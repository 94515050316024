import {combineReducers, configureStore } from "@reduxjs/toolkit"
import {persistStore , persistReducer} from 'redux-persist'
import storage from "redux-persist/lib/storage"
import PO_ID_Reducer from "./Reducers/PO_ID_Reducer"
import PO_Reducer from "./Reducers/PO_Reducer"
import WindowSizeReducer from "./Reducers/WindowSizeReducer"
import ActiveTabReducer from "./Reducers/activeTabReducer"
import userIdReducer from "./Reducers/UserId_Reducer"
import listingSearchParametersReducer from "./Reducers/listinngSearchParametersReducer"
import listingsReducer from "./Reducers/listingsReducer"
import listingSearchParametersReducer2 from "./Reducers/listingSearchParametersReducer2"
import userDetailsReducer from "./Reducers/userDetailsReducer"
import topListingsReducer from "./Reducers/topListingsReducer"
import imageHashMapReducer from "./Reducers/imageHashMapReducer"
import purchaseOrderImageHashMapReducer from "./Reducers/purchaseOrderImageHashMapReducer"

const persistConfig = {
    key: 'root',
    storage ,
}

const reducers =  combineReducers({    
    user_id : userIdReducer,
    active_tab : ActiveTabReducer,
    window_size : WindowSizeReducer,
    PO_Id : PO_ID_Reducer,
    PO : PO_Reducer,
    search_text : listingSearchParametersReducer,
    search_params : listingSearchParametersReducer2,
    listings_store : listingsReducer,
    top_listings_store : topListingsReducer,
    userdetails_store : userDetailsReducer,
    image_hashmap_store : imageHashMapReducer,
    purchase_order_image_hashmap_store : purchaseOrderImageHashMapReducer
});


const persistedReducer = persistReducer(persistConfig,reducers)
export const store = configureStore({    
    reducer : persistedReducer
})

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

