export const ocassions = [
    "Regular/BAU",
    "Diwali",
    "Rakhi",
    "Holi",
    "Valentine's Day",
    "Christmas",
    "Navratri",
    "Makar Sankranti",
    "Dussehra",
    "Ganesh Chaturthi",
    "Ugadi",
    "Gudi Padwa",
    "Pongal",
    "Onam",
    "Lohri",
    "Karva Chauth",
    "Basant Panchami",
    "Mahashivratri",
    "Ram Navami",
    "Krishna Janmashtami",
    "Chhath Puja",
    "Baisakhi",
    "Hanuman Jayanti",
    "Akshaya Tritiya",
    "Guru Purnima",
    "Buddha Purnima",
    "Eid-ul-Fitr",
    "Eid-ul-Adha (Bakrid)",
    "Muharram",
    "Ramadan",
    "Good Friday",
    "Easter",
    "New Year's Day",
    "Vaisakhi",
    "Cheti Chand",
    "Guru Nanak Jayanti",
    "Guru Gobind Singh Jayanti",
    "Paryushana",
    "Mahavir Jayanti",
    "Easter Monday",
    "Bakra Eid",
    "Jamat Ul Vida",
    "Father's Day",
    "Mother's Day",
    "Children's Day",
    "Teachers' Day",
    "Subhas Chandra Bose Jayanti",
    "Ambedkar Jayanti",
    "Gandhi Jayanti",
    "Chhatrapati Shivaji Maharaj Jayanti",
  ];

  export const allPoStatusesConstants = [
    "ACKNOWLEDGEMENT PENDING",
    "OPEN",
    "MARKED CANCELLED",
    "EXPIRED"
  ]