import React, { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Offcanvas,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import "./CSS/CompanyPurchaseOrdersAllPurchaseOrders.css";

import {
  Catalogue,
  Company,
  ErrorMessageBody,
  FilterItem,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  POSkuLevelReportObject,
  PacksAndCombos,
  PurchaseOrder,
  SearchParams,
} from "../../atomic_components/types";
import { useHistory, useLocation } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrdersAllPurchaseOrdersSearchBar from "./CompanyPurchaseOrdersAllPurchaseOrdersSearchBar";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import {
  Filter,
  FilterSquareFill,
  Funnel,
  FunnelFill,
} from "react-bootstrap-icons";
import CatalogueThemesDropdown from "../Catalogues/CatalogueThemesDropdown";
import {
  ocassions,
  allPoStatusesConstants,
} from "./Constants/CompanyPurchaseOrdersConstants";
import EscapeKeyListener from "../EscapeKeyListener";
import { POTypes } from "../../atomic_components/PODetails";
import jsonexport from "jsonexport";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CompanyPurchaseOrderPendingDispatchMultiPDF from "./CompanyPurchaseOrderPendingDispatchMultiPDF";
import DatePicker from "react-datepicker";

function CompanyPurchaseOrdersAllPurchaseOrders() {
  const history = useHistory();
  const location = useLocation();
  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  let pageNumber = 1;

  const [allIncomingPurchaseOrders, setAllIncomingPurchaseOrders] = useState<
    IncomingPurchaseOrderDataObject[]
  >([]);
  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [finalExpiryDatesList, setFinalExpiryDatesList] = useState<string[]>(
    []
  );
  const [finalPoStatusList, setFinalPoStatusList] = useState<string[]>([]);
  const [finalPOTypesList, setFinalPoTypesList] = useState<string[]>([]);
  const [finalCompanyIdsList, setFinalCompanyIdsList] = useState<number[]>([]);
  const [finalDeliveryLocationsList, setFinalDeliveryLocationsList] = useState<
    string[]
  >([]);

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isCheckedSelectAll, setIsCheckedSelectAll] = useState<boolean>(false);
  const [checkedCount, setCheckedCount] = useState<number>(0);
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const [isExpiryDateFilterVisible, setIsExpiryDateFilterVisible] =
    useState<boolean>(false);
  const [isPoStatusFilterVisible, setIsPoStatusFilterVisible] = useState(false); // State for filter visibility
  const [isPoTypeFilterVisible, setIsPoTypeFilterVisible] = useState(false); // State for filter visibility
  const [isCompaniesFilterVisible, setIsCompaniesFilterVisible] =
    useState(false); // State for filter visibility
  const [isDeliveryLocationFilterVisible, setIsDeliveryLocationFilterVisible] =
    useState(false); // State for filter visibility
  const [isPoNumberFilterVisible, setIsPoNumberFilterVisible] = useState(false); // State for filter visibility

  const [companies, setCompanies] = useState<Company[]>([]);
  const [deliveryLocations, setDeliveryLocations] = useState<[]>([]);

  const [expiryDateRangeLocal, setExpiryDateRangeLocal] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [expiryDateRangeGlobal, setExpiryDateRangeGlobal] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const [poTypeFilterCheckedCountLocal, setPoTypeFilterCheckedCountLocal] =
    useState<number>(0);
  const [poTypeFilterCheckedCountGlobal, setPoTypeFilterCheckedCountGlobal] =
    useState<number>(0);

  const [poStatusFilterCheckedCountLocal, setPoStatusFilterCheckedCountLocal] =
    useState<number>(0);
  const [
    poStatusFilterCheckedCountGlobal,
    setPoStatusFilterCheckedCountGlobal,
  ] = useState<number>(0);

  const [companyFilterCheckedCountLocal, setCompanyFilterCheckedCountLocal] =
    useState<number>(0);
  const [companyFilterCheckedCountGlobal, setCompanyFilterCheckedCountGlobal] =
    useState<number>(0);

  const [searchQueryLocal, setSearchQueryLocal] = useState<string>("");
  const [searchQueryGlobal, setSearchQueryGlobal] = useState<string>("");

  const [
    deliveryLocationCountCheckedCountLocal,
    setDeliveryLocationCheckedCountLocal,
  ] = useState<number>(0);
  const [
    deliveryLocationCheckedCountGlobal,
    setDeliveryLocationCheckedCountGlobal,
  ] = useState<number>(0);

  const [poTypeFilterList, setPOTypeFilterList] = useState<FilterItem[]>([]);
  const [companyFilterList, setCompanyFilterList] = useState<FilterItem[]>([]);
  const [deliveryLocationFilterList, setDeliveryLocationFilterList] = useState<
    FilterItem[]
  >([]);
  const [poStatusFilterList, setPoStatusFilterList] = useState<FilterItem[]>(
    []
  );

  const [downloadPendencyPDF, setDownloadPendencyPDF] = useState(false);
  const [
    isAddWarehouseInventoryColumnChecked,
    setIsAddWarehouseInventoryColumnChecked,
  ] = useState<boolean>(true);

  const [poSkuLevelReportObjectList, setPoSkuLevelReportObjectList] = useState<
    POSkuLevelReportObject[]
  >([]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQueryLocal(e.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchQueryGlobal(searchQueryLocal);
      handleSearch(searchQueryLocal);
      setIsPoNumberFilterVisible(false);
    }
  };

  const applyPONumberFilter = () => {
    setSearchQueryGlobal(searchQueryLocal);
    handleSearch(searchQueryLocal);
    setIsPoNumberFilterVisible(false);
  };

  const getAllPurchaseOrdersPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean,
    requestBody: Record<string, any>
  ) => {
    try {
      // const response =
      //   await companyPurchaseOrderNetworkUtil.getAllPurchaseOrdersPaginated(
      //     fromHandleSearch == true ? searchString : searchParams.search_string,
      //     pageNumber,
      //     28
      //   );

      const response =
        await companyPurchaseOrderNetworkUtil.getAllPurchaseOrdersPaginatedWithFilters(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          100,
          requestBody
        );
      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setAllIncomingPurchaseOrders((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error in fetching catalogues :", error);
      setIsLoading(false);
    }
  };

  const handleSearch = (query: string) => {
    const requestBody: Record<string, any> = {};
    requestBody["expiryDates"] = finalExpiryDatesList;
    requestBody["poStatuses"] = finalPoStatusList;
    requestBody["poTypes"] = finalPOTypesList;
    requestBody["companyIds"] = finalCompanyIdsList;
    requestBody["deliveryLocations"] = finalDeliveryLocationsList;

    setAllIncomingPurchaseOrders([]);
    setIsLoading(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    getAllPurchaseOrdersPaginated(query, 1, true, requestBody);
  };

  const handleSearchWithExpiryDateFilters = (
    query: string,
    expiryDates: any[]
  ) => {
    const requestBody: Record<string, any> = {};
    requestBody["expiryDates"] = expiryDates;
    requestBody["poStatuses"] = finalPoStatusList;
    requestBody["poTypes"] = finalPOTypesList;
    requestBody["companyIds"] = finalCompanyIdsList;
    requestBody["deliveryLocations"] = finalDeliveryLocationsList;

    setAllIncomingPurchaseOrders([]);
    setIsLoading(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    getAllPurchaseOrdersPaginated(query, 1, true, requestBody);
  };

  const handleSearchWithPoStatusFilters = (
    query: string,
    poStatuses: string[]
  ) => {
    const requestBody: Record<string, any> = {};
    requestBody["expiryDates"] = finalExpiryDatesList;
    requestBody["poStatuses"] = poStatuses;
    requestBody["poTypes"] = finalPOTypesList;
    requestBody["companyIds"] = finalCompanyIdsList;
    requestBody["deliveryLocations"] = finalDeliveryLocationsList;

    setAllIncomingPurchaseOrders([]);
    setIsLoading(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    getAllPurchaseOrdersPaginated(query, 1, true, requestBody);
  };

  const handleSearchWithPoTypeFilters = (query: string, poTypes: string[]) => {
    const requestBody: Record<string, any> = {};
    requestBody["expiryDates"] = finalExpiryDatesList;
    requestBody["poStatuses"] = finalPoStatusList;
    requestBody["poTypes"] = poTypes;
    requestBody["companyIds"] = finalCompanyIdsList;
    requestBody["deliveryLocations"] = finalDeliveryLocationsList;

    setAllIncomingPurchaseOrders([]);
    setIsLoading(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    getAllPurchaseOrdersPaginated(query, 1, true, requestBody);
  };

  const handleSearchWithCompanyFilters = (
    query: string,
    companyIds: number[]
  ) => {
    const requestBody: Record<string, any> = {};
    requestBody["expiryDates"] = finalExpiryDatesList;
    requestBody["poStatuses"] = finalPoStatusList;
    requestBody["poTypes"] = finalPOTypesList;
    requestBody["companyIds"] = companyIds;
    requestBody["deliveryLocations"] = finalDeliveryLocationsList;

    setAllIncomingPurchaseOrders([]);
    setIsLoading(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    getAllPurchaseOrdersPaginated(query, 1, true, requestBody);
  };

  const handleSearchWithDeliveryLocationFilters = (
    query: string,
    localDeliveryLocations: string[]
  ) => {
    const requestBody: Record<string, any> = {};
    requestBody["expiryDates"] = finalExpiryDatesList;
    requestBody["poStatuses"] = finalPoStatusList;
    requestBody["poTypes"] = finalPOTypesList;
    requestBody["companyIds"] = finalCompanyIdsList;
    requestBody["deliveryLocations"] = localDeliveryLocations;

    setAllIncomingPurchaseOrders([]);
    setIsLoading(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    getAllPurchaseOrdersPaginated(query, 1, true, requestBody);
  };

  const loadMore = () => {
    const requestBody: Record<string, any> = {};
    requestBody["expiryDates"] = finalExpiryDatesList;
    requestBody["poStatuses"] = finalPoStatusList;
    requestBody["poTypes"] = finalPOTypesList;
    requestBody["companyIds"] = finalCompanyIdsList;
    requestBody["deliveryLocations"] = finalDeliveryLocationsList;

    getAllPurchaseOrdersPaginated(
      "",
      searchParams.current_page_num + 1,
      false,
      requestBody
    );
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  const convertOcassionsToPOTypeFilterList = () => {
    return ocassions.map((item) => ({
      filter_id: 0,
      filter_string: item,
      is_selected_local: false,
      is_selected_global: false,
    }));
  };
  const convertPoStatusesToPoStatusFilterList = () => {
    return allPoStatusesConstants.map((item) => ({
      filter_id: 0,
      filter_string: item,
      is_selected_local: false,
      is_selected_global: false,
    }));
  };

  const convertCompaniesToCompanyTypeFilterList = (
    localCompanies: Company[]
  ) => {
    return localCompanies.map((item) => ({
      filter_id: item.company_id,
      filter_string: item.company_name,
      is_selected_local: false,
      is_selected_global: false,
    }));
  };

  const convertDeliveryLocationsToDeliveryLocationFilterList = (
    locations: []
  ) => {
    return locations.map((item) => ({
      filter_id: 0,
      filter_string: item,
      is_selected_local: false,
      is_selected_global: false,
    }));
  };

  useEffect(() => {
    const fetchAllActiveCompanies = async () => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.getAllActiveCompanies();
        setCompanies(response.data);
        setCompanyFilterList(
          convertCompaniesToCompanyTypeFilterList(response.data)
        );
      } catch (error) {
        console.error("Error in fetching all active companies", error);
      }
    };

    const fetchAllUniqueDeliveryLocations = async () => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.getAllUniqueDeliveryLocations();
        setDeliveryLocations(response.data);
        setDeliveryLocationFilterList(
          convertDeliveryLocationsToDeliveryLocationFilterList(response.data)
        );
      } catch (error) {
        console.error("Error in fetching all active companies", error);
      }
    };

    fetchAllActiveCompanies();
    fetchAllUniqueDeliveryLocations();
    const requestBody: Record<string, any> = {};
    requestBody["expiryDates"] = finalExpiryDatesList;
    requestBody["poStatuses"] = finalPoStatusList;
    requestBody["poTypes"] = finalPOTypesList;
    requestBody["companyIds"] = finalCompanyIdsList;
    requestBody["deliveryLocations"] = finalDeliveryLocationsList;
    getAllPurchaseOrdersPaginated("", 1, false, requestBody);
    setPOTypeFilterList(convertOcassionsToPOTypeFilterList());
    setPoStatusFilterList(convertPoStatusesToPoStatusFilterList());
  }, []);

  useEffect(() => {
    console.log(searchParams);
  }, [searchParams]);

  const getCalculatedPoStatusColor = (calculatedStatus: string) => {
    if (calculatedStatus === "ACKNOWLEDGEMENT PENDING")
      return "incoming-po-text-orange";
    else if (calculatedStatus === "OPEN") return "incoming-po-text-green";
    else if (calculatedStatus === "MARKED CANCELLED")
      return "incoming-po-text-purple";
    else if (calculatedStatus === "EXPIRED") return "incoming-po-text-red";
    return "";
  };

  const getWIPColor = (wipStatus: string) => {
    if (wipStatus === "YES") return "incoming-po-text-green";
    else return "incoming-po-text-red";
  };

  const handleCheckBoxChangeForAll = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCheckedSelectAll(event.target.checked); // Update state based on the checkbox value
    if (event.target.checked) {
      setCheckedCount(allIncomingPurchaseOrders.length);
    } else {
      setCheckedCount(0);
    }

    setAllIncomingPurchaseOrders((prevItems) =>
      prevItems.map((item, i) => ({
        ...item,
        ["is_selected"]: event.target.checked,
      }))
    );
  };

  const handleCheckBoxChangeAtIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked) setCheckedCount(checkedCount + 1);
    else {
      setCheckedCount(checkedCount - 1);
      if (checkedCount - 1 == 0 && isCheckedSelectAll) {
        setIsCheckedSelectAll(false);
      }
    }

    setAllIncomingPurchaseOrders((prevItems) =>
      prevItems.map((item, i) =>
        i == index ? { ...item, ["is_selected"]: !item.is_selected } : item
      )
    );
  };

  const handleCheckBoxChangeAtPoStatusFilterIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked)
      setPoStatusFilterCheckedCountLocal(poStatusFilterCheckedCountLocal + 1);
    else {
      setPoStatusFilterCheckedCountLocal(poStatusFilterCheckedCountLocal - 1);
      // if (checkedCount - 1 == 0 && isCheckedSelectAll) {
      //   setIsCheckedSelectAll(false);
      // }
    }

    setPoStatusFilterList((prevItems) =>
      prevItems.map((item, i) =>
        i == index
          ? { ...item, ["is_selected_local"]: !item.is_selected_local }
          : item
      )
    );
  };

  const handleCheckBoxChangeAtPoTypeFilterIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked)
      setPoTypeFilterCheckedCountLocal(poTypeFilterCheckedCountLocal + 1);
    else {
      setPoTypeFilterCheckedCountLocal(poTypeFilterCheckedCountLocal - 1);
      // if (checkedCount - 1 == 0 && isCheckedSelectAll) {
      //   setIsCheckedSelectAll(false);
      // }
    }

    setPOTypeFilterList((prevItems) =>
      prevItems.map((item, i) =>
        i == index
          ? { ...item, ["is_selected_local"]: !item.is_selected_local }
          : item
      )
    );
  };

  const handleCheckBoxChangeAtCompanyFilterIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked)
      setCompanyFilterCheckedCountLocal(companyFilterCheckedCountLocal + 1);
    else {
      setCompanyFilterCheckedCountLocal(companyFilterCheckedCountLocal - 1);
      // if (checkedCount - 1 == 0 && isCheckedSelectAll) {
      //   setIsCheckedSelectAll(false);
      // }
    }

    setCompanyFilterList((prevItems) =>
      prevItems.map((item, i) =>
        i == index
          ? { ...item, ["is_selected_local"]: !item.is_selected_local }
          : item
      )
    );
  };

  const handleCheckBoxChangeAtDeliveryLocationFilterIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked)
      setDeliveryLocationCheckedCountLocal(
        deliveryLocationCountCheckedCountLocal + 1
      );
    else {
      setDeliveryLocationCheckedCountLocal(
        deliveryLocationCountCheckedCountLocal - 1
      );
      // if (checkedCount - 1 == 0 && isCheckedSelectAll) {
      //   setIsCheckedSelectAll(false);
      // }
    }

    setDeliveryLocationFilterList((prevItems) =>
      prevItems.map((item, i) =>
        i == index
          ? { ...item, ["is_selected_local"]: !item.is_selected_local }
          : item
      )
    );
  };

  const addSelectedPOsToWIP = async () => {
    setShowLoadingPage(true);
    const additionMap = allIncomingPurchaseOrders.reduce((map, item, index) => {
      if (item.is_selected === true) {
        map[item.po_number] = index; // Use the original index as the key
      }
      return map;
    }, {} as Record<string, number>);

    const additionList = allIncomingPurchaseOrders
      .filter((item) => item.is_selected === true) // Use strict equality and ensure the condition is returned
      .map((item) => item.po_number);

    try {
      const response =
        await companyPurchaseOrderNetworkUtil.addSelectedPOsToWIP(
          additionList,
          userDetailsFromStore.user_details.userId
        );
      // window.location.reload();

      const successfullyUpdatedPONumbers = response.data; // Assuming this is the key for returned PO numbers.
      if (successfullyUpdatedPONumbers.length > 0) {
        toast.success("Success!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });

        // Update the state of allIncomingPurchaseOrders
        setAllIncomingPurchaseOrders((prevOrders) =>
          prevOrders.map((order) =>
            successfullyUpdatedPONumbers.includes(order.po_number)
              ? { ...order, is_wip: "YES" } // Update the `is_wip` status to true for marked POs
              : order
          )
        );
      } else {
        toast.error(
          "Selected POs were not marked WIP, either because their acknowledgement is pending or they were already marked WIP.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );
      }

      setShowLoadingPage(false);
    } catch (error) {
      console.error("Error in addSelectedPOsToWIP : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
      setShowLoadingPage(false);
    }
  };

  const applyExpiryDateFilter = () => {
    if (expiryDateRangeLocal[0] && expiryDateRangeLocal[1]) {
      const formattedStart = expiryDateRangeLocal[0]
        .toISOString()
        .split("T")[0]; // Convert to 'YYYY-MM-DD'
      const formattedEnd = expiryDateRangeLocal[1].toISOString().split("T")[0]; // Convert to 'YYYY-MM-DD'
      setExpiryDateRangeGlobal(expiryDateRangeLocal);

      const expiryDatesList = [];
      expiryDatesList.push(formattedStart);
      expiryDatesList.push(formattedEnd);
      setFinalExpiryDatesList(expiryDatesList);
      setIsExpiryDateFilterVisible(false);

      handleSearchWithExpiryDateFilters(
        searchParams.search_string,
        expiryDatesList
      );
    }
  };

  const applyPOStatusFilter = () => {
    const poStatuses = poStatusFilterList
      .filter((item) => item.is_selected_local)
      .map((item) => item.filter_string);

    setFinalPoStatusList(poStatuses);

    setPoStatusFilterCheckedCountGlobal(poStatusFilterCheckedCountLocal);
    setPoStatusFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_global: item.is_selected_local, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    handleSearchWithPoStatusFilters(searchParams.search_string, poStatuses);
    setIsPoStatusFilterVisible(false); // Close the dropdown
  };

  const applyPOTypeFilter = () => {
    const poTypes = poTypeFilterList
      .filter((item) => item.is_selected_local)
      .map((item) => item.filter_string);
    setFinalPoTypesList(poTypes);

    setPoTypeFilterCheckedCountGlobal(poTypeFilterCheckedCountLocal);
    setPOTypeFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_global: item.is_selected_local, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    handleSearchWithPoTypeFilters(searchParams.search_string, poTypes);
    setIsPoTypeFilterVisible(false); // Close the dropdown
  };

  const applyCompanyTypeFilter = () => {
    const companyIds = companyFilterList
      .filter((item) => item.is_selected_local)
      .map((item) => item.filter_id);
    setFinalCompanyIdsList(companyIds);

    setCompanyFilterCheckedCountGlobal(companyFilterCheckedCountLocal);
    setCompanyFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_global: item.is_selected_local, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    handleSearchWithCompanyFilters(searchParams.search_string, companyIds);
    setIsCompaniesFilterVisible(false); // Close the dropdown
  };

  const applyDeliveryLocationFilter = () => {
    const deliveryLocations = deliveryLocationFilterList
      .filter((item) => item.is_selected_local)
      .map((item) => item.filter_string);

    setFinalDeliveryLocationsList(deliveryLocations);
    setDeliveryLocationCheckedCountGlobal(
      deliveryLocationCountCheckedCountLocal
    );
    setDeliveryLocationFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_global: item.is_selected_local, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    handleSearchWithDeliveryLocationFilters(
      searchParams.search_string,
      deliveryLocations
    );
    setIsDeliveryLocationFilterVisible(false); // Close the dropdown
  };

  const clearExpiryDateFilter = () => {
    setIsExpiryDateFilterVisible(false);
    setExpiryDateRangeLocal(expiryDateRangeGlobal);
  };

  const clearPOStatusFilter = () => {
    setPoStatusFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setIsPoStatusFilterVisible(false); // Close the dropdown
    setPoStatusFilterCheckedCountLocal(poStatusFilterCheckedCountGlobal);
  };

  const clearPOTypeFilter = () => {
    setPOTypeFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setIsPoTypeFilterVisible(false); // Close the dropdown
    setPoTypeFilterCheckedCountLocal(poTypeFilterCheckedCountGlobal);
  };

  const clearCompaniesFilter = () => {
    setCompanyFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setIsCompaniesFilterVisible(false); // Close the dropdown
    setCompanyFilterCheckedCountLocal(companyFilterCheckedCountGlobal);
  };

  const clearDeliveryLocationFilter = () => {
    setDeliveryLocationFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setIsDeliveryLocationFilterVisible(false); // Close the dropdown
    setDeliveryLocationCheckedCountLocal(deliveryLocationCheckedCountGlobal);
  };

  const clearPoNumberFilter = () => {
    // setDeliveryLocationFilterList((prevItems) =>
    //   prevItems.map((item) => ({
    //     ...item, // Spread the existing properties of the object
    //     is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
    //   }))
    // );

    setIsPoNumberFilterVisible(false);
    setSearchQueryLocal(searchQueryGlobal);
  };

  const resetPoNumberFilter = () => {
    setIsPoNumberFilterVisible(false);
    setSearchQueryLocal("");
    setSearchQueryGlobal("");
    handleSearch("");
  };

  const resetExpiryDateFilter = () => {
    setIsExpiryDateFilterVisible(false);
    setExpiryDateRangeLocal([null, null]);
    setExpiryDateRangeGlobal([null, null]);
    setFinalExpiryDatesList([]);
    handleSearchWithExpiryDateFilters(searchParams.search_string, []);
    // setTableFilters((prev) => ({ ...prev, expiry_start: null, expiry_end: null }));
  };

  const resetPoStatusFilter = () => {
    setIsPoStatusFilterVisible(false);

    setPoStatusFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: false, // Update `is_selected_local` with the value of `is_selected_global`
        is_selected_global: false, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setPoStatusFilterCheckedCountLocal(0);
    setPoStatusFilterCheckedCountGlobal(0);
    setFinalPoStatusList([]);
    handleSearchWithPoStatusFilters(searchParams.search_string, []);
  };

  const resetPoTypeFilter = () => {
    setIsPoTypeFilterVisible(false);

    setPOTypeFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: false, // Update `is_selected_local` with the value of `is_selected_global`
        is_selected_global: false, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setPoTypeFilterCheckedCountLocal(0);
    setPoTypeFilterCheckedCountGlobal(0);
    setFinalPoTypesList([]);
    handleSearchWithPoTypeFilters(searchParams.search_string, []);
  };

  const resetCompanyNameFilter = () => {
    setIsCompaniesFilterVisible(false);
    setCompanyFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: false, // Update `is_selected_local` with the value of `is_selected_global`
        is_selected_global: false, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setCompanyFilterCheckedCountLocal(0);
    setCompanyFilterCheckedCountGlobal(0);
    setFinalCompanyIdsList([]);
    handleSearchWithCompanyFilters(searchParams.search_string, []);
  };

  const resetDeliveryLocationFilter = () => {
    setIsDeliveryLocationFilterVisible(false);
    setDeliveryLocationFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: false, // Update `is_selected_local` with the value of `is_selected_global`
        is_selected_global: false, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setDeliveryLocationCheckedCountGlobal(0);
    setDeliveryLocationCheckedCountLocal(0);
    setFinalDeliveryLocationsList([]);
    handleSearchWithDeliveryLocationFilters(searchParams.search_string, []);
  };

  const downloadCurrentViewAsSpreadSheet = async () => {
    try {
      // itemsToExport.push()
      const transformedItems = allIncomingPurchaseOrders
        .filter((item) => item.is_selected)
        .map((singleItem, index) => {
          return {
            sr_no: index + 1,
            po_number: singleItem.po_number,
            po_type: singleItem.po_type,
            buyer_name: singleItem.company_name,
            delivery_location: singleItem.delivery_city,
            po_status: singleItem.calculated_po_status,
            is_wip: singleItem.is_wip,
            sku_count: singleItem.analytics_object.sku_count,
            total_before_tax: singleItem.analytics_object.total_before_tax,
            total_tax: singleItem.analytics_object.total_tax,
            total_after_tax: singleItem.analytics_object.total_after_tax,
            total_demand: singleItem.analytics_object.total_demand,
            total_dispatched: singleItem.analytics_object.total_dispatched,
            total_packed: singleItem.analytics_object.total_packed,
            total_pending: singleItem.analytics_object.total_pending,
            quantity_fill_rate: singleItem.analytics_object.quantity_fill_rate,
            sku_fill_rate: singleItem.analytics_object.sku_fill_rate,
            total_consignments: singleItem.analytics_object.total_consignments,
            total_boxes_dispatched:
              singleItem.analytics_object.boxes_dispatched,
            total_boxes_packed: singleItem.analytics_object.boxes_packed,
            po_issue_date: DateTimeUtils.formatDateOnly(
              singleItem.po_issue_date
            ),
            po_expiry_date: DateTimeUtils.formatDateOnly(
              singleItem.expiry_date
            ),
            po_addition_date: DateTimeUtils.formatDateTime(
              singleItem.created_at
            ),
            po_added_by: singleItem.created_by,
          };
        });

      jsonexport(transformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "po_level_report.csv";
        // Simulate a click to trigger the download
        link.click();
      });
    } catch (error) {
      console.log("Printing error from download current view.");
      console.error("Error:", error);
    }
  };

  const getAvailableQuantityForMulti = (
    packsAndCombos: PacksAndCombos[]
  ): number => {
    // console.log(listings);
    let available_quantity = 10000000;
    packsAndCombos.forEach((packsAndCombo, index) => {
      available_quantity = Math.min(
        available_quantity,
        packsAndCombo.listing.available_quantity
      );
    });

    return available_quantity == 10000000 ? 0 : available_quantity;
  };

  const sortListings = (
    array: IncomingPurchaseOrderListingDetailsDataObject[]
  ): IncomingPurchaseOrderListingDetailsDataObject[] => {
    return [...array].sort((a, b) => {
      const diffA = a.demand - a.reserved_quantity - a.dispatched_quantity; //a.pending;
      const diffB = b.demand - b.reserved_quantity - b.dispatched_quantity; //b.pending;
      return diffB - diffA; // descending order
    });
  };

  const downloadSKULevelReportAsSpreadsheet = async (
    skuLevelReportObjectList: POSkuLevelReportObject[]
  ) => {
    const finalTransformedItems: any[] = [];
    try {
      skuLevelReportObjectList.forEach((currentPurchaseOrder, ind) => {
        const transformedItems = sortListings(
          currentPurchaseOrder.listings
        ).map((singleItem) => {
          return {
            buyer_name: currentPurchaseOrder.company.company_name,
            po_number: singleItem.po_number,
            po_issue_date: DateTimeUtils.formatDateOnly(
              currentPurchaseOrder.po_issue_date
            ),
            po_expiry_date: DateTimeUtils.formatDateOnly(
              currentPurchaseOrder.expiry_date
            ),
            po_addition_date: DateTimeUtils.formatDateOnly(
              currentPurchaseOrder.created_at
            ),
            po_type: currentPurchaseOrder.po_type,
            delivery_location: currentPurchaseOrder.delivery_city,
            po_secondary_sku: singleItem.po_secondary_sku,
            master_sku: singleItem.master_sku,
            inventory_sku_id: singleItem.inventory_sku_id,
            pack_combo_sku_id: singleItem.pack_combo_sku_id,
            sku_type: singleItem.sku_type,
            company_code_primary: singleItem.company_code_primary,
            company_code_secondary: singleItem.company_code_secondary,
            title: singleItem.title,
            mrp: singleItem.mrp,
            rate_without_tax: singleItem.rate_without_tax,
            tax_rate: singleItem.tax_rate,
            hsn: singleItem.hsn_code,
            size: singleItem.size,
            color: singleItem.color,
            ops_tag: singleItem.listing ? singleItem.listing.ops_tag : "",
            warehouse_quantity:
              singleItem.sku_type === "MULTI"
                ? getAvailableQuantityForMulti(singleItem.multi_listings)
                : singleItem.listing
                ? singleItem.listing.available_quantity
                : 0,
            demand: singleItem.demand,
            packed: singleItem.reserved_quantity,
            dispatched: singleItem.dispatched_quantity,
            pending:
              singleItem.demand -
              singleItem.reserved_quantity -
              singleItem.dispatched_quantity,
            fill_rate_percent:
              Math.round(
                ((singleItem.reserved_quantity +
                  singleItem.dispatched_quantity) /
                  singleItem.demand) *
                  100 *
                  100
              ) / 100,
          };
        });

        finalTransformedItems.push(...transformedItems);
      });
      jsonexport(finalTransformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "sku_level_report.csv";
        // Simulate a click to trigger the download
        link.click();
      });
    } catch (error) {
      console.log("Printing error from downloadSKULevelReportAsSpreadsheet.");
      console.error("Error:", error);
    }
  };

  const prepareAndDownloadReportAsSpreadSheet = async () => {
    setShowLoadingPage(true);
    const additionList = allIncomingPurchaseOrders
      .filter((item) => item.is_selected === true) // Use strict equality and ensure the condition is returned
      .map((item) => item.po_number);

    try {
      const response =
        await companyPurchaseOrderNetworkUtil.downloadDataForSKULevelReport(
          additionList
        );
      // window.location.reload();

      downloadSKULevelReportAsSpreadsheet(response.data);
      setShowLoadingPage(false);
    } catch (error) {
      console.error("Error in prepareAndDownloadReportAsSpreadSheet : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
      setShowLoadingPage(false);
    }
  };
  const prepareAndDownloadPendencyPDF = async () => {
    setShowLoadingPage(true);
    const additionList = allIncomingPurchaseOrders
      .filter((item) => item.is_selected === true) // Use strict equality and ensure the condition is returned
      .map((item) => item.po_number);

    try {
      const response =
        await companyPurchaseOrderNetworkUtil.downloadDataForSKULevelReport(
          additionList
        );
      // window.location.reload();

      // downloadSKULevelReportAsSpreadsheet(response.data);
      setDownloadPendencyPDF(true);
      setPoSkuLevelReportObjectList(response.data);
      setShowLoadingPage(false);
    } catch (error) {
      console.error("Error in prepareAndDownloadReportAsSpreadSheet : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
      setShowLoadingPage(false);
    }
  };

  const handleShowWarehouseInventoryCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsAddWarehouseInventoryColumnChecked(event.target.checked); // Update state based on the checkbox value
  };

  return (
    <div className="catalogues-container">
      <div className="catalogues-search-bar-container">
        {checkedCount > 0 && (
          <div>
            <Button
              className="mr-2 filters-button"
              onClick={prepareAndDownloadPendencyPDF}
              variant="outline-primary"
            >
              Download Pendency PDF
            </Button>

            <Button
              className="mr-2 filters-button"
              onClick={prepareAndDownloadReportAsSpreadSheet}
              variant="outline-primary"
            >
              Download SKU Level Report
            </Button>
            <Button
              className="mr-2 filters-button"
              onClick={downloadCurrentViewAsSpreadSheet}
              variant="outline-primary"
            >
              Download PO Level Report
            </Button>
            <Button
              className="mr-2 filters-button"
              onClick={addSelectedPOsToWIP}
              variant="outline-primary"
            >
              Add to WIP
            </Button>
          </div>
        )}
      </div>

      <div className="purchase-orders-table-container">
        <Table bordered hover size="sm">
          <thead className="sticky-header">
            <tr>
              <th>
                {" "}
                <input
                  type="checkbox"
                  checked={isCheckedSelectAll}
                  onChange={handleCheckBoxChangeForAll}
                />
              </th>
              <th>
                PO Number
                {searchQueryGlobal.length == 0 ? (
                  <Filter
                    className="filter-option ml-1"
                    onClick={() => setIsPoNumberFilterVisible(true)}
                  ></Filter>
                ) : (
                  <FilterSquareFill
                    className="filter-option ml-1"
                    style={{ color: "green" }}
                    onClick={() => setIsPoNumberFilterVisible(true)}
                  ></FilterSquareFill>
                )}
                {isPoNumberFilterVisible && (
                  <EscapeKeyListener onEscape={clearPoNumberFilter}>
                    <div className="filter-dropdown">
                      <div className="empty-space"></div>
                      <div className="filter-list-main ml-2 mr-2">
                        <input
                          type="text"
                          placeholder="Search PO Number..."
                          value={searchQueryLocal}
                          onChange={handleInputChange}
                          onKeyPress={handleKeyPress}
                        />
                      </div>
                      <div className="filter-buttons-section mt-2 ml-2 mr-2">
                        {/* {poTypeFilterCheckedCount > 0} */}
                        {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}

                        <Button
                          variant="outline-primary"
                          className="filters-button"
                          onClick={resetPoNumberFilter}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="outline-primary"
                          className="filters-button"
                          onClick={clearPoNumberFilter}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          className="filters-button"
                          disabled={searchQueryLocal.length == 0}
                          onClick={applyPONumberFilter}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </EscapeKeyListener>
                )}
              </th>
              <th>
                PO Type{" "}
                {poTypeFilterCheckedCountGlobal == 0 ? (
                  <Filter
                    className="filter-option ml-1"
                    onClick={() => setIsPoTypeFilterVisible(true)}
                  ></Filter>
                ) : (
                  <FilterSquareFill
                    className="filter-option ml-1"
                    style={{ color: "green" }}
                    onClick={() => setIsPoTypeFilterVisible(true)}
                  ></FilterSquareFill>
                )}
                {isPoTypeFilterVisible && (
                  <EscapeKeyListener onEscape={clearPOTypeFilter}>
                    <div className="filter-dropdown">
                      <div className="empty-space"></div>
                      <div className="filter-list-main">
                        {poTypeFilterList.map((filterListItem, index) => (
                          <div key={index} className="filter-item">
                            <input
                              className="filter-check-box mr-1"
                              type="checkbox"
                              checked={filterListItem.is_selected_local}
                              onChange={(e) =>
                                handleCheckBoxChangeAtPoTypeFilterIndex(
                                  e,
                                  index
                                )
                              }
                            />
                            {filterListItem.filter_string}
                          </div>
                        ))}
                      </div>
                      <div className="filter-buttons-section">
                        {/* {poTypeFilterCheckedCount > 0} */}
                        {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}
                        <Button
                          variant="outline-primary"
                          className="filters-button"
                          onClick={resetPoTypeFilter}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="outline-primary"
                          className="filters-button"
                          onClick={clearPOTypeFilter}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          className="filters-button"
                          disabled={poTypeFilterCheckedCountLocal == 0}
                          onClick={applyPOTypeFilter}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </EscapeKeyListener>
                )}
              </th>
              <th>
                Company Name{" "}
                {companyFilterCheckedCountGlobal == 0 ? (
                  <Filter
                    className="filter-option ml-1"
                    onClick={() => setIsCompaniesFilterVisible(true)}
                  ></Filter>
                ) : (
                  <FilterSquareFill
                    className="filter-option ml-1"
                    style={{ color: "green" }}
                    onClick={() => setIsCompaniesFilterVisible(true)}
                  ></FilterSquareFill>
                )}
                {isCompaniesFilterVisible && (
                  <EscapeKeyListener onEscape={clearCompaniesFilter}>
                    <div className="filter-dropdown">
                      <div className="empty-space"></div>
                      <div className="filter-list-main">
                        {companyFilterList.map((filterListItem, index) => (
                          <div key={index} className="filter-item">
                            <input
                              className="filter-check-box mr-1"
                              type="checkbox"
                              checked={filterListItem.is_selected_local}
                              onChange={(e) =>
                                handleCheckBoxChangeAtCompanyFilterIndex(
                                  e,
                                  index
                                )
                              }
                            />
                            {filterListItem.filter_string}
                          </div>
                        ))}
                      </div>
                      <div className="filter-buttons-section">
                        {/* {poTypeFilterCheckedCount > 0} */}
                        {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}
                        <Button
                          variant="outline-primary"
                          className="filters-button"
                          onClick={resetCompanyNameFilter}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="outline-primary"
                          className="filters-button"
                          onClick={clearCompaniesFilter}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          className="filters-button"
                          disabled={companyFilterCheckedCountLocal == 0}
                          onClick={applyCompanyTypeFilter}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </EscapeKeyListener>
                )}
              </th>
              <th>
                Delivery Location
                {deliveryLocationCheckedCountGlobal == 0 ? (
                  <Filter
                    className="filter-option ml-1"
                    onClick={() => setIsDeliveryLocationFilterVisible(true)}
                  ></Filter>
                ) : (
                  <FilterSquareFill
                    className="filter-option ml-1"
                    style={{ color: "green" }}
                    onClick={() => setIsDeliveryLocationFilterVisible(true)}
                  ></FilterSquareFill>
                )}
                {isDeliveryLocationFilterVisible && (
                  <EscapeKeyListener onEscape={clearDeliveryLocationFilter}>
                    <div className="filter-dropdown">
                      <div className="empty-space"></div>
                      <div className="filter-list-main">
                        {deliveryLocationFilterList.map(
                          (filterListItem, index) => (
                            <div key={index} className="filter-item">
                              <input
                                className="filter-check-box mr-1"
                                type="checkbox"
                                checked={filterListItem.is_selected_local}
                                onChange={(e) =>
                                  handleCheckBoxChangeAtDeliveryLocationFilterIndex(
                                    e,
                                    index
                                  )
                                }
                              />
                              {filterListItem.filter_string}
                            </div>
                          )
                        )}
                      </div>
                      <div className="filter-buttons-section">
                        {/* {poTypeFilterCheckedCount > 0} */}
                        {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}
                        <Button
                          variant="outline-primary"
                          className="filters-button"
                          onClick={resetDeliveryLocationFilter}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="outline-primary"
                          className="filters-button"
                          onClick={clearDeliveryLocationFilter}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          className="filters-button"
                          disabled={deliveryLocationCountCheckedCountLocal == 0}
                          onClick={applyDeliveryLocationFilter}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </EscapeKeyListener>
                )}
              </th>
              <th>
                PO status
                {poStatusFilterCheckedCountGlobal == 0 ? (
                  <Filter
                    className="filter-option ml-1"
                    onClick={() => setIsPoStatusFilterVisible(true)}
                  ></Filter>
                ) : (
                  <FilterSquareFill
                    className="filter-option ml-1"
                    style={{ color: "green" }}
                    onClick={() => setIsPoStatusFilterVisible(true)}
                  ></FilterSquareFill>
                )}
                {isPoStatusFilterVisible && (
                  <EscapeKeyListener onEscape={clearPOStatusFilter}>
                    <div className="filter-dropdown">
                      <div className="empty-space"></div>
                      <div className="filter-list-main">
                        {poStatusFilterList.map((filterListItem, index) => (
                          <div key={index} className="filter-item">
                            <input
                              className="filter-check-box mr-1"
                              type="checkbox"
                              checked={filterListItem.is_selected_local}
                              onChange={(e) =>
                                handleCheckBoxChangeAtPoStatusFilterIndex(
                                  e,
                                  index
                                )
                              }
                            />
                            {filterListItem.filter_string}
                          </div>
                        ))}
                      </div>
                      <div className="filter-buttons-section">
                        {/* {poTypeFilterCheckedCount > 0} */}
                        {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}
                        <Button
                          variant="outline-primary"
                          className="filters-button"
                          onClick={resetPoStatusFilter}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="outline-primary"
                          className="filters-button"
                          onClick={clearPOStatusFilter}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          className="filters-button"
                          disabled={poStatusFilterCheckedCountLocal == 0}
                          onClick={applyPOStatusFilter}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </EscapeKeyListener>
                )}
              </th>
              <th>Is WIP ?</th>
              <th>SKU Count</th>
              <th>Demand Quantity</th>
              <th>Dispatched Quantity</th>
              <th>Packed Quantity</th>
              <th>Pending Quantity</th>
              <th>Quantity Fill Rate</th>
              <th>SKU Fill Rate</th>
              <th>Consignment Count</th>
              <th>Boxes Dispatched</th>
              <th>Boxes Packed</th>
              <th>Issue Date</th>
              <th>
                Expiry Date
                {expiryDateRangeGlobal[0] || expiryDateRangeGlobal[1] ? (
                  <FilterSquareFill
                    className="filter-option ml-1"
                    style={{ color: "green" }}
                    onClick={() => setIsExpiryDateFilterVisible(true)}
                  />
                ) : (
                  <Filter
                    className="filter-option ml-1"
                    onClick={() => setIsExpiryDateFilterVisible(true)}
                  />
                )}
                {isExpiryDateFilterVisible && (
                  <EscapeKeyListener onEscape={clearExpiryDateFilter}>
                    <div className="filter-dropdown-right">
                      <div className="empty-space"></div>
                      <div className="filter-list-main ml-2 mr-2">
                        <DatePicker
                          selected={expiryDateRangeLocal[0]}
                          onChange={(dates: [Date | null, Date | null]) =>
                            setExpiryDateRangeLocal(dates)
                          }
                          startDate={expiryDateRangeLocal[0]}
                          endDate={expiryDateRangeLocal[1]}
                          selectsRange
                          dateFormat="yyyy-MM-dd"
                          placeholderText="Select Date Range"
                          className="form-control"
                        />
                      </div>
                      <div className="filter-buttons-section mt-2 ml-2 mr-2">
                        <Button
                          variant="outline-primary"
                          className="filters-button"
                          onClick={resetExpiryDateFilter}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="outline-primary"
                          className="filters-button"
                          onClick={clearExpiryDateFilter}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          className="filters-button"
                          disabled={
                            !expiryDateRangeLocal[0] || !expiryDateRangeLocal[1]
                          }
                          onClick={applyExpiryDateFilter}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </EscapeKeyListener>
                )}
              </th>
              <th>PO addition Date</th>
              <th>PO added By</th>
            </tr>
          </thead>

          <tbody>
            {allIncomingPurchaseOrders.map((purchase_order_item, index) => {
              return (
                <tr className="catalogues-search-bar-row" key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={purchase_order_item.is_selected}
                      onChange={(e) => handleCheckBoxChangeAtIndex(e, index)}
                    />
                  </td>
                  <td className="po-id-link">
                    <a
                      href={`/incomingPos/info/details/${purchase_order_item.po_number}`}
                    >
                      {purchase_order_item.po_number}
                    </a>
                  </td>
                  <td>{purchase_order_item.po_type}</td>
                  <td>{purchase_order_item.company_name}</td>
                  <td>{purchase_order_item.delivery_city}</td>
                  <td
                    className={getCalculatedPoStatusColor(
                      purchase_order_item.calculated_po_status
                    )}
                  >
                    {purchase_order_item.calculated_po_status}
                  </td>
                  <td className={getWIPColor(purchase_order_item.is_wip)}>
                    {purchase_order_item.is_wip}
                  </td>
                  <td>{purchase_order_item.analytics_object.sku_count}</td>
                  <td>{purchase_order_item.analytics_object.total_demand}</td>
                  <td>
                    {purchase_order_item.analytics_object.total_dispatched}
                  </td>
                  <td>{purchase_order_item.analytics_object.total_packed}</td>

                  <td>{purchase_order_item.analytics_object.total_pending}</td>
                  <td>
                    {purchase_order_item.analytics_object.quantity_fill_rate}
                  </td>
                  <td>{purchase_order_item.analytics_object.sku_fill_rate}</td>
                  <td>
                    {purchase_order_item.analytics_object.total_consignments}
                  </td>
                  <td>
                    {purchase_order_item.analytics_object.boxes_dispatched}
                  </td>
                  <td>{purchase_order_item.analytics_object.boxes_packed}</td>

                  <td>
                    {DateTimeUtils.formatDateOnly(
                      purchase_order_item.po_issue_date
                    )}
                  </td>
                  <td>
                    {DateTimeUtils.formatDateOnly(
                      purchase_order_item.expiry_date
                    )}
                  </td>
                  <td>
                    {DateTimeUtils.formatDateTime(
                      purchase_order_item.created_at
                    )}
                  </td>
                  <td>{purchase_order_item.created_by}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        {allIncomingPurchaseOrders.length == 0 && (
          <div className="purchase-orders-table-container no-item">
            {isLoading ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <div>No Purchase Orders were found.</div>
            )}
          </div>
        )}
        {searchParams.item_count > 0 &&
          searchParams.item_count < searchParams.expected_item_count && (
            <div className="load-more-catalogues-button-container">
              <Button
                variant="outline-primary"
                className="load-more-catalogues-button"
                onClick={() => loadMore()}
              >
                Load More..
              </Button>
            </div>
          )}
      </div>

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
        </div>
      )}

      <Modal
        show={downloadPendencyPDF}
        onHide={() => setDownloadPendencyPDF(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Download Pendency PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This PDF holds the pendency of selected POs in PDF format.
          <div className="mt-2 mb-2">
            <label>
              <input
                type="checkbox"
                checked={isAddWarehouseInventoryColumnChecked}
                onChange={handleShowWarehouseInventoryCheckboxChange}
              />
              Add Warehouse Inventory Column
            </label>
          </div>
          {poSkuLevelReportObjectList.length > 0 && (
            <PDFDownloadLink
              fileName={`selected_pos_pendency.pdf`}
              document={
                <CompanyPurchaseOrderPendingDispatchMultiPDF
                  showWarehouseInventoryColumn={
                    isAddWarehouseInventoryColumnChecked
                  }
                  createdBy={userDetailsFromStore.user_details.userId}
                  chunkSize={30}
                  poSkuLevelReportObjectList={poSkuLevelReportObjectList}
                />
              }
            >
              {({ blob, url, loading, error }) => (
                <Button className="vendor-action-button" disabled={loading}>
                  {loading ? "Loading document..." : "Download PDF"}
                </Button>
              )}
            </PDFDownloadLink>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CompanyPurchaseOrdersAllPurchaseOrders;
