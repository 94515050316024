import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./CSS/PurchaseOrdersAddItem.css";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  ModalProps,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";

import {
  ErrorMessageBody,
  ImageHashMapState,
  PoItem,
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderItemWithLisiting,
  Specialty,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _ from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";

import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "./NetworkUtils/VendorsNetworkUtil";

import Header from "../Header";
import VendorListingsView from "./VendorListingsView";
import VendorsListingSearchBar from "./VendorsListingSearchBar";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
import PurchaseOrdersVendorListingsView from "./PurchaseOrdersVendorListingsView";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";
import PurchaseOrderPDF from "./PurchaseOrderPDF";
import PurchaseOrderNetworkUtils from "./NetworkUtils/PurchaseOrderNetworkUtils";
import PurchaseOrdersPublishedItemsView from "./PurchaseOrdersPublishedItemsView";
import PurchaseOrderPDF_v2 from "./PurchaseOrderPDF_v2";
import PurchaseOrderPDF_v3 from "./PurchaseOrderPDF_v3";
import PurchaseOrderPDF_v4 from "./PurchaseOrderPDF_v4";
import CatalogueNetworkUtils from "../Catalogues/NetworkUtils/CatalogueNetworkUtils";
import { ImageHashMapVendorPurchaseOrder__Action } from "../../state/action-creators";
import {
  ImageHashMapActionTypes,
  VendorPurchaseOrderImageHashMapActionTypes,
} from "../../state/action-types";
import { AppDispatch } from "../../state";

interface RouteParams {
  po_id: string;
}

function PurchaseOrderPublished() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const chunkSize = 4;
  const { po_id } = useParams<RouteParams>();
  const dispatch: AppDispatch = useDispatch();

  const [purchaseOrderItemsWithListing, setPurchaseOrderItemsWithListing] =
    useState<PurchaseOrderItemWithLisiting[]>([]);
  const [currentPurchaseOrder, setCurrentPurchaseOrder] =
    useState<PurchaseOrder | null>(null);
  const [modalShow, setModalShow] = useState(false);
  const [modifyPurchaseOrder, setModifyPurchaseOrder] = useState(false);
  const [cancelPurchaseOrder, setCancelPurchaseOrder] = useState(false);
  const [chunkedPoItems, setChunkedPoItems] = useState<
    PurchaseOrderItemWithLisiting[][]
  >([]);
  const [
    filteredPurchaseOrderItemsWithListing,
    setFilteredPurchaseOrderItemsWithListings,
  ] = useState<PurchaseOrderItemWithLisiting[]>([]);

  const [generatePDF, setGeneratePDF] = useState(false);
  const [downloadsReady, setDownloadsReady] = useState<boolean>(false);
  const [imageDownloadStart, setImageDownloadStart] = useState<boolean>(false);
  const [areImagesAlreadyDownloaded, setAreImagesAlreadyDownloaded] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [imageSources, setImageSources] = useState<string[]>([]);
  const [currentVendor, setCurrentVendor] = useState<Vendor>({
    id: 0,
    vendor_name: "",
    vendor_address_line: "",
    vendor_city: "",
    vendor_state: "",
    vendor_postal_code: "",
    vendor_gstin: "",
    vendor_contact_number: "",
    created_by: "",
    modified_by: "",
    created_at: "",
    updated_at: "",
    specialties: [],
  });

  const history = useHistory();
  const location = useLocation();
  const catalogueNetworkUtils = new CatalogueNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const purchaseOrderNetworkUtil = new PurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  useEffect(() => {
    // if (!userDetailsFromStore.authorities.includes("AUTH_ADMIN")) {
    //   history.push(`/listings/all`);
    // }
  }, []);

  useEffect(() => {
    const fetchPurchaseOrderByPOId = async (poId: string) => {
      console.log(poId);
      try {
        const response = await purchaseOrderNetworkUtil.getPurchaseOrderById(
          poId
        );
        console.log(response.data);
        setCurrentPurchaseOrder(response.data);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
      }
    };

    fetchPurchaseOrderByPOId(po_id);
  }, [po_id]);

  useEffect(() => {
    const fetchVendorByVendorId = async (vendor_id: string) => {
      try {
        const response = await vendorsNetworkUtil.getVendorByVendorId(
          vendor_id
        );
        console.log(response.data);
        setCurrentVendor(response.data);
      } catch (error) {
        console.error("Error in fetching vendor by vendor_id :", error);
      }
    };

    const getPurchaseOrderItemsWithListingByPurchaseOrderId = async (
      poId: number
    ) => {
      try {
        const response =
          await purchaseOrderNetworkUtil.getPurcahseOrderItemsWithListing(
            poId.toString()
          );

        dispatch(
          ImageHashMapVendorPurchaseOrder__Action(
            VendorPurchaseOrderImageHashMapActionTypes.INIT_MAP,
            {
              key: "",
              value: 0,
              purchaseOrderItemWithListings: response.data,
            }
          )
        );
        setPurchaseOrderItemsWithListing(response.data);
        setFilteredPurchaseOrderItemsWithListings(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(
          "Error in getting purchase order items by poId : ",
          error
        );
        setIsLoading(false);
      }
    };

    if (currentPurchaseOrder) {
      if (
        currentPurchaseOrder.status == "PENDING" ||
        currentPurchaseOrder.status == "MARKED_MODIFICATION"
      ) {
        history.push(`/vendor-hub/all-purchase-orders/add-items/${po_id}`);
        return;
      }
      fetchVendorByVendorId(currentPurchaseOrder.vendor_id.toString());
      getPurchaseOrderItemsWithListingByPurchaseOrderId(
        currentPurchaseOrder.po_id
      );
    }
  }, [currentPurchaseOrder]);

  useEffect(() => {
    setChunkedPoItems(chunkArray(purchaseOrderItemsWithListing));
  }, [purchaseOrderItemsWithListing]);

  const chunkArray = (
    array: PurchaseOrderItemWithLisiting[]
  ): PurchaseOrderItemWithLisiting[][] => {
    return Array.from(
      { length: Math.ceil(array.length / chunkSize) },
      (_, index) =>
        array.slice(index * chunkSize, index * chunkSize + chunkSize)
    );
  };

  const handleSearch = (query: string) => {
    console.log("purchase order item searched ", query);
    if (query.length == 0)
      setFilteredPurchaseOrderItemsWithListings(
        _.cloneDeep(purchaseOrderItemsWithListing)
      );
    else
      setFilteredPurchaseOrderItemsWithListings(
        filterPurchaseOrderItemsWithListings(query)
      );
  };

  const filterPurchaseOrderItemsWithListings = (query: string) => {
    query = query.toLowerCase();
    return purchaseOrderItemsWithListing.filter((poItem) => {
      const skuMatches = poItem.listing.sku_id.toLowerCase().includes(query);

      const opsTagMatches = poItem.listing.ops_tag
        .toLowerCase()
        .includes(query);

      return skuMatches || opsTagMatches;
    });
  };

  const onCardClick = (currentListing: PurchaseOrderItemWithLisiting) => {
    console.log("card clicked: ", currentListing);
    setModalShow(true);
    // setSelectedListing(currentListing);
  };

  const markForModification = async () => {
    try {
      const response = await purchaseOrderNetworkUtil.markPoForModification(
        po_id
      );
      history.push(`/vendor-hub/all-purchase-orders/add-items/${po_id}`);
      console.log(response.data);
    } catch (error) {
      console.error(
        "Error in marking purchase order for modification : ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const markForCancellation = async () => {
    try {
      const response = await purchaseOrderNetworkUtil.markPoForCancellation(
        po_id
      );
      setCurrentPurchaseOrder(response.data);
      toast.success("Cancelled PO successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setCancelPurchaseOrder(false);
    } catch (error) {
      console.error(
        "Error in marking purchase order for cancellation : ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const downloadThemesAndImages = async () => {
    await downloadAllImages();
  };

  const downloadAllImages = async () => {
    if (!areImagesAlreadyDownloaded) {
      setImageDownloadStart(true);
      let i = 0;
      let url = "";
      for (i = 0; i < purchaseOrderItemsWithListing.length; i++) {
        const sku_id = purchaseOrderItemsWithListing[i].listing.sku_id;
        const img_index = hashMap.imageHashmap[sku_id];
        if (img_index > 4) {
          url = "images/not-available.jpg";
        } else {
          let images = [
            purchaseOrderItemsWithListing[i].listing.img_wdim,
            purchaseOrderItemsWithListing[i].listing.img_hd,
            purchaseOrderItemsWithListing[i].listing.img_white,
            purchaseOrderItemsWithListing[i].listing.img_link1,
            purchaseOrderItemsWithListing[i].listing.img_link2,
          ];
          url = images[img_index];
        }

        let resourceToAdd = await catalogueNetworkUtils.downloadImageWithRetry(
          url.substring(url.lastIndexOf("/") + 1)
        );

        setImageSources((prevImageSources) => [
          ...prevImageSources,
          resourceToAdd.image,
        ]);
        // await delay(500);
      }
      setAreImagesAlreadyDownloaded(true);
    }
    // console.log(imageSources.length);
    // console.log(imageSources[0]);
    // console.log("code shift");
    setGeneratePDF(true);
    setDownloadsReady(true);
    setImageDownloadStart(false);
  };

  const handleClose = () => {
    setGeneratePDF(false);
  };

  const hashMap: ImageHashMapState = useAppSelector(
    (state) => state.purchase_order_image_hashmap_store
  );

  return (
    <div className="overlay">
      <div className="vendor-details">
        <Header />
        <div className="main-container">
          {purchaseOrderItemsWithListing.length > 0 && currentPurchaseOrder ? (
            <div className="vendor-action-container">
              {currentPurchaseOrder.status != "MARKED_CANCELLED" && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Purchase Order Actions</h4>
                  </div>

                  <div className="vendor-action-buttons-container">
                    {/* {generatePDF && downloadsReady
                      ? currentVendor &&
                        currentPurchaseOrder &&
                        purchaseOrderItemsWithListing.length > 0 && (
                          <div>
                            <PDFDownloadLink
                              document={
                                <PurchaseOrderPDF_v3
                                  chunkedPoItems={chunkedPoItems}
                                  purchaseOrder={currentPurchaseOrder}
                                  vendor={currentVendor}
                                  totalItems={
                                    purchaseOrderItemsWithListing.length
                                  }
                                  totalQuantity={purchaseOrderItemsWithListing.reduce(
                                    (total, poItem) =>
                                      total + poItem.required_quantity,
                                    0
                                  )}
                                />
                              }
                              fileName={currentPurchaseOrder.po_id.toString()}
                            >
                              {({ blob, url, loading, error }) => (
                                <Button
                                  className="vendor-action-button"
                                  disabled={loading}
                                >
                                  {loading
                                    ? "Loading document..."
                                    : "Download Purchase order PDF"}
                                </Button>
                              )}
                            </PDFDownloadLink>
                          </div>
                        )
                      : currentPurchaseOrder.status != "MARKED_COMPLETED" &&
                        currentPurchaseOrder.status != "MARKED_CANCELLED" && (
                          <Button
                            variant="outline-primary"
                            className="vendor-action-button"
                            onClick={() => setGeneratePDF(true)}
                          >
                            Generate Purchase Order PDF
                          </Button>
                        )} */}

                    {currentPurchaseOrder.status != "MARKED_COMPLETED" &&
                      currentPurchaseOrder.status != "MARKED_CANCELLED" && (
                        <Button
                          variant="outline-primary"
                          className="vendor-action-button"
                          onClick={() => {
                            downloadThemesAndImages();
                          }}
                        >
                          Generate Purchase Order PDF
                        </Button>
                      )}

                    {currentPurchaseOrder.status != "MARKED_COMPLETED" &&
                      currentPurchaseOrder.status != "MARKED_CANCELLED" && (
                        <Button
                          variant="outline-primary"
                          className="vendor-action-button"
                          onClick={() => setModifyPurchaseOrder(true)}
                        >
                          Modify Purchase Order
                        </Button>
                      )}
                    {currentPurchaseOrder.status != "MARKED_COMPLETED" &&
                      currentPurchaseOrder.status != "MARKED_CANCELLED" && (
                        <Button
                          variant="outline-primary"
                          className="vendor-action-button"
                          onClick={() => setCancelPurchaseOrder(true)}
                        >
                          Cancel Purchase Order
                        </Button>
                      )}
                    {currentPurchaseOrder.status != "MARKED_COMPLETED" &&
                      currentPurchaseOrder.status != "MARKED_CANCELLED" && (
                        <Button
                          variant="outline-primary"
                          className="vendor-action-button"
                        >
                          Start Receiving Items against this Purchase Order
                        </Button>
                      )}
                  </div>
                </div>
              )}
              {purchaseOrderItemsWithListing.length > 0 && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">
                      Published Purchase Order Details
                    </h4>
                    <div className="vendor-details-button-container"></div>
                  </div>
                  <div className="grid-1-cells">
                    {purchaseOrderItemsWithListing.length != 0 && (
                      <div className="grid-2-cells">
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total SKUs : </b>{" "}
                            {purchaseOrderItemsWithListing.length.toString()}
                          </div>
                        </Alert>
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total Quantity : </b>
                            {purchaseOrderItemsWithListing.reduce(
                              (total, poItem) =>
                                total + poItem.required_quantity,
                              0
                            )}
                          </div>
                        </Alert>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {currentPurchaseOrder && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Purchase Order Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="grid-item-details-card">
                      <label>PO status</label>
                      {currentPurchaseOrder.status == "PENDING_PUBLISHED" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="primary">
                          PUBLISHED
                        </Badge>
                      )}
                      {currentPurchaseOrder.status == "MARKED_CANCELLED" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="dark">
                          MARKED CANCELLED
                        </Badge>
                      )}
                      {currentPurchaseOrder.status == "MARKED_COMPLETED" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="success">
                          MARKED COMPLETED
                        </Badge>
                      )}
                    </div>
                    <div className="grid-item-details-card">
                      <label>Purchase Order Id</label>
                      <input
                        type="text"
                        name="po_id"
                        value={currentPurchaseOrder.po_id}
                        disabled={true}
                      />
                    </div>

                    <div className="grid-item-details-card">
                      <label>Expiry Date</label>
                      <input
                        type="text"
                        name="po_expiry_date"
                        value={DateTimeUtils.formatDateTime(
                          currentPurchaseOrder.expected_date
                        )}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Purchase Order Remarks</label>
                      <input
                        type="text"
                        name="po_remarks"
                        value={currentPurchaseOrder.po_remarks}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Created By</label>
                      <input
                        type="text"
                        name="po_created_by"
                        value={currentPurchaseOrder.created_by}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Date Created</label>
                      <input
                        type="text"
                        name="po_date_created"
                        value={DateTimeUtils.formatDateTime(
                          currentPurchaseOrder.created_at
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              {currentPurchaseOrder && currentVendor && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Vendor Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="grid-item-details-card">
                      <label>Vendor Id</label>
                      <input
                        type="text"
                        name="vendor_id"
                        value={currentVendor.id}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor Name</label>
                      <input
                        type="text"
                        name="vendor_name"
                        value={currentVendor.vendor_name}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor Address Line</label>
                      <input
                        type="text"
                        name="vendor_address_line"
                        value={currentVendor.vendor_address_line}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor City</label>
                      <input
                        type="text"
                        name="vendor_city"
                        value={currentVendor.vendor_city}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor State</label>
                      <input
                        type="text"
                        name="vendor_state"
                        value={currentVendor.vendor_state}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor Postal Code</label>
                      <input
                        type="text"
                        name="vendor_postal_code"
                        value={currentVendor.vendor_postal_code}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor GSTIN</label>
                      <input
                        type="text"
                        name="vendor_gstin"
                        value={currentVendor.vendor_gstin}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor Contact Number</label>
                      <input
                        type="text"
                        name="vendor_contact_number"
                        value={currentVendor.vendor_contact_number}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="vendor-action-container"></div>
          )}
          {isLoading ? (
            <div className="vendor-skus-spinner-container">
              {" "}
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="vendor-skus-container-main">
              {purchaseOrderItemsWithListing.length > 0 &&
                currentPurchaseOrder && (
                  <div className="vendor-listings-search-bar-container">
                    <VendorsListingSearchBar
                      onSearch={handleSearch}
                    ></VendorsListingSearchBar>
                  </div>
                )}
              <div className="vendor-skus-container">
                <div className="listings-container purchase-order-vendor-listings-container">
                  <PurchaseOrdersPublishedItemsView
                    publishedListings={filteredPurchaseOrderItemsWithListing}
                    onCardClick={onCardClick}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={modifyPurchaseOrder}
        onHide={() => setModifyPurchaseOrder(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modify Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure about modification? Please ensure to inform vendor about
          the updated purchase order.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModifyPurchaseOrder(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={() => markForModification()}>
            Understood
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cancelPurchaseOrder}
        onHide={() => setCancelPurchaseOrder(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this Purchase Order? Please note that
          purchase orders whose status is "Published" and against which no
          quantities were received can only be marked "Cancelled".
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setCancelPurchaseOrder(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => markForCancellation()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={imageDownloadStart}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <span className="image-being-downloaded-spinner">
            <Spinner animation="grow" className="mr-4" />
            Please wait while the images are being downloaded..
          </span>
        </Modal.Body>
      </Modal>

      <Modal
        show={generatePDF}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {generatePDF &&
            downloadsReady &&
            currentVendor &&
            currentPurchaseOrder &&
            purchaseOrderItemsWithListing.length > 0 && (
              <div className="mt-4">
                <PDFDownloadLink
                  document={
                    <PurchaseOrderPDF_v4
                      chunkedPoItems={chunkedPoItems}
                      purchaseOrder={currentPurchaseOrder}
                      vendor={currentVendor}
                      totalItems={purchaseOrderItemsWithListing.length}
                      totalQuantity={purchaseOrderItemsWithListing.reduce(
                        (total, poItem) => total + poItem.required_quantity,
                        0
                      )}
                      hashMap={hashMap}
                      imageSources={imageSources}
                      chunkSize={chunkSize}
                    />
                  }
                  fileName={
                    currentPurchaseOrder.po_id.toString() +
                    "_" +
                    currentVendor.vendor_name
                  }
                >
                  {({ blob, url, loading, error }) => (
                    <button
                      className="download-pdf-button"
                      type="button"
                      disabled={loading}
                    >
                      {loading
                        ? "Loading document..."
                        : "Download Purchase Order PDF"}
                    </button>
                  )}
                </PDFDownloadLink>
              </div>
            )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PurchaseOrderPublished;
