import React, { useEffect, useState, KeyboardEvent } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/PurchaseOrdersAddItem.css";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  ModalProps,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";

import {
  PoItem,
  PurchaseOrder,
  PurchaseOrderItem,
  Specialty,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _ from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";

import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "./NetworkUtils/VendorsNetworkUtil";
import Header from "../Header";
import VendorListingsView from "./VendorListingsView";
import VendorsListingSearchBar from "./VendorsListingSearchBar";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
import PurchaseOrdersVendorListingsView from "./PurchaseOrdersVendorListingsView";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";
import PurchaseOrderPDF from "./PurchaseOrderPDF";
import PurchaseOrderNetworkUtils from "./NetworkUtils/PurchaseOrderNetworkUtils";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
  sub_category: string;
  po_id: string;
  vendor_id: string;
}

function PurchaseOrdersAddItem() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const { po_id } = useParams<RouteParams>();
  const [selectedListing, setSelectedListing] = useState<VendorListing | null>(
    null
  );
  const [selectedPurchaseOrderItem, setSelectedPurchaseOrderItem] =
    useState<PurchaseOrderItem | null>(null);
  const [isItemPartOfPurchaseOrder, setIsItemPartOfPurchaseOrder] =
    useState<boolean>(false);
  const [vendorListings, setVendorListings] = useState<VendorListing[]>([]);
  const [poItems, setPoItems] = useState<PoItem[]>([]);
  const [purchaseOrderItems, setPurchaseOrderItems] = useState<
    PurchaseOrderItem[]
  >([]);
  const [currentPurchaseOrder, setCurrentPurchaseOrder] =
    useState<PurchaseOrder | null>(null);
  const [modalShow, setModalShow] = useState(false);

  const [filteredVendorListings, setFilteredVendorListings] = useState<
    VendorListing[]
  >([]);
  const [currentVendor, setCurrentVendor] = useState<Vendor>({
    id: 0,
    vendor_name: "",
    vendor_address_line: "",
    vendor_city: "",
    vendor_state: "",
    vendor_postal_code: "",
    vendor_gstin: "",
    vendor_contact_number: "",
    created_by: "",
    modified_by: "",
    created_at: "",
    updated_at: "",
    specialties: [],
  });

  const [localVendor, setLocalVendor] = useState<Vendor>(currentVendor);
  const [requiredQuantity, setRequiredQuantity] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const purchaseOrderNetworkUtil = new PurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  useEffect(() => {
    // if (!userDetailsFromStore.authorities.includes("AUTH_ADMIN")) {
    //   history.push(`/listings/all`);
    // }
  }, []);

  useEffect(() => {
    const fetchVendorByVendorId = async (vendor_id: string) => {
      try {
        const response = await vendorsNetworkUtil.getVendorByVendorId(
          vendor_id
        );
        // console.log(response.data);
        setCurrentVendor(response.data);
        setLocalVendor(response.data);
      } catch (error) {
        console.error("Error in fetching vendor by vendor_id :", error);
      }
    };

    const fetchVendorListingsByVendorId = async (vendor_id: string) => {
      try {
        const response = await vendorsNetworkUtil.getVendorListingsByVendorId(
          vendor_id
        );
        setIsLoading(false);
        // console.log(response.data);
        setVendorListings(response.data);
        setFilteredVendorListings(response.data);
      } catch (error) {
        setIsLoading(false);
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
      }
    };

    const getPurchaseOrderItemsByPurchaseOrderId = async (poId: number) => {
      try {
        const response = await purchaseOrderNetworkUtil.getPurcahseOrderItems(
          poId.toString()
        );
        // console.log(response.data);
        setPurchaseOrderItems(response.data);
      } catch (error) {
        console.error(
          "Error in getting purchase order items by poId : ",
          error
        );
      }
    };

    if (currentPurchaseOrder) {
      if (currentPurchaseOrder.status == "PENDING_PUBLISHED") {
        history.push(`/vendor-hub/all-purchase-orders/published/${po_id}`);
      } else {
        fetchVendorByVendorId(currentPurchaseOrder.vendor_id.toString());
        fetchVendorListingsByVendorId(
          currentPurchaseOrder.vendor_id.toString()
        );
        getPurchaseOrderItemsByPurchaseOrderId(currentPurchaseOrder.po_id);
      }
    }
  }, [currentPurchaseOrder]);

  useEffect(() => {
    if (!modalShow) {
      setRequiredQuantity(0);
      setIsItemPartOfPurchaseOrder(false);
      setSelectedListing(null);
      setSelectedPurchaseOrderItem(null);
    }
  }, [modalShow]);

  useEffect(() => {
    let sku: string = "";
    if (selectedListing) {
      sku = selectedListing.sku_id;
    } else if (selectedPurchaseOrderItem) {
      sku = selectedPurchaseOrderItem.sku_id;
    }

    if (sku != "") {
      const obj: PurchaseOrderItem | undefined = purchaseOrderItems.find(
        (poItem) => poItem.sku_id == sku
      );
      if (obj) {
        setIsItemPartOfPurchaseOrder(true);
        setRequiredQuantity(obj.required_quantity);
      }
    }
  }, [selectedListing, selectedPurchaseOrderItem]);

  useEffect(() => {
    const fetchPurchaseOrderByPOId = async (poId: string) => {
      try {
        const response = await purchaseOrderNetworkUtil.getPurchaseOrderById(
          poId
        );
        // console.log(response.data);
        setCurrentPurchaseOrder(response.data);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
      }
    };

    fetchPurchaseOrderByPOId(po_id);
  }, [po_id]);

  useEffect(() => {
    setFilteredVendorListings(_.cloneDeep(vendorListings));
  }, [vendorListings]);

  const handleSearch = (query: string) => {
    // dispatch(SearchText__Action(query));
    // search(query, 1);
    // console.log("vendor listing searched ", query);
    if (query.length == 0)
      setFilteredVendorListings(_.cloneDeep(vendorListings));
    else setFilteredVendorListings(filterVendorsListings(query));
  };

  const filterVendorsListings = (query: string) => {
    query = query.toLowerCase();
    return vendorListings.filter((vendorListing) => {
      const skuMatches = vendorListing.listing.sku_id
        .toLowerCase()
        .includes(query);

      const opsTagMatches = vendorListing.listing.ops_tag
        .toLowerCase()
        .includes(query);

      return skuMatches || opsTagMatches;
    });
  };

  const handleRequiredQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setRequiredQuantity(parseInt(value));
  };

  const onCardClick = (currentListing: VendorListing) => {
    // console.log("card clicked: ", currentListing);
    setModalShow(true);
    setSelectedListing(currentListing);
  };

  const handleOnClickAdd = () => {
    if (requiredQuantity <= 0) {
      toast.error("Please enter a non-zero quantity to add.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return;
    }
    addToPurchaseOrder(
      selectedListing,
      selectedPurchaseOrderItem,
      requiredQuantity
    );
    setModalShow(false);
  };

  const addToPurchaseOrder = async (
    listing: VendorListing | null,
    purchaseOrderItem: PurchaseOrderItem | null,
    qty: number
  ) => {
    if (listing || purchaseOrderItem) {
      const sku: string = listing
        ? listing.sku_id
        : purchaseOrderItem
        ? purchaseOrderItem.sku_id
        : "";
      if (sku == "") return;

      //check sku in purchaseOrderItems
      const obj: PurchaseOrderItem | undefined = purchaseOrderItems.find(
        (poItem) => poItem.sku_id == sku
      );

      let newPurchaseOrderItem: any;
      let isNewItem: boolean = true;
      if (obj) {
        obj.required_quantity = qty;
        newPurchaseOrderItem = obj;
        isNewItem = false;
      } else {
        newPurchaseOrderItem = {
          po_id: po_id,
          sku_id: sku,
          required_quantity: qty,
          created_by: userDetailsFromStore.user_details.userId,
        };
      }

      try {
        const response = await purchaseOrderNetworkUtil.addItemToPurchaseOrder(
          newPurchaseOrderItem,
          po_id
        );
        // console.log(response.data);
        if (isNewItem) {
          setPurchaseOrderItems((prevList) => [...prevList, response.data]);
        } else {
          const updatedList = purchaseOrderItems.map((poItem, index) =>
            poItem.sku_id === sku ? response.data : poItem
          );
          setPurchaseOrderItems(updatedList);
        }
        toast.success("Success.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        // setCurrentPurchaseOrder(response.data);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
        toast.error("Error while adding, try again.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const onPurchaseOrderItemClick = (poItem: PurchaseOrderItem) => {
    setModalShow(true);
    setSelectedPurchaseOrderItem(poItem);
    setRequiredQuantity(poItem.required_quantity);
  };

  const handleOnClickDelete = async () => {
    if (selectedPurchaseOrderItem || selectedListing) {
      const sku: string = selectedPurchaseOrderItem
        ? selectedPurchaseOrderItem.sku_id
        : selectedListing
        ? selectedListing.sku_id
        : "";
      const itemToDelete = {
        po_id: po_id,
        sku_id: sku,
      };
      try {
        const response =
          await purchaseOrderNetworkUtil.deleteItemFromPurchaseOrder(
            itemToDelete,
            po_id
          );

        const updatedList = purchaseOrderItems.filter(
          (poItem, index) => poItem.sku_id != sku
        );
        setPurchaseOrderItems(updatedList);
        toast.success("Successfully deleted.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
        toast.error("Error while deleting, try again.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
    setModalShow(false);
  };

  const publishPO = async () => {
    try {
      const response = await purchaseOrderNetworkUtil.publishPo(po_id);
      if (currentPurchaseOrder) {
        const updatedPurchaseOrder = {
          ...currentPurchaseOrder,
          status: "PENDING_PUBLISHED",
        };
        setCurrentPurchaseOrder(updatedPurchaseOrder);
      }
      toast.success("Published successfully", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Error in publishing po :", error);
      toast.error("Error while publishing, try again.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const republishPO = async () => {
    try {
      const response = await purchaseOrderNetworkUtil.republishPo(
        po_id,
        userDetailsFromStore.user_details.userId
      );
      if (currentPurchaseOrder) {
        const updatedPurchaseOrder = {
          ...currentPurchaseOrder,
          status: "PENDING_PUBLISHED",
        };
        setCurrentPurchaseOrder(updatedPurchaseOrder);
      }
      toast.success("Re-Published successfully", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Error in republishPo :", error);
      toast.error("Error while re-publishing, try again.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // console.log(requiredQuantity);
      handleOnClickAdd();
    }
  };

  return (
    <div className="overlay">
      <div className="vendor-details">
        <Header />
        {currentPurchaseOrder && (
          <div className="main-container">
            <div className="vendor-action-container">
              <div className="vendor-basics vendors-info-card add-items-heading">
                {currentPurchaseOrder.status == "PENDING" ? (
                  <div>Add Items to PO [ {po_id} ]</div>
                ) : (
                  <div>Modify PO [ {po_id} ]</div>
                )}
              </div>
              {purchaseOrderItems.length > 0 && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Purchase Order Items</h4>
                    <div className="vendor-details-button-container">
                      {currentPurchaseOrder.status == "PENDING" && (
                        <div>
                          <button type="button" onClick={publishPO}>
                            Publish
                          </button>
                          {/* <PDFDownloadLink
                            document={<PurchaseOrderPDF poItems={poItems} />}
                            fileName="test"
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? "Loading document..." : "Download PDF"
                            }
                          </PDFDownloadLink> */}
                        </div>
                      )}
                      {currentPurchaseOrder.status == "MARKED_MODIFICATION" && (
                        <div>
                          <button type="button" onClick={republishPO}>
                            Re-Publish
                          </button>
                          {/* <PDFDownloadLink
                            document={<PurchaseOrderPDF poItems={poItems} />}
                            fileName="test"
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? "Loading document..." : "Download PDF"
                            }
                          </PDFDownloadLink> */}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="grid-1-cells">
                    {purchaseOrderItems.length != 0 && (
                      <div className="grid-2-cells">
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total SKUs : </b>{" "}
                            {purchaseOrderItems.length.toString()}
                          </div>
                        </Alert>
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total Quantity :</b>{" "}
                            {purchaseOrderItems.reduce(
                              (total, poItem) =>
                                total + poItem.required_quantity,
                              0
                            )}
                          </div>
                        </Alert>
                      </div>
                    )}

                    {purchaseOrderItems.map((poItem, index) => {
                      return (
                        <div
                          className="po-item-card"
                          key={index}
                          onClick={() => onPurchaseOrderItemClick(poItem)}
                        >
                          <div>
                            <b>{poItem.sku_id}</b> -{" "}
                            {poItem.required_quantity.toString()}
                          </div>
                          <div className="last-modified">
                            {DateTimeUtils.formatDateTime(poItem.updated_at)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {currentPurchaseOrder && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Purchase Order Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="grid-item-details-card">
                      <label>PO status</label>
                      {currentPurchaseOrder.status == "PENDING" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="danger">
                          {currentPurchaseOrder.status}
                        </Badge>
                      )}
                      {currentPurchaseOrder.status == "MARKED_MODIFICATION" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="warning">
                          MARKED FOR MODIFICATION
                        </Badge>
                      )}
                      {/* <input
                      type="text"
                      name="po_status"
                      value={currentPurchaseOrder.status}
                      disabled={true}
                    /> */}
                    </div>
                    <div className="grid-item-details-card">
                      <label>Purchase Order Id</label>
                      <input
                        type="text"
                        name="po_id"
                        value={currentPurchaseOrder.po_id}
                        disabled={true}
                      />
                    </div>

                    <div className="grid-item-details-card">
                      <label>Expiry Date</label>
                      <input
                        type="text"
                        name="po_expiry_date"
                        value={DateTimeUtils.formatDateTime(
                          currentPurchaseOrder.expected_date
                        )}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Purchase Order Remarks</label>
                      <input
                        type="text"
                        name="po_remarks"
                        value={currentPurchaseOrder.po_remarks}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Created By</label>
                      <input
                        type="text"
                        name="po_created_by"
                        value={currentPurchaseOrder.created_by}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Date Created</label>
                      <input
                        type="text"
                        name="po_date_created"
                        value={DateTimeUtils.formatDateTime(
                          currentPurchaseOrder.created_at
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              {currentPurchaseOrder && currentVendor && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Vendor Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="grid-item-details-card">
                      <label>Vendor Id</label>
                      <input
                        type="text"
                        name="vendor_id"
                        value={currentVendor.id}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor Name</label>
                      <input
                        type="text"
                        name="vendor_name"
                        value={localVendor.vendor_name}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor Address Line</label>
                      <input
                        type="text"
                        name="vendor_address_line"
                        value={localVendor.vendor_address_line}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor City</label>
                      <input
                        type="text"
                        name="vendor_city"
                        value={localVendor.vendor_city}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor State</label>
                      <input
                        type="text"
                        name="vendor_state"
                        value={localVendor.vendor_state}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor Postal Code</label>
                      <input
                        type="text"
                        name="vendor_postal_code"
                        value={localVendor.vendor_postal_code}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor GSTIN</label>
                      <input
                        type="text"
                        name="vendor_gstin"
                        value={localVendor.vendor_gstin}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor Contact Number</label>
                      <input
                        type="text"
                        name="vendor_contact_number"
                        value={localVendor.vendor_contact_number}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isLoading ? (
              <div className="vendor-skus-spinner-container">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <div className="vendor-skus-container-main">
                <div className="vendor-listings-search-bar-container">
                  <VendorsListingSearchBar
                    onSearch={handleSearch}
                  ></VendorsListingSearchBar>
                </div>
                <div className="vendor-skus-container">
                  <div className="listings-container purchase-order-vendor-listings-container">
                    <PurchaseOrdersVendorListingsView
                      vendorListings={filteredVendorListings}
                      onCardClick={onCardClick}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="add-item-quantity-card-title"
          >
            Add required quantity for -{" "}
            {(selectedListing && selectedListing.sku_id) ||
              (selectedPurchaseOrderItem && selectedPurchaseOrderItem.sku_id)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="number"
            name="order_quantity"
            value={requiredQuantity.toString()}
            onChange={(e) => handleRequiredQuantityChange(e)}
            onKeyPress={handleKeyPress}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="add-item-quantity-card-button-container">
            <Button className="mr-2" onClick={() => setModalShow(false)}>
              Cancel
            </Button>
            <Button onClick={handleOnClickAdd}>Add</Button>
            {isItemPartOfPurchaseOrder && (
              <Button className="ml-2" onClick={handleOnClickDelete}>
                Delete
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PurchaseOrdersAddItem;
