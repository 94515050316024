import React, { useEffect, useState, KeyboardEvent } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/CompanyPurchaseOrdersDetails.css";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  ModalProps,
  Offcanvas,
  Row,
  Spinner,
  Table,
  FloatingLabel,
  Form,
  Fade,
} from "react-bootstrap";

import {
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderAnalyticsObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  IncomingPurchaseOrderListingDetailsDataObjectWithLabelsData,
  LabelData,
  LabelDataV2,
  LabelsMasterDataErrorItem,
  LabelsMasterDataItem,
  Listing,
  PacksAndCombos,
  PoDocument,
  SearchParams,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";
import { PDFDocument, rgb, degrees } from "pdf-lib";

import _ from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";

import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrderLabelUtils from "./LabelUtils/CompanyPurchaseOrderLabelUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";
import Header from "../Header";
import VendorListingsView from "../Vendors/VendorListingsView";
import VendorsListingSearchBar from "../Vendors/VendorsListingSearchBar";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
// import PurchaseOrdersVendorListingsView from "./PurchaseOrdersVendorListingsView";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";

import { Gif } from "@mui/icons-material";
import Papa from "papaparse";
import jsonexport from "jsonexport";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";
import CompanyPurchaseOrderPendingDispatchPDF from "./CompanyPurchaseOrderPendingDispatchPDF";
import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";
import { Download, PencilSquare } from "react-bootstrap-icons";
import { title } from "process";
import { color } from "html2canvas/dist/types/css/types/color";
import CompanyPurchaseOrdersPoTypeDropDown from "./CompanyPurchaseOrdersPoTypeDropDown";
import { ocassions } from "./Constants/CompanyPurchaseOrdersConstants";

interface RouteParams {
  po_id: string;
}

interface RadioOption {
  label: string;
  value: string;
}

enum LabelSize {
  SeventyForty = "70By40",
  SeventyFiveThirtyEight = "75By38",
}

enum MarketedBy {
  EunoiaCrafts = "ECIPL, Warehouse 1, Jaat Colony, Khasra 1660, Bhakrota, Jaipur, Rajasthan, 302026, Tel. no: 8100418100 & e-Mail: care@ecraftindia.com",
  Intellozene = "Intellozene, Warehouse 1, Jaat Colony, Khasra 1660, Bhakrota, Jaipur, Rajasthan, 302026, Tel. no: 8100418100 & e-Mail: care@ecraftindia.com",
  Flipkart = "M/s Flipkart India Private Limited, Buildings Alyssa",
}

enum ManufacturedBy {
  EunoiaCrafts = "ECIPL, Warehouse 1, Jaat Colony, Khasra 1660, Bhakrota, Jaipur, Rajasthan, 302026, Tel. no: 8100418100 & e-Mail: care@ecraftindia.com",
  Intellozene = "Intellozene, Warehouse 1, Jaat Colony, Khasra 1660, Bhakrota, Jaipur, Rajasthan, 302026, Tel. no: 8100418100 & e-Mail: care@ecraftindia.com",
}

function CompanyPurchaseOrdersDetails() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const { po_id } = useParams<RouteParams>();

  const [selectedLabelSize, setSelectedLabelSize] = useState<string>(
    LabelSize.SeventyForty
  );

  const handleNextStep = () => {
    if (step === 1) {
      if (errorCountInListingLabels > 0) {
        setShowConfirmationDialogToProceedWithLabelsCreation(true);
      } else {
        setStep(2);
      }
    } else if (step === 2) {
      setStep(3); // Move to the final step (label size selection)
    } else if (step == 3) {
      setStep(4); // Move to the final step (label size selection)
    }
  };

  const handleBackStep = () => {
    setStep(step - 1); // Go back to the previous step
  };

  const handleCancel = () => {
    setStep(1);
    setGenerateProductLabels(false); // Close the modal
  };

  const labelSizes: RadioOption[] = [
    { label: "70 x 40 mm", value: LabelSize.SeventyForty },
    { label: "75 x 38 mm", value: LabelSize.SeventyFiveThirtyEight },
  ];

  const handleRadioChange = (value: string) => {
    setSelectedLabelSize(value);
    console.log(`Selected label size: ${value}`);
  };

  const [step, setStep] = useState(1); // Step state to control the modal step
  const [isDataValid, setIsDataValid] = useState(false); // Flag to check if the data is valid

  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true);
  const [
    showGenerateLabelModalLoadingPage,
    setShowGenerateLabelModalLoadingPage,
  ] = useState<boolean>(true);
  const [currentPurchaseOrder, setCurrentPurchaseOrder] =
    useState<IncomingPurchaseOrder>();
  const [
    currentPurchaseOrderListingDetails,
    setCurrentPurchaseOrderListingDetails,
  ] = useState<IncomingPurchaseOrderListingDetailsDataObject[]>([]);
  const [
    currentPurchaseOrderListingDetailsWithLabelsData,
    setCurrentPurchaseOrderListingDetailsWithLabelsData,
  ] = useState<IncomingPurchaseOrderListingDetailsDataObjectWithLabelsData[]>(
    []
  );

  const [errorsInListingLabels, setErrorsInListingLabels] = useState<
    LabelsMasterDataErrorItem[]
  >([]);

  const [errorCountInListingLabels, setErrorCountInListingLabels] =
    useState<number>(0);
  const [
    showConfirmationDialogToProceedWithLabelsCreation,
    setShowConfirmationDialogToProceedWithLabelsCreation,
  ] = useState<boolean>(false);

  const [
    currentPurchaseOrderAnalyticsObject,
    setCurrentPurchaseOrderAnalyticsObject,
  ] = useState<IncomingPurchaseOrderAnalyticsObject>();

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [acknowledgePurchaseOrder, setAcknowledgePurchaseOrder] =
    useState(false);
  const [cancelPurchaseOrder, setCancelPurchaseOrder] = useState(false);
  const [workInProgress, setWorkInProgress] = useState(false);
  const [downloadDetailedReport, setDownloadDetailedReport] = useState(false);
  const [downloadPendencyPDF, setDownloadPendencyPDF] = useState(false);
  const [generateProductLabels, setGenerateProductLabels] = useState(false);
  const [generatePhase1BoxLabels, setGeneratePhase1BoxLabels] = useState(false);
  const [chunkedPurchaseOrderListingItem, setChunkedPurchaseOrderListingItem] =
    useState<IncomingPurchaseOrderListingDetailsDataObject[][]>([]);

  const [isShowImagesChecked, setIsShowImageChecked] = useState<boolean>(false);
  const [
    isAddWarehouseInventoryColumnChecked,
    setIsAddWarehouseInventoryColumnChecked,
  ] = useState<boolean>(true);
  const [startingPhase1BoxNumber, setStartingPhase1BoxNumber] = useState(0);
  const [endingPhase1BoxNumber, setEndingPhase1BoxNumber] = useState(0);
  const [selectedMarketedBy, setSelectedMarketedBy] = useState<string>(
    MarketedBy.EunoiaCrafts
  );
  const [selectedManufacturedBy, setSelectedManufacturedBy] = useState<string>(
    ManufacturedBy.EunoiaCrafts
  );

  const [selectedManufacturedDate, setSelectedManufacturedDate] =
    useState<string>(DateTimeUtils.getCurrentMonthAndYear());
  const [selectedBrand, setSelectedBrand] = useState<string>("eCraftIndia");

  const [selectedCountryOfOrigin, setSelectedCountryOfOrigin] =
    useState<string>("India");

  const [showLabelGenerationModal, setShowLabelGenerationModal] =
    useState<boolean>(false);

  const [poDocuments, setPoDocuments] = useState<PoDocument[]>([]);
  const [showModifyPoTypeOption, setShowModifyPoTypeOption] =
    useState<boolean>(false);
  const [selectedPOType, setSelectedPOType] = useState<string | null>(null);
  const [providedSelectedPOType, setProvidedSelectedPOType] =
    useState<string>();
  const [finalDataForLabelsGeneration, setFinalDataForLabelsGeneration] =
    useState<LabelDataV2[]>([]);

  useEffect(() => {
    setChunkedPurchaseOrderListingItem(
      chunkArray(currentPurchaseOrderListingDetails, 30)
    );
  }, [currentPurchaseOrderListingDetails]);

  const chunkArray = (
    array: IncomingPurchaseOrderListingDetailsDataObject[],
    size: number
  ): IncomingPurchaseOrderListingDetailsDataObject[][] => {
    // const sortedArray = [...array].sort((a, b) => {
    //   const diffA = a.demand - 0; //a.pending;
    //   const diffB = b.demand - 0; //b.pending;
    //   return diffB - diffA; // descending order
    // });

    const sortedArray = array.filter(
      (item) =>
        item.demand - item.dispatched_quantity - item.reserved_quantity > 0
    );

    return Array.from(
      { length: Math.ceil(sortedArray.length / size) },
      (_, index) => sortedArray.slice(index * size, index * size + size)
    );
  };

  const sortByPendingQuantity = (
    array: IncomingPurchaseOrderListingDetailsDataObject[]
  ): IncomingPurchaseOrderListingDetailsDataObject[] => {
    const sortedArray = [...array].sort((a, b) => {
      const diffA = a.demand - a.reserved_quantity - a.dispatched_quantity; //a.pending;
      const diffB = b.demand - b.reserved_quantity - b.dispatched_quantity; //b.pending;
      return diffB - diffA; // descending order
    });

    return sortedArray;
  };

  const toggleRow = (index: number) => {
    if (expandedRow === index) {
      setExpandedRow(null); // collapse if already expanded
    } else {
      setExpandedRow(index); // expand selected row
    }
  };

  const handleMarketedByChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedMarketedBy(event.target.value);
  };

  const handleManufacturedByChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedManufacturedBy(event.target.value);
  };

  const handleManufactureDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedManufacturedDate(event.target.value);
  };

  const handleBrandChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedBrand(event.target.value);
  };

  const handleCountryOfOriginChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedCountryOfOrigin(event.target.value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsShowImageChecked(event.target.checked); // Update state based on the checkbox value
  };

  const handleShowWarehouseInventoryCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsAddWarehouseInventoryColumnChecked(event.target.checked); // Update state based on the checkbox value
  };

  useEffect(() => {
    if (currentPurchaseOrder) {
      setSelectedPOType(currentPurchaseOrder.po_type);
      setProvidedSelectedPOType(currentPurchaseOrder.po_type);
    }
  }, [currentPurchaseOrder]);

  useEffect(() => {
    const fetchPurchaseOrderDetails = async (purchase_order_id: any) => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.geturchaseOrderByPOid(
            purchase_order_id.toString()
          );
        // console.log(response.data);
        setCurrentPurchaseOrder(response.data);
      } catch (error) {
        toast.error("Error! Could not find purchase order details.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    };

    const fetchPurchaseOrderDetailFiles = async (purchase_order_id: any) => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.fetchPODetailFiles(
            purchase_order_id.toString()
          );

        setPoDocuments(response.data);
        // createDocumentMap(response.data);
      } catch (error) {
        console.error("Error in getting po_documents : ", error);
        const err = error as AxiosError;
        if (err.response && err.response.data && err.response.status == 400) {
          console.log((err.response.data as ErrorMessageBody).message);
          toast.error((err.response.data as ErrorMessageBody).message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        } else {
          toast.error("Unexpected error", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
      }
    };

    const fetchPurchaseOrderListingDetails = async (purchase_order_id: any) => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.getAllPurchaseOrdersListingPaginated(
            purchase_order_id.toString(),
            "",
            1,
            1000
          );

        setSearchParams((prev) => {
          return {
            ...prev,
            item_count: prev.item_count + response.data["curr_page_count"],
            expected_item_count: response.data["total"],
            current_page_num: response.data["current_page"],
          };
        });

        setCurrentPurchaseOrderListingDetails(
          sortByPendingQuantity(response.data["content"])
        );

        setShowLoadingPage(false);
      } catch (error) {
        toast.error("Error! Could not find purchase order details.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    };

    const fetchPurchaseOrderAnalysisObject = async (purchase_order_id: any) => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.getPurchaseOrderAnalyticsObject(
            purchase_order_id.toString()
          );

        setCurrentPurchaseOrderAnalyticsObject(response.data);
      } catch (error) {
        toast.error("Error! Could not find purchase order details.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    };

    fetchPurchaseOrderDetails(po_id);
    fetchPurchaseOrderDetailFiles(po_id);
    fetchPurchaseOrderListingDetails(po_id);
    fetchPurchaseOrderAnalysisObject(po_id);
  }, []);

  const getPurchaseOrderListingDetailsPaginated = async (
    purchase_order_id: any,
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.getAllPurchaseOrdersListingPaginated(
          purchase_order_id.toString(),
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          1000
        );

      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setCurrentPurchaseOrderListingDetails((prevList) =>
        sortByPendingQuantity([...prevList, ...response.data["content"]])
      );

      setShowLoadingPage(false);
    } catch (error) {
      toast.error("Error! Could not find purchase order details.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const loadMore = () => {
    console.log("FROM load more : ", searchParams.current_page_num);
    getPurchaseOrderListingDetailsPaginated(
      po_id,
      "",
      searchParams.current_page_num + 1,
      false
    );
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  const getCalculatedPoStatusColor = (calculatedStatus: string) => {
    if (calculatedStatus === "ACKNOWLEDGEMENT PENDING")
      return "incoming-po-text-orange";
    else if (calculatedStatus === "OPEN") return "incoming-po-text-green";
    else if (calculatedStatus === "MARKED CANCELLED")
      return "incoming-po-text-purple";
    else if (calculatedStatus === "EXPIRED") return "incoming-po-text-red";
    return "";
  };

  const getWIPcolor = (wip_status: string) => {
    if (wip_status == "YES") return "marked-green";
    else return "marked-red";
  };

  const markForAcknowledgement = async () => {
    setShowLoadingPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.acknowledgePurchaseOrder(
          po_id,
          userDetailsFromStore.user_details.userId
        );
      setCurrentPurchaseOrder(response.data);
      toast.success("PO Acknowledged successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setShowLoadingPage(false);
      setAcknowledgePurchaseOrder(false);
    } catch (error) {
      console.error(
        "Error in marking purchase order for acknowledgement : ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
      setAcknowledgePurchaseOrder(false);
    }
  };

  const markForWIP = async () => {
    setShowLoadingPage(true);
    try {
      const response = await companyPurchaseOrderNetworkUtil.markForWIP(
        po_id,
        userDetailsFromStore.user_details.userId
      );
      setCurrentPurchaseOrder(response.data);
      toast.success("PO Marked WIP successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setShowLoadingPage(false);
      setWorkInProgress(false);
    } catch (error) {
      console.error(
        "Error in marking purchase order for acknowledgement : ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
      setWorkInProgress(false);
    }
  };

  const prepareDataForLabelsGeneration = async () => {
    const dataForLabelsGeneration: LabelDataV2[] = [];
    currentPurchaseOrderListingDetailsWithLabelsData
      .filter((item) => item.labels_count > 0)
      .forEach((item) => {
        dataForLabelsGeneration.push({
          barcode: item.company_code_primary,
          marketedBy: selectedMarketedBy,
          manufacturedBy: selectedManufacturedBy,
          title: item.title,
          dateOfManufacture: selectedManufacturedDate,
          color: item.labels_master_data_item
            ? item.labels_master_data_item.color
            : "",
          brand: selectedBrand,
          material: item.labels_master_data_item
            ? item.labels_master_data_item.material
            : "",
          netQuantity: "1 Set",
          productDimension: item.labels_master_data_item
            ? item.labels_master_data_item.size
            : "",
          oneSetContains: item.labels_master_data_item
            ? item.labels_master_data_item.one_set_contains
            : "",
          modelNumber: item.po_secondary_sku,
          mrp: item.mrp + "",
          countryOfOrign: selectedCountryOfOrigin,
          styleId: item.company_code_secondary,
          labelCount: item.labels_count,
        });
      });

    // setFinalDataForLabelsGeneration(dataForLabelsGeneration);
    return dataForLabelsGeneration;
  };

  const handleLabelsGeneration2 = async () => {
    const labelHeadings: LabelData = {
      barcode: "NA",
      marketedBy: "Marketed By/For Consumer Complaints",
      manufacturedBy: "Manufactured By",
      netQuantity: "Net Quantity",
      oneSetContains: "1 Set Contains",
      title: "Title",
      dateOfManufacture: "Mfg. Date",
      color: "Color",
      brand: "Brand",
      material: "Material",
      productDimension: "Product Dimension",
      modelNumber: "Model Number",
      mrp: "M.R.P(INR)",
      countryOfOrign: "Country of Origin",
      styleId: "Style ID",
    };

    const labelsData = await prepareDataForLabelsGeneration();

    if (selectedLabelSize == LabelSize.SeventyFiveThirtyEight) {
      // CompanyPurchaseOrderLabelUtils.generateMyntraLabels75By38_v2(
      //   labelsData,
      //   labelHeadings,
      //   5
      // );
    } else {
      if (selectedManufacturedBy == selectedMarketedBy) {
        labelHeadings.marketedBy = "Marketed/Manufactured/Consumer Complaints";
        if (
          currentPurchaseOrder &&
          currentPurchaseOrder.company.company_name == "Myntra"
        ) {
          CompanyPurchaseOrderLabelUtils.generateGeneralLabels70By40_v4_combined_address_Myntra(
            labelsData,
            labelHeadings
          );
        }
      } else {
      }
    }
  };

  // generateMyntraLabels75By38_v2(labelData, labelHeadings, 5);

  const handlePhase1BoxLabelsGeneration = () => {
    if (startingPhase1BoxNumber <= 0 || endingPhase1BoxNumber <= 0) {
      toast.error("Error! Page Numbers must be greater than 0.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return;
    }

    if (startingPhase1BoxNumber > endingPhase1BoxNumber) {
      toast.error(
        "Error! Ending Box Number must be greater than startingBox Number.",
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        }
      );
      return;
    }

    console.log(selectedLabelSize);

    if (currentPurchaseOrder) {
      if (selectedLabelSize == LabelSize.SeventyFiveThirtyEight) {
        CompanyPurchaseOrderLabelUtils.createPhase1BoxLabelsFor75by38(
          startingPhase1BoxNumber,
          endingPhase1BoxNumber,
          po_id,
          currentPurchaseOrder.delivery_city,
          currentPurchaseOrder.company.company_name
        );
      } else if (selectedLabelSize == LabelSize.SeventyForty) {
        CompanyPurchaseOrderLabelUtils.createPhase1BoxLabelsFor70by40(
          startingPhase1BoxNumber,
          endingPhase1BoxNumber,
          po_id,
          currentPurchaseOrder.delivery_city,
          currentPurchaseOrder.company.company_name
        );
      }
    }
  };

  const markPurchaseOrderForCancellation = async () => {
    if (currentPurchaseOrder) {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.markPurchaseOrderForCancellation(
            currentPurchaseOrder.po_number
          );

        setPoDocuments(response.data);
        // createDocumentMap(response.data);
      } catch (error) {
        console.error("Error in cancelling purchase order : ", error);
        const err = error as AxiosError;
        if (err.response && err.response.data && err.response.status == 400) {
          console.log((err.response.data as ErrorMessageBody).message);
          toast.error((err.response.data as ErrorMessageBody).message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        } else {
          toast.error("Unexpected error", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
      }
    }
  };

  const submitPoTypeForModification = async () => {
    if (currentPurchaseOrder) {
      if (selectedPOType == currentPurchaseOrder.po_type) {
        toast.error("PO Type was not changed.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        return;
      }
      setShowModifyPoTypeOption(false);
      setShowLoadingPage(true);
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.submitPoTypeForModification(
            currentPurchaseOrder.po_number,
            selectedPOType,
            userDetailsFromStore.user_details.userId
          );

        setCurrentPurchaseOrder(response.data);
        setShowLoadingPage(false);
        // createDocumentMap(response.data);
      } catch (error) {
        setShowLoadingPage(false);
        console.error("Error in cancelling purchase order : ", error);
        const err = error as AxiosError;
        if (err.response && err.response.data && err.response.status == 400) {
          console.log((err.response.data as ErrorMessageBody).message);
          toast.error((err.response.data as ErrorMessageBody).message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        } else if (
          err.response &&
          err.response.data &&
          err.response.status == 500
        ) {
          console.log((err.response.data as ErrorMessageBody).message);
          toast.error((err.response.data as ErrorMessageBody).message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        } else {
          toast.error("Unexpected error", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
      }
    }
  };

  const downloadDetailedReportAsSpreadsheet = async () => {
    try {
      // itemsToExport.push()
      if (currentPurchaseOrder) {
        const transformedItems = currentPurchaseOrderListingDetails.map(
          (singleItem) => {
            return {
              buyer_name: currentPurchaseOrder.company.company_name,
              po_number: singleItem.po_number,
              po_issue_date: DateTimeUtils.formatDateOnly(
                currentPurchaseOrder.po_issue_date
              ),
              po_expiry_date: DateTimeUtils.formatDateOnly(
                currentPurchaseOrder.expiry_date
              ),
              po_addition_date: DateTimeUtils.formatDateOnly(
                currentPurchaseOrder.created_at
              ),
              po_type: currentPurchaseOrder.po_type,
              delivery_location: currentPurchaseOrder.delivery_city,
              po_secondary_sku: singleItem.po_secondary_sku,
              master_sku: singleItem.master_sku,
              inventory_sku_id: singleItem.inventory_sku_id,
              pack_combo_sku_id: singleItem.pack_combo_sku_id,
              sku_type: singleItem.sku_type,
              company_code_primary: singleItem.company_code_primary,
              company_code_secondary: singleItem.company_code_secondary,
              title: singleItem.title,
              mrp: singleItem.mrp,
              rate_without_tax: singleItem.rate_without_tax,
              tax_rate: singleItem.tax_rate,
              hsn: singleItem.hsn_code,
              size: singleItem.size,
              color: singleItem.color,
              ops_tag: singleItem.listing ? singleItem.listing.ops_tag : "",
              warehouse_quantity:
                singleItem.sku_type === "MULTI"
                  ? getAvailableQuantityForMulti(singleItem.multi_listings)
                  : singleItem.listing
                  ? singleItem.listing.available_quantity
                  : 0,
              demand: singleItem.demand,
              packed: singleItem.reserved_quantity,
              dispatched: singleItem.dispatched_quantity,
              pending:
                singleItem.demand -
                singleItem.reserved_quantity -
                singleItem.dispatched_quantity,
              fill_rate_percent:
                Math.round(
                  ((singleItem.reserved_quantity +
                    singleItem.dispatched_quantity) /
                    singleItem.demand) *
                    100 *
                    100
                ) / 100,
            };
          }
        );

        jsonexport(transformedItems, (err: Error | null, csv: string) => {
          if (err) {
            console.error(err);
            return;
          }
          // Create a Blob object with the CSV data
          const blob = new Blob([csv], { type: "text/csv" });
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = currentPurchaseOrder
            ? currentPurchaseOrder.po_number + "_detailed_report.csv"
            : "po_detailed_report.csv";
          // Simulate a click to trigger the download
          link.click();
        });
      }

      setDownloadDetailedReport(false);
    } catch (error) {
      console.log(
        "Printing error from CompanyPurchaseOrderDetails -> Download detailed report.   "
      );
      console.error("Error:", error);

      setDownloadDetailedReport(false);
    }
  };

  const downloadFileFromWeb = async (file_id: any, saved_file_name: string) => {
    setShowDownloadPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.downloadPoFileFromWeb(file_id);

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = saved_file_name; // Use the original file name for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setShowDownloadPage(false);
    } catch (error) {
      console.error("Error in downloading file : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowDownloadPage(false);
    }
  };

  const getClassNameForFillRate = (fill_rate: number): string => {
    if (fill_rate < 25) return "fill_rate_purple";
    else if (fill_rate < 50) return "fill_rate_red";
    else if (fill_rate < 90) return "fill_rate_orange";
    else return "fill_rate_green";
  };

  const getAvailableQuantityForMulti = (
    packsAndCombos: PacksAndCombos[]
  ): number => {
    // console.log(listings);
    let available_quantity = 10000000;
    packsAndCombos.forEach((packsAndCombo, index) => {
      available_quantity = Math.min(
        available_quantity,
        packsAndCombo.listing.available_quantity
      );
    });

    return available_quantity == 10000000 ? 0 : available_quantity;
  };

  const handlePOTypeFromDropdownSelected = (value: string) => {
    setSelectedPOType(value);
  };

  const fetchLabelsDataForCurrentPurchaseOrder = async () => {
    setShowGenerateLabelModalLoadingPage(true);
    if (currentPurchaseOrder) {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.fetchLabelsDataForPurchaseOrder(
            currentPurchaseOrder.po_number
          );

        createLabelsDataActionList(response.data);
        setShowGenerateLabelModalLoadingPage(false);
      } catch (error) {
        toast.error(
          "Error! Could not find labels data for current Purchase Order.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );
        setShowGenerateLabelModalLoadingPage(false);
      }
    }
  };

  const createLabelsDataActionList = (
    labelsMasterData: LabelsMasterDataItem[]
  ) => {
    // Create a hashmap for quick lookup of labelsData
    const labelsMap = new Map(
      labelsMasterData.map((label) => [label.secondary_sku, label])
    );

    // Merge data using the hashmap
    const listingsWithLabelsData: IncomingPurchaseOrderListingDetailsDataObjectWithLabelsData[] =
      currentPurchaseOrderListingDetails.map((listing) => ({
        ...listing,
        labels_master_data_item:
          labelsMap.get(listing.po_secondary_sku) || null,
        labels_count: 0,
      }));

    const errorsInListingLabelsData: LabelsMasterDataErrorItem[] = [];
    let errorCount = 0;
    listingsWithLabelsData.forEach((item, index) => {
      if (item.labels_master_data_item) {
        let obj: LabelsMasterDataErrorItem = {
          secondary_sku: "",
          ean_code: "",
          size: "",
          color: "",
          one_set_contains: "",
          mrp: "",
          material: "",
        };

        if (
          item.labels_master_data_item.ean_code == "" ||
          item.labels_master_data_item.ean_code == null
        ) {
          obj.ean_code = "Invalid Value. Update in Master";
        }

        if (
          item.labels_master_data_item.size == "NA" ||
          item.labels_master_data_item.size == "" ||
          item.labels_master_data_item.size == null
        ) {
          obj.size = "Invalid Value. Update in Master.";
          errorCount++;
        }

        if (
          item.labels_master_data_item.color == "NA" ||
          item.labels_master_data_item.color == "" ||
          item.labels_master_data_item.color == null
        ) {
          obj.color = "Invalid Value. Update in Master.";
          errorCount++;
        }

        if (
          item.labels_master_data_item.one_set_contains == "NA" ||
          item.labels_master_data_item.one_set_contains == "" ||
          item.labels_master_data_item.one_set_contains == null
        ) {
          obj.one_set_contains = "Invalid Value. Update in Master.";
          errorCount++;
        }

        if (
          item.labels_master_data_item.mrp == 0 ||
          item.labels_master_data_item.mrp == null
        ) {
          obj.mrp = "Invalid Value. Update in Master.";
          errorCount++;
        } else if (item.labels_master_data_item.mrp != item.mrp) {
          obj.mrp = "Current MRP does not match the PO's MRP.";
          errorCount++;
        }

        if (
          item.labels_master_data_item.material == "NA" ||
          item.labels_master_data_item.material == null
        ) {
          obj.material = "Invalid Value. Update in Master.";
          errorCount++;
        }

        errorsInListingLabelsData.push(obj);
      } else {
        errorsInListingLabelsData.push({
          secondary_sku: "",
          ean_code: "Cannot find Secondary SKU in Labels Master Data.",
          size: "Cannot find Secondary SKU in Labels Master Data.",
          color: "Cannot find Secondary SKU in Labels Master Data.",
          one_set_contains: "Cannot find Secondary SKU in Labels Master Data.",
          mrp: "Cannot find Secondary SKU in Labels Master Data.",
          material: "Cannot find Secondary SKU in Labels Master Data.",
        });
        errorCount += 6;
      }
    });

    setErrorCountInListingLabels(errorCount);
    setErrorsInListingLabels(errorsInListingLabelsData);
    setCurrentPurchaseOrderListingDetailsWithLabelsData(listingsWithLabelsData);
  };

  const downloadCurrentViewForLabelsData = () => {
    try {
      // itemsToExport.push()
      if (
        currentPurchaseOrderListingDetailsWithLabelsData &&
        currentPurchaseOrder
      ) {
        const transformedItems =
          currentPurchaseOrderListingDetailsWithLabelsData.map((singleItem) => {
            return {
              po_number: singleItem.po_number,
              po_issue_date: DateTimeUtils.formatDateOnly(
                currentPurchaseOrder.po_issue_date
              ),
              po_expiry_date: DateTimeUtils.formatDateOnly(
                currentPurchaseOrder.expiry_date
              ),
              po_type: currentPurchaseOrder.po_type,
              delivery_location: currentPurchaseOrder.delivery_city,
              po_secondary_sku: singleItem.po_secondary_sku,
              // master_sku: singleItem.master_sku,
              // inventory_sku_id: singleItem.inventory_sku_id,
              // pack_combo_sku_id: singleItem.pack_combo_sku_id,
              // sku_type: singleItem.sku_type,
              company_code_primary: singleItem.company_code_primary,
              company_code_secondary: singleItem.company_code_secondary,
              ean_code:
                singleItem.labels_master_data_item &&
                singleItem.labels_master_data_item.ean_code,
              size:
                singleItem.labels_master_data_item &&
                singleItem.labels_master_data_item.size,
              color:
                singleItem.labels_master_data_item &&
                singleItem.labels_master_data_item.color,
              mrp:
                singleItem.labels_master_data_item &&
                singleItem.labels_master_data_item.mrp,
              title: singleItem.title,
              one_set_contains:
                singleItem.labels_master_data_item &&
                singleItem.labels_master_data_item.one_set_contains,
              warehouse_quantity:
                singleItem.sku_type === "MULTI"
                  ? getAvailableQuantityForMulti(singleItem.multi_listings)
                  : singleItem.listing
                  ? singleItem.listing.available_quantity
                  : 0,
              demand: singleItem.demand,
              packed: singleItem.reserved_quantity,
              dispatched: singleItem.dispatched_quantity,
              pending:
                singleItem.demand -
                singleItem.reserved_quantity -
                singleItem.dispatched_quantity,
            };
          });

        jsonexport(transformedItems, (err: Error | null, csv: string) => {
          if (err) {
            console.error(err);
            return;
          }
          // Create a Blob object with the CSV data
          const blob = new Blob([csv], { type: "text/csv" });
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = currentPurchaseOrder
            ? currentPurchaseOrder.po_number + "_labels_data.csv"
            : "labels_data.csv";
          // Simulate a click to trigger the download
          link.click();
        });
      }

      setDownloadDetailedReport(false);
    } catch (error) {
      console.log(
        "Printing error from CompanyPurchaseOrderDetails -> Download detailed report.   "
      );
      console.error("Error:", error);

      setDownloadDetailedReport(false);
    }
  };

  const handleLabelsCountChange = (index: number, value: string) => {
    let numericValue = value.replace(/\D/g, "");

    // Convert to integer and prevent leading zeros
    const cnt = numericValue ? parseInt(numericValue, 10) : 0;

    const updatedList = [...currentPurchaseOrderListingDetailsWithLabelsData];
    updatedList[index] = { ...updatedList[index], labels_count: cnt };
    setCurrentPurchaseOrderListingDetailsWithLabelsData(updatedList);
  };

  return (
    <div className="upload-purchase-order-details-container">
      {currentPurchaseOrder && (
        <div className="vendor-action-buttons-container incoming-purchase-order-details-table-container">
          <div className="po-document-section-heading">
            Purchase Order Files
          </div>
          {poDocuments.length === 0 ? (
            <div className="po-document-section-no-document mt-3">
              No document was found
            </div>
          ) : (
            <div className="incoming-purchase-order-file-details-table-container mt-3">
              <Table bordered hover size="sm" className="table-auto-width">
                <thead>
                  <tr>
                    <th>File ID</th>
                    <th>Uploaded At</th>
                    <th>Uploaded By</th>
                    <th>File Name</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {poDocuments.map((doc, index) => {
                    return (
                      <tr className="catalogues-search-bar-row">
                        <td className="nowrap">{doc.id}</td>
                        <td>{DateTimeUtils.formatDateTime(doc.created_at)}</td>
                        <td>{doc.file_uploaded_by}</td>
                        <td>{doc.file_name}</td>
                        <td>
                          <Download
                            size={15}
                            color="#007bff"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              downloadFileFromWeb(doc.id, doc.saved_file_name)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      )}

      {currentPurchaseOrder && (
        <div className="incoming-purchase-order-details-table-container">
          <Table bordered hover size="sm" className="table-auto-width">
            <tbody>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Status</td>
                <td
                  className={getCalculatedPoStatusColor(
                    currentPurchaseOrder.calculated_po_status
                  )}
                >
                  {currentPurchaseOrder.calculated_po_status}
                </td>
              </tr>

              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Marked WIP ?</td>
                <td className={getWIPcolor(currentPurchaseOrder.is_wip)}>
                  {currentPurchaseOrder.is_wip}
                </td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Buyer Company Name</td>
                <td>{currentPurchaseOrder.company.company_name}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Number</td>
                <td>{currentPurchaseOrder.po_number}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Type</td>
                <td>
                  <div className="data_and_edit_button_container">
                    <div className="data_part">
                      {currentPurchaseOrder.po_type}
                    </div>
                    <div className="button_part">
                      <PencilSquare
                        size={14}
                        color="#007bff"
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowModifyPoTypeOption(true)}
                      ></PencilSquare>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Sold Via</td>
                <td>{currentPurchaseOrder.sold_via}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Reference Location</td>
                <td>{currentPurchaseOrder.delivery_city}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Delivery Address</td>
                <td>{currentPurchaseOrder.delivery_address}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Billing Address</td>
                <td>{currentPurchaseOrder.billing_address}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Buyer GSTIN</td>
                <td>{currentPurchaseOrder.buyer_gstin}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Issue Date</td>
                <td>
                  {DateTimeUtils.formatDateOnly(
                    currentPurchaseOrder.po_issue_date
                  )}
                </td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Expiry Date</td>
                <td>
                  {DateTimeUtils.formatDateOnly(
                    currentPurchaseOrder.expiry_date
                  )}
                </td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Created by</td>
                <td>{currentPurchaseOrder.created_by}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Created At</td>
                <td>
                  {DateTimeUtils.formatDateTime(
                    currentPurchaseOrder.created_at
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}

      <div className="incoming-purchase-order-pack-task-summary-table-container">
        {currentPurchaseOrderAnalyticsObject && (
          <div className="incoming-purchase-order-pack-task-summary-table-container-child-66">
            <Table bordered hover size="sm" className="table-auto-width">
              <tbody>
                <tr className="catalogues-search-bar-row">
                  <td colSpan={4} className="centered-heading">
                    Current Purchase Order Summary
                  </td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Number of SKUs</td>
                  <td className="centered">
                    {currentPurchaseOrderAnalyticsObject.sku_count}
                  </td>
                  <td className="nowrap">SKU Fill Rate %</td>
                  <td className="centered">
                    {currentPurchaseOrderAnalyticsObject.sku_fill_rate}%
                  </td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Total Demand Quantity</td>
                  <td className="centered">
                    {currentPurchaseOrderAnalyticsObject.total_demand}
                  </td>
                  <td className="nowrap">Quantity Fill Rate %</td>
                  <td className="centered">
                    {currentPurchaseOrderAnalyticsObject.quantity_fill_rate}%
                  </td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Total Pending Quantity</td>
                  <td className="centered">
                    {currentPurchaseOrderAnalyticsObject.total_pending}
                  </td>
                  <td className="nowrap">Total Consignments</td>
                  <td className="centered">
                    {currentPurchaseOrderAnalyticsObject.total_consignments}
                  </td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Total Packed Quantity</td>
                  <td className="centered">
                    {currentPurchaseOrderAnalyticsObject.total_packed}
                  </td>
                  <td className="nowrap">Total Dispatched Quantity</td>
                  <td className="centered">
                    {currentPurchaseOrderAnalyticsObject.total_dispatched}
                  </td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Boxed Packed</td>
                  <td className="centered">
                    {currentPurchaseOrderAnalyticsObject.boxes_packed}
                  </td>
                  <td className="nowrap ">Boxes Dispatched</td>
                  <td className="centered ">
                    {currentPurchaseOrderAnalyticsObject.boxes_dispatched}
                  </td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Total Before Tax</td>
                  <td className="centered">
                    Rs. {currentPurchaseOrderAnalyticsObject.total_before_tax}
                  </td>
                  <td className="nowrap ">Total After Tax</td>
                  <td className="centered ">
                    Rs. {currentPurchaseOrderAnalyticsObject.total_after_tax}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        )}

        {currentPurchaseOrder && (
          <div className="purchase-order-action-buttons-container incoming-purchase-order-pack-task-summary-table-container-child">
            {currentPurchaseOrder.calculated_po_status ===
            "ACKNOWLEDGEMENT PENDING" ? (
              <Button
                variant="outline-primary"
                className="vendor-action-button"
                onClick={() => setAcknowledgePurchaseOrder(true)}
              >
                Acknowledge Purchase Order
              </Button>
            ) : (
              <div>
                {currentPurchaseOrder.calculated_po_status !==
                  "MARKED CANCELLED" && (
                  <div>
                    <Button
                      variant="outline-primary"
                      className="vendor-action-button"
                      onClick={() => setCancelPurchaseOrder(true)}
                    >
                      Cancel Purchase Order
                    </Button>

                    {currentPurchaseOrder.is_wip === "NO" && (
                      <Button
                        variant="outline-primary"
                        className="vendor-action-button"
                        onClick={() => setWorkInProgress(true)}
                      >
                        Add this purchase order to WIP
                      </Button>
                    )}
                  </div>
                )}
              </div>
            )}

            <Button
              variant="outline-primary"
              className="vendor-action-button"
              onClick={() => setDownloadDetailedReport(true)}
            >
              Download SKU Level Report
            </Button>
            <Button
              variant="outline-primary"
              className="vendor-action-button"
              onClick={() => setDownloadPendencyPDF(true)}
            >
              Download Pendency PDF
            </Button>
            <Button
              variant="outline-primary"
              className="vendor-action-button"
              onClick={() => setGenerateProductLabels(true)}
            >
              Generate Product Labels
            </Button>
            <Button
              variant="outline-primary"
              className="vendor-action-button"
              onClick={() => setGeneratePhase1BoxLabels(true)}
            >
              Generate Phase 1 Box Labels
            </Button>
          </div>
        )}
      </div>

      {showLoadingPage ? (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      ) : (
        currentPurchaseOrder && (
          <div className="po-items-container-details">
            <div className="mb-2">
              <label>
                <input
                  type="checkbox"
                  checked={isShowImagesChecked}
                  onChange={handleCheckboxChange}
                />
                Show Images ?
              </label>
            </div>
            <Table bordered hover size="sm">
              <thead className="sticky-header">
                <tr>
                  <th>SR No.</th>
                  {isShowImagesChecked && <th>Image</th>}
                  <th>PO Secondary SKU</th>
                  <th>Master SKU</th>
                  <th>Inventory SKU</th>
                  <th>Pack-Combo SKU</th>
                  <th>SKU Type</th>
                  <th>Company Code Primary</th>
                  <th>Warehouse Quantity</th>
                  <th>Demand Quantity</th>
                  <th>Packed Quantity</th>
                  <th>Dispatched Quantity</th>
                  <th>Pending Quantity</th>
                  <th>Fill Rate %</th>
                </tr>
              </thead>
              <tbody>
                {currentPurchaseOrderListingDetails.map(
                  (purchaseOrderItem, index) => {
                    const isExpanded = expandedRow === index;
                    return (
                      <React.Fragment key={index}>
                        <tr
                          className={
                            isExpanded
                              ? "incoming-po-search-bar-row expanded-main-row"
                              : "incoming-po-search-bar-row"
                          }
                          onClick={() => toggleRow(index)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>{index + 1}</td>
                          {isShowImagesChecked && (
                            <td>
                              {purchaseOrderItem.listing && (
                                <img
                                  style={{ height: "70px", width: "70px" }}
                                  src={purchaseOrderItem.listing.img_wdim}
                                  alt="Product Image"
                                />
                              )}
                            </td>
                          )}
                          <td>{purchaseOrderItem.po_secondary_sku}</td>
                          <td>{purchaseOrderItem.master_sku}</td>
                          <td>{purchaseOrderItem.inventory_sku_id}</td>
                          <td>{purchaseOrderItem.pack_combo_sku_id}</td>
                          <td>{purchaseOrderItem.sku_type}</td>
                          <td>{purchaseOrderItem.company_code_primary}</td>
                          <td>
                            {purchaseOrderItem.sku_type == "MULTI"
                              ? getAvailableQuantityForMulti(
                                  purchaseOrderItem.multi_listings
                                )
                              : purchaseOrderItem.listing
                              ? purchaseOrderItem.listing.available_quantity
                              : 0}
                          </td>
                          <td>{purchaseOrderItem.demand}</td>
                          <td>{purchaseOrderItem.reserved_quantity}</td>
                          <td>{purchaseOrderItem.dispatched_quantity}</td>
                          <td>
                            {purchaseOrderItem.demand -
                              purchaseOrderItem.reserved_quantity -
                              purchaseOrderItem.dispatched_quantity}
                          </td>
                          <td
                            className={`${getClassNameForFillRate(
                              Math.round(
                                ((purchaseOrderItem.reserved_quantity +
                                  purchaseOrderItem.dispatched_quantity) /
                                  purchaseOrderItem.demand) *
                                  100
                              )
                            )}`}
                          >
                            {Math.round(
                              ((purchaseOrderItem.reserved_quantity +
                                purchaseOrderItem.dispatched_quantity) /
                                purchaseOrderItem.demand) *
                                100 *
                                100
                            ) / 100}
                            %
                          </td>
                        </tr>
                        {isExpanded && (
                          <tr
                            className={`incoming-po-search-bar-row expandable-content-po ${
                              isExpanded ? "expanded" : "collapsed"
                            }`}
                          >
                            <td></td>
                            <td colSpan={13} className="po-expanded-cell">
                              <div className="expanded-content">
                                <div className="expanded-item">
                                  <strong>Title:</strong>{" "}
                                  {purchaseOrderItem.title}
                                </div>
                                <div className="expanded-item">
                                  <strong>Company Code Secondary:</strong>{" "}
                                  {purchaseOrderItem.company_code_secondary}
                                </div>
                                <div className="expanded-item">
                                  <strong>HSN:</strong>{" "}
                                  {purchaseOrderItem.hsn_code}
                                </div>
                                <div className="expanded-item">
                                  <strong>MRP:</strong> {purchaseOrderItem.mrp}
                                </div>
                                <div className="expanded-item">
                                  <strong>Rate exclusive of Taxes :</strong>{" "}
                                  {purchaseOrderItem.rate_without_tax}
                                </div>
                                <div className="expanded-item">
                                  <strong>Tax Rate :</strong>{" "}
                                  {purchaseOrderItem.tax_rate}
                                </div>
                                {/* <div className="expanded-item">
                                  <strong>Size:</strong>{" "}
                                  {purchaseOrderItem.size}
                                </div>
                                <div className="expanded-item">
                                  <strong>Color:</strong>{" "}
                                  {purchaseOrderItem.color}
                                </div> */}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  }
                )}
              </tbody>
            </Table>
            {searchParams.item_count > 0 &&
              searchParams.item_count < searchParams.expected_item_count && (
                <div className="load-more-catalogues-button-container">
                  <Button
                    variant="outline-primary"
                    className="load-more-catalogues-button"
                    onClick={() => loadMore()}
                  >
                    Load More..
                  </Button>
                </div>
              )}
          </div>
        )
      )}

      <Modal
        show={acknowledgePurchaseOrder}
        onHide={() => setAcknowledgePurchaseOrder(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Acknowledge Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to acknowledge this Purchase Order ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setAcknowledgePurchaseOrder(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => markForAcknowledgement()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cancelPurchaseOrder}
        onHide={() => setCancelPurchaseOrder(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this Purchase Order ? Please note that
          if there is an open/dispatched consignment or the PO is in WIP state,
          it cannot be cancelled.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setCancelPurchaseOrder(false)}
          >
            Cancel
          </Button>
          <Button onClick={markPurchaseOrderForCancellation} variant="primary">
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={workInProgress}
        onHide={() => setWorkInProgress(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark this PO as Work in Progress(WIP)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to mark this Purchase Order as Work In Progress
          ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setWorkInProgress(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => markForWIP()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={downloadDetailedReport}
        onHide={() => setDownloadDetailedReport(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Download Detailed Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This report contains the spreadsheet view of the table provided on
          this page.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setDownloadDetailedReport(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => downloadDetailedReportAsSpreadsheet()}
          >
            Download
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={downloadPendencyPDF}
        onHide={() => setDownloadPendencyPDF(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Download Pendency PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This PDF holds the Pending Quantity of each sku that is yet to be
          dispatched.
          <div className="mt-2 mb-2">
            <label>
              <input
                type="checkbox"
                checked={isAddWarehouseInventoryColumnChecked}
                onChange={handleShowWarehouseInventoryCheckboxChange}
              />
              Add Warehouse Inventory Column
            </label>
          </div>
          {currentPurchaseOrder && (
            <PDFDownloadLink
              fileName={`${currentPurchaseOrder.po_number}_pendency.pdf`}
              document={
                <CompanyPurchaseOrderPendingDispatchPDF
                  showWarehouseInventoryColumn={
                    isAddWarehouseInventoryColumnChecked
                  }
                  currentPurchaseOrder={currentPurchaseOrder}
                  chunkedPurchaseOrderListingItem={
                    chunkedPurchaseOrderListingItem
                  }
                  createdBy={userDetailsFromStore.user_details.userId}
                  totalSkus={currentPurchaseOrderListingDetails.length}
                  totalDemand={currentPurchaseOrderListingDetails.reduce(
                    (total, cp) => total + cp.demand,
                    0
                  )}
                  pendingDemand={currentPurchaseOrderListingDetails
                    .filter(
                      (item) =>
                        item.demand -
                          item.reserved_quantity -
                          item.dispatched_quantity >
                        0
                    )
                    .reduce(
                      (total, item) =>
                        total +
                        (item.demand -
                          item.reserved_quantity -
                          item.dispatched_quantity),
                      0
                    )}
                  pendingSkus={
                    currentPurchaseOrderListingDetails.filter(
                      (item) =>
                        item.demand -
                          item.reserved_quantity -
                          item.dispatched_quantity >
                        0
                    ).length
                  }
                  chunkSize={30}
                />
              }
            >
              {({ blob, url, loading, error }) => (
                <Button className="vendor-action-button" disabled={loading}>
                  {loading ? "Loading document..." : "Download PDF"}
                </Button>
              )}
            </PDFDownloadLink>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={generateProductLabels}
        onHide={() => setGenerateProductLabels(false)}
        onShow={() => {
          fetchLabelsDataForCurrentPurchaseOrder();
        }}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Generate Product Labels</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          {/* Step 1: Data Validation */}
          {step === 1 && (
            <Fade in={step === 1}>
              <div className="labels-data-for-generation-container-main">
                <p>
                  Step 1: Data required to generate labels will be fetched here
                  by the system. Verify the data to proceed.
                </p>
                {errorCountInListingLabels > 0 ? (
                  <div className="error-count-container pb-2">
                    {errorCountInListingLabels} error(s) were found
                  </div>
                ) : (
                  <div className="success-container pb-2">
                    No error was found
                  </div>
                )}
                <div className="labels-data-for-generation-container">
                  <div className="all-labels-master-data-table">
                    <Table
                      bordered
                      hover
                      size="sm"
                      className="table-auto-width data-table"
                    >
                      <thead>
                        <tr>
                          <th>PO Secondary SKU</th>
                          <th>Company Code Primary</th>
                          <th>Company Code Secondary</th>
                          <th>EAN Code</th>
                          <th>Size</th>
                          <th>Color</th>
                          <th>One Set Contains</th>
                          <th>Material</th>
                          <th>MRP at the moment</th>
                          <th>MRP at the time of PO creation</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPurchaseOrderListingDetailsWithLabelsData.map(
                          (singleItem, index) => {
                            const errorMessages = errorsInListingLabels[index];

                            const getClassForError = (errorMessage: string) =>
                              errorMessage.length > 0 ? "error-cell" : "";

                            const getTooltipForError = (errorMessage: string) =>
                              errorMessage.length > 0 ? (
                                <span className="tooltip">{errorMessage}</span>
                              ) : null;

                            return (
                              <tr className="catalogues-search-bar-row">
                                <td>{singleItem.po_secondary_sku}</td>
                                <td>{singleItem.company_code_primary}</td>
                                <td>{singleItem.company_code_secondary}</td>
                                {singleItem.labels_master_data_item ? (
                                  <td
                                    className={getClassForError(
                                      errorMessages.ean_code
                                    )}
                                  >
                                    {getTooltipForError(errorMessages.ean_code)}
                                    {
                                      singleItem.labels_master_data_item
                                        .ean_code
                                    }
                                  </td>
                                ) : (
                                  <td
                                    className={getClassForError(
                                      errorMessages.ean_code
                                    )}
                                  >
                                    {getTooltipForError(errorMessages.ean_code)}
                                    null
                                  </td>
                                )}
                                {singleItem.labels_master_data_item ? (
                                  <td
                                    className={getClassForError(
                                      errorMessages.size
                                    )}
                                  >
                                    {getTooltipForError(errorMessages.size)}
                                    {singleItem.labels_master_data_item.size}
                                  </td>
                                ) : (
                                  <td
                                    className={getClassForError(
                                      errorMessages.size
                                    )}
                                  >
                                    {getTooltipForError(errorMessages.size)}
                                    null
                                  </td>
                                )}
                                {singleItem.labels_master_data_item ? (
                                  <td
                                    className={getClassForError(
                                      errorMessages.color
                                    )}
                                  >
                                    {getTooltipForError(errorMessages.color)}
                                    {singleItem.labels_master_data_item.color}
                                  </td>
                                ) : (
                                  <td
                                    className={getClassForError(
                                      errorMessages.color
                                    )}
                                  >
                                    {getTooltipForError(errorMessages.color)}
                                    null
                                  </td>
                                )}
                                {singleItem.labels_master_data_item ? (
                                  <td
                                    className={getClassForError(
                                      errorMessages.one_set_contains
                                    )}
                                  >
                                    {getTooltipForError(
                                      errorMessages.one_set_contains
                                    )}
                                    {
                                      singleItem.labels_master_data_item
                                        .one_set_contains
                                    }
                                  </td>
                                ) : (
                                  <td
                                    className={getClassForError(
                                      errorMessages.one_set_contains
                                    )}
                                  >
                                    {getTooltipForError(
                                      errorMessages.one_set_contains
                                    )}
                                    null
                                  </td>
                                )}
                                {singleItem.labels_master_data_item ? (
                                  <td
                                    className={getClassForError(
                                      errorMessages.material
                                    )}
                                  >
                                    {getTooltipForError(errorMessages.material)}
                                    {
                                      singleItem.labels_master_data_item
                                        .material
                                    }
                                  </td>
                                ) : (
                                  <td
                                    className={getClassForError(
                                      errorMessages.material
                                    )}
                                  >
                                    {getTooltipForError(errorMessages.material)}
                                    null
                                  </td>
                                )}
                                {singleItem.labels_master_data_item ? (
                                  <td
                                    className={getClassForError(
                                      errorMessages.mrp
                                    )}
                                  >
                                    {getTooltipForError(errorMessages.mrp)}
                                    {singleItem.labels_master_data_item.mrp}
                                  </td>
                                ) : (
                                  <td
                                    className={getClassForError(
                                      errorMessages.mrp
                                    )}
                                  >
                                    {getTooltipForError(errorMessages.mrp)}
                                    null
                                  </td>
                                )}
                                <td>{singleItem.mrp}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Fade>
          )}

          {/* Step 2: Enter the Number of Labels */}
          {step === 2 && (
            <Fade in={step === 2}>
              <div>
                <p>Step 2: Modify fixed label settings.</p>
                <div className="fixed-label-details-form-container">
                  <div className="fixed-label-picker-container-main mt-3">
                    <div className="fixed-label-picker-selected-date-container ml-1 mb-1">
                      <span>Select Marketed By: </span> &nbsp;
                    </div>
                    <div className="maunfactured-marketed-by-container">
                      <select
                        value={selectedMarketedBy}
                        name="payment_mode"
                        className="fixed-label-picker-select"
                        onChange={handleMarketedByChange}
                      >
                        <option value={MarketedBy.EunoiaCrafts}>
                          {MarketedBy.EunoiaCrafts}
                        </option>
                        <option value={MarketedBy.Intellozene}>
                          {MarketedBy.Intellozene}
                        </option>
                        <option value={MarketedBy.Flipkart}>
                          {MarketedBy.Flipkart}
                        </option>
                      </select>
                    </div>

                    {/* <div className="other-option-container mt-2">
                      <label>
                        <input
                          type="checkbox"
                          checked={isOtherChecked}
                          onChange={handleOtherCheckboxChange}
                        />{" "}
                        Other
                      </label>
                      {isOtherChecked && (
                        <div className="custom-input-container mt-1">
                          <input
                            type="text"
                            value={customMarketedBy}
                            onChange={handleCustomInputChange}
                            placeholder="Enter custom marketed by..."
                            className="custom-input-field"
                          />
                        </div>
                      )}
                    </div> */}
                  </div>
                  <div className="fixed-label-picker-container-main mt-3">
                    <div className="fixed-label-picker-selected-date-container ml-1 mb-1">
                      <span>Select Manufactured By: </span> &nbsp;
                    </div>
                    <div className="maunfactured-marketed-by-container">
                      <select
                        value={selectedManufacturedBy}
                        name="payment_mode"
                        className="fixed-label-picker-select"
                        onChange={handleManufacturedByChange}
                      >
                        <option value={MarketedBy.EunoiaCrafts}>
                          {ManufacturedBy.EunoiaCrafts}
                        </option>
                        <option value={MarketedBy.Intellozene}>
                          {ManufacturedBy.Intellozene}
                        </option>
                      </select>
                    </div>
                  </div>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Date of Manufacture"
                    className="mb-3 mt-3"
                  >
                    <Form.Control
                      type="text"
                      name="manufactured_date"
                      value={selectedManufacturedDate}
                      onChange={handleManufactureDateChange}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Brand"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="brand"
                      value={selectedBrand}
                      onChange={handleBrandChange}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Country of Origin"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="country_of_origin"
                      value={selectedCountryOfOrigin}
                      onChange={handleCountryOfOriginChange}
                    />
                  </FloatingLabel>
                </div>
              </div>
            </Fade>
          )}

          {step === 3 && (
            <Fade in={step === 3}>
              <div className="labels-data-for-generation-container-main">
                <p>Step 3: Provide the number of labels to print</p>
                <div className="mb-3">
                  <Button
                    className="mr-2 filters-button"
                    onClick={downloadCurrentViewForLabelsData}
                    variant="outline-primary"
                  >
                    Download Labels Data
                  </Button>
                </div>
                <div className="labels-data-for-generation-container">
                  <div className="all-labels-master-data-table">
                    <Table
                      bordered
                      hover
                      size="sm"
                      className="table-auto-width data-table"
                    >
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>PO Secondary SKU</th>
                          <th>Master SKU</th>
                          <th>Inventory SKU</th>
                          <th>Pack-Combo SKU</th>
                          <th>SKU Type</th>
                          <th>Company Code Primary</th>
                          <th>EAN Code</th>
                          <th style={{ minWidth: "40px" }}>Size</th>
                          <th>Color</th>
                          <th>MRP</th>
                          <th>Title</th>
                          <th>One Set Contains</th>
                          <th>Warehouse Quantity</th>
                          <th>Demand Quantity</th>
                          <th>Packed Quantity</th>
                          <th>Dispatched Quantity</th>
                          <th>Pending Quantity</th>
                          <th>Labels Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPurchaseOrderListingDetailsWithLabelsData.map(
                          (singleItem, index) => {
                            return (
                              <tr
                                className={`catalogues-search-bar-row ${
                                  singleItem.labels_count > 0
                                    ? "highlighted-row"
                                    : ""
                                }`}
                              >
                                <td>
                                  {singleItem.listing && (
                                    <img
                                      style={{ height: "70px", width: "70px" }}
                                      src={singleItem.listing.img_wdim}
                                      alt="Product Image"
                                    />
                                  )}
                                </td>
                                <td>{singleItem.po_secondary_sku}</td>
                                <td>{singleItem.master_sku}</td>
                                <td>{singleItem.inventory_sku_id}</td>
                                <td>{singleItem.pack_combo_sku_id}</td>
                                <td>{singleItem.sku_type}</td>
                                <td>{singleItem.company_code_primary}</td>
                                <td>
                                  {singleItem.labels_master_data_item &&
                                    singleItem.labels_master_data_item.ean_code}
                                </td>
                                <td>
                                  {singleItem.labels_master_data_item &&
                                    singleItem.labels_master_data_item.size}
                                </td>
                                <td>
                                  {singleItem.labels_master_data_item &&
                                    singleItem.labels_master_data_item.color}
                                </td>
                                <td>
                                  {singleItem.labels_master_data_item &&
                                    singleItem.labels_master_data_item.mrp}
                                </td>
                                <td>{singleItem.title}</td>
                                <td>
                                  {singleItem.labels_master_data_item &&
                                    singleItem.labels_master_data_item
                                      .one_set_contains}
                                </td>
                                <td>
                                  {" "}
                                  {singleItem.sku_type == "MULTI"
                                    ? getAvailableQuantityForMulti(
                                        singleItem.multi_listings
                                      )
                                    : singleItem.listing
                                    ? singleItem.listing.available_quantity
                                    : 0}
                                </td>
                                <td>{singleItem.demand}</td>
                                <td>{singleItem.reserved_quantity}</td>
                                <td>{singleItem.dispatched_quantity}</td>
                                <td>
                                  {singleItem.demand -
                                    singleItem.reserved_quantity -
                                    singleItem.dispatched_quantity}
                                </td>
                                <td>
                                  {" "}
                                  <input
                                    type="number"
                                    min="0"
                                    value={singleItem.labels_count}
                                    onFocus={() => window.scrollTo(0, 0)}
                                    onChange={(e) =>
                                      handleLabelsCountChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      height: "70px",
                                      fontSize: "16px", // Increase font size
                                      textAlign: "center", // Center-align text
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Fade>
          )}

          {/* Step 3: Select Label Size */}
          {step === 4 && (
            <Fade in={step === 4}>
              <div className="label-count-container mt-4">
                <div className="label-count-section-header mb-2">
                  Label Size Settings
                </div>
                {labelSizes.map((size) => (
                  <div key={size.value} className="form-check">
                    <input
                      type="radio"
                      id={`label-size-${size.value}`}
                      name="labelSize"
                      value={size.value}
                      checked={selectedLabelSize === size.value}
                      onChange={() => handleRadioChange(size.value)}
                      className="form-check-input"
                    />
                    <label
                      htmlFor={`label-size-${size.value}`}
                      className="form-check-label"
                    >
                      {size.label}
                    </label>
                  </div>
                ))}
              </div>
            </Fade>
          )}

          {showGenerateLabelModalLoadingPage && (
            <div className="loading-page">
              <Spinner animation="grow" />
              Loading, please wait...
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCancel}
            className="modal-footer-button"
          >
            Cancel
          </Button>

          {/* Back Button for Steps 2 and 3 */}
          {step > 1 && (
            <Button
              variant="secondary"
              onClick={handleBackStep}
              className="modal-footer-button"
            >
              Back
            </Button>
          )}

          {/* Next Button for Step 1 and 2 */}
          {step < 4 && (
            <Button
              variant="primary"
              onClick={handleNextStep}
              className="modal-footer-button"
            >
              Next
            </Button>
          )}

          {/* Generate Labels Button on the last step */}
          {step === 4 && (
            <Button
              variant="primary"
              onClick={handleLabelsGeneration2}
              className="modal-footer-button"
            >
              Generate Labels
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={generatePhase1BoxLabels}
        onHide={() => setGeneratePhase1BoxLabels(false)}
        className="alert-dialog"
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Generate Phase 1 Box Labels</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="label-count-container mt-4">
            <div className="label-count-section-header mb-2">
              Label Count Settings
            </div>
            <FloatingLabel
              controlId="floatingInput"
              label="Enter the starting box number."
              className="mb-3"
            >
              <Form.Control
                type="number"
                value={startingPhase1BoxNumber}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10) || 0; // Convert to number
                  setStartingPhase1BoxNumber(value);
                }}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Enter the ending box number."
              className="mb-3"
            >
              <Form.Control
                type="number"
                value={endingPhase1BoxNumber}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10) || 0; // Convert to number
                  setEndingPhase1BoxNumber(value);
                }}
              />
            </FloatingLabel>
          </div>

          <div className="label-count-container mt-4">
            <div className="label-count-section-header mb-2">
              Label Size Settings
            </div>
            {labelSizes.map((size) => (
              <div key={size.value} className="form-check">
                <input
                  type="radio"
                  id={`label-size-${size.value}`}
                  name="labelSize"
                  value={size.value}
                  checked={selectedLabelSize === size.value}
                  onChange={() => handleRadioChange(size.value)}
                  className="form-check-input"
                />
                <label
                  htmlFor={`label-size-${size.value}`}
                  className="form-check-label"
                >
                  {size.label}
                </label>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setGenerateProductLabels(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handlePhase1BoxLabelsGeneration}>
            Generate
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModifyPoTypeOption}
        onHide={() => setShowModifyPoTypeOption(false)}
        className="alert-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Modify PO Type</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dropdown-container pt-3">
            <CompanyPurchaseOrdersPoTypeDropDown
              placeHolder="Select PO Type"
              options={ocassions}
              onItemSelected={handlePOTypeFromDropdownSelected}
              providedSelectedValue={providedSelectedPOType}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="modal-footer-button"
            variant="secondary"
            onClick={() => setShowModifyPoTypeOption(false)}
          >
            Cancel
          </Button>
          <Button
            className="modal-footer-button"
            variant="primary"
            onClick={submitPoTypeForModification}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showConfirmationDialogToProceedWithLabelsCreation}
        onHide={() =>
          setShowConfirmationDialogToProceedWithLabelsCreation(false)
        }
        className="alert-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Confirmation to Proceed</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorCountInListingLabels} error(s) were found. Are you sure you want
          to continue without fixing them ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="modal-footer-button"
            variant="secondary"
            onClick={() =>
              setShowConfirmationDialogToProceedWithLabelsCreation(false)
            }
          >
            Cancel
          </Button>
          <Button
            className="modal-footer-button"
            variant="primary"
            onClick={() => {
              setStep(2);
              setShowConfirmationDialogToProceedWithLabelsCreation(false);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {showDownloadPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Please wait, While your file is being downloaded...
        </div>
      )}
    </div>
  );
}

export default CompanyPurchaseOrdersDetails;
