import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import {
  BoxLevelItem,
  Consignment,
  ConsignmentInvoiceItem,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderListingDetailsDataObject,
} from "../../atomic_components/types";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { grey } from "@mui/material/colors";
import Barcode from "react-barcode";
import JsBarcode from "jsbarcode";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30, // Adjusted for multi-line header
    paddingBottom: 30, // Space for footer
    paddingHorizontal: 20,
  },
  section: {
    margin: 10,
    padding: 0, // No padding for the section
    flexGrow: 1,
  },
  containerAllBorder: {
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid black",
    fontSize: 10,
    textAlign: "center", // Center text for header row
  },
  containerBottomBorder: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    fontSize: 10,
    padding: 0, // No padding for the data rows
    textAlign: "center", // Center text for data rows
  },
  srNo: {
    width: "5%",
    padding: 0, // No padding for the srNo column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
  },
  srNoHeading: {
    width: "5%",
    padding: 0, // No padding for the srNo column
    borderRight: "1px solid black",
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  poSkuHeading: {
    width: "25%", // Adjusted width to accommodate the new empty column
    padding: 0, // No padding for the qty column
    borderRight: "1px solid black",
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  poSku: {
    width: "25%", // Adjusted width to accommodate the new empty column
    padding: 0, // No padding for the qty column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
  },
  companyCodePrimary: {
    width: "20%", // Adjusted width
    padding: 0, // No padding for the unitPrice column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
  },
  companyCodePrimaryHeading: {
    width: "20%", // Adjusted width
    padding: 0, // No padding for the unitPrice column
    borderRight: "1px solid black",
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  warehouseInventory: {
    width: "10%", // Adjusted width`
    padding: 0, // No padding for the unitPrice column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
    backgroundColor: "grey",
  },
  warehouseInventoryHeading: {
    width: "10%", // Adjusted width
    padding: 0, // No padding for the unitPrice column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 8,
    paddingTop: 8,
  },
  demand: {
    width: "10%",
    padding: 0, // No padding for the amount column
    borderRight: "1px solid black",
    paddingBottom: 3,
    paddingTop: 3,
    textAlign: "center", // Center text
  },
  pendingDemandHeading: {
    width: "10%",
    padding: 0, // No padding for the amount column
    borderRight: "1px solid black",
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  emptyColumn: {
    width: "20%", // Added column with some width
    padding: 0, // No padding for the empty column
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
  },
  emptyColumnHeading: {
    width: "20%", // Added column with some width
    padding: 0, // No padding for the empty column
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  headerContainer: {
    textAlign: "center", // Center all text in header
    marginBottom: 10, // Adjust spacing between header lines
  },
  headerTextBold: {
    fontSize: 14,
    fontWeight: "bold", // Bold text for company name
    textAlign: "center", // Center company name
    marginBottom: 20,
  },
  headerText: {
    fontSize: 10,
    textAlign: "center", // Center other lines
  },
  headerTextQty: {
    fontSize: 10,
    textAlign: "center", // Center other lines
    marginTop: 10,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center", // Center text for footer
    fontSize: 6,
    padding: 10, // Keep padding for the footer text
  },
  totalSkusPendingContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 10,
    fontSize: 8,
  },
  totalSkusText: {
    fontWeight: "bold",
    marginRight: 20, // Space between total SKUs and pending quantity
  },
  totalPendingText: {
    fontWeight: "bold",
  },
  rowHeaderText: {
    fontSize: 8,
    marginBottom: 4,
  },
  smallText: {
    fontSize: 8,
    width: "100%",
    textAlign: "center",
  },
  smallTextLeftAligned: {
    fontSize: 8,
    width: "100%",
    textAlign: "left",
  },
  rowText: {
    fontSize: 16,
  },
  logo: {
    width: "100%", // Adjust width as needed
    height: 50, // Adjust height as needed
  },
  barCode: {
    width: 150, // Width of the barcode image
    height: 18, // Height of the barcode image
    resizeMode: "contain", // Ensures the image fits properly
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  imageContainer: {
    width: "30%",
    display: "flex",
    flexDirection: "row",
  },
  headingContainer: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
  },
  packingListHeaderText: {
    fontSize: 22, // Adjust the font size for the Purchase Order text
    fontWeight: "ultrabold",
    marginTop: 15,
    width: "100%",
    textAlign: "right",
  },
  packingListSubHeaderText: {
    fontSize: 12, // Adjust the font size for the Purchase Order text
    fontWeight: "ultrabold",
    marginTop: 15,
    width: "100%",
    textAlign: "right",
  },
  packingListDetailsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  packingListDetailsRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 8,
  },

  packingListDetailsRowHeadingSection: {
    width: "25%",
    display: "flex",
    flexDirection: "row",
  },
  packingListDetailsRowContentSection: {
    width: "75%",
    display: "flex",
    flexDirection: "row",
  },
  packingListItemsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  packingListRowContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: 7,
    marginTop: 7,
  },

  packingListRowHeaderContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    fontWeight: "bold",
  },

  packingListRowHeading: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  packingListRowItems: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingTop: 3,
    paddingBottom: 3,
    marginBottom: 1,
  },
  packingListRowHeaderItems: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  packingListRowItemCompanyCode: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 2,
  },

  packingListRowItemSecondaryCode: {
    width: "40%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  packingListRowItemQuantity: {
    width: "20%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  horizontalLine: {
    borderBottom: "0.5px solid #ccc", // Define the line's appearance
    marginVertical: 10, // Add spacing above and below the line
  },
  horizontalLineHeader: {
    borderBottom: "0.5px solid #ccc", // Define the line's appearance
    marginTop: 4,
    marginBottom: 4,
  },
  marginOnly: {
    marginBottom: 30, // Add spacing above and below the line
    width: "100%",
  },
});

const sanitize = (invoice_number: string) => {
  const match = invoice_number.match(/[\/\\](\d+)$/); // Match '/' or '\' followed by digits
  return match ? match[1] : null;
};

const generateBarcode = (value: string) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, value, {
    format: "CODE128",
    height: 18, // Increased for better scanability
    width: 2, // Increased for better scanability
    margin: 0, // Removes extra white space
    displayValue: false, // Hides default text below the barcode
  });
  return canvas.toDataURL("image/png");
};
const CompanyPurchaseOrderBoxPackingListPDF: React.FC<{
  boxLevelItems: BoxLevelItem[];
  incomingPurchaseOrder: IncomingPurchaseOrder;
  selectedConsignmentInvoiceItem: ConsignmentInvoiceItem;
}> = ({
  boxLevelItems,
  incomingPurchaseOrder,
  selectedConsignmentInvoiceItem,
}) => {
  console.log("from pdf ", boxLevelItems);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.logoContainer}>
          <View style={styles.imageContainer}>
            <Image
              style={styles.logo}
              src="/ecraft_complete_logo.PNG" // Replace with your image URL or local path
            />
          </View>
          <View style={styles.headingContainer}>
            <Text style={styles.packingListHeaderText}>PACKING LIST</Text>
            <Text style={styles.packingListSubHeaderText}>(BOX WISE)</Text>
          </View>
        </View>

        <View style={styles.packingListDetailsContainer}>
          <View style={styles.packingListDetailsRow}>
            <View style={styles.packingListDetailsRowHeadingSection}>
              <Text style={styles.smallTextLeftAligned}>Shipped From : </Text>
            </View>
            <View style={styles.packingListDetailsRowContentSection}>
              <Text style={styles.smallTextLeftAligned}>
                Warehouse 1, Khasra No. 1660, Jat Colony, Jaisinghpura Road,
                Bhankrota, Jaipur, 302026
              </Text>
            </View>
          </View>
          <View style={styles.packingListDetailsRow}>
            <View style={styles.packingListDetailsRowHeadingSection}>
              <Text style={styles.smallTextLeftAligned}>Shipped To : </Text>
            </View>
            <View style={styles.packingListDetailsRowContentSection}>
              <Text style={styles.smallTextLeftAligned}>
                {incomingPurchaseOrder.delivery_address}
              </Text>
            </View>
          </View>
          <View style={styles.packingListDetailsRow}>
            <View style={styles.packingListDetailsRowHeadingSection}>
              <Text style={styles.smallTextLeftAligned}>Po Number : </Text>
            </View>
            <View style={styles.packingListDetailsRowContentSection}>
              <Text style={styles.smallTextLeftAligned}>
                {incomingPurchaseOrder.po_number}
              </Text>
            </View>
          </View>
          <View style={styles.packingListDetailsRow}>
            <View style={styles.packingListDetailsRowHeadingSection}>
              <Text style={styles.smallTextLeftAligned}>Invoice Number : </Text>
            </View>
            <View style={styles.packingListDetailsRowContentSection}>
              <Text style={styles.smallTextLeftAligned}>
                {sanitize(selectedConsignmentInvoiceItem.invoice_number)}
              </Text>
            </View>
          </View>
          <View style={styles.packingListDetailsRow}>
            <View style={styles.packingListDetailsRowHeadingSection}>
              <Text style={styles.smallTextLeftAligned}>Total Boxes : </Text>
            </View>
            <View style={styles.packingListDetailsRowContentSection}>
              <Text style={styles.smallTextLeftAligned}>
                {boxLevelItems.length}
              </Text>
            </View>
          </View>
          <View style={styles.packingListDetailsRow}>
            <View style={styles.packingListDetailsRowHeadingSection}>
              <Text style={styles.smallTextLeftAligned}>Total Quantity : </Text>
            </View>
            <View style={styles.packingListDetailsRowContentSection}>
              <Text style={styles.smallTextLeftAligned}>
                {boxLevelItems.reduce(
                  (total, boxLevelItem) =>
                    total +
                    boxLevelItem.box_details.reduce(
                      (subTotal, item) => subTotal + item.box_quantity,
                      0
                    ),
                  0
                )}{" "}
                Units
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.packingListItemsContainer}>
          <View style={styles.horizontalLineHeader} />
          <View style={styles.packingListRowHeaderContainer}>
            <View style={styles.packingListRowHeaderItems}>
              <View style={styles.packingListRowItemCompanyCode}>
                <Text style={styles.smallText}>Buyer SKU Code</Text>
              </View>
              <View style={styles.packingListRowItemSecondaryCode}>
                <Text style={styles.smallText}>eCraft SKU Code</Text>
              </View>
              <View style={styles.packingListRowItemQuantity}>
                <Text style={styles.smallText}>Quantity</Text>
              </View>
            </View>
          </View>
          <View style={styles.horizontalLineHeader} />

          {boxLevelItems.map((boxLevelItem, index) => (
            <View key={index} style={styles.packingListRowContainer}>
              <View style={styles.packingListRowHeading}>
                <Text style={styles.rowHeaderText}>
                  Box Number : {boxLevelItem.box_number} | Total Quantity :{" "}
                  {boxLevelItem.box_details.reduce(
                    (total, detail) => total + detail.box_quantity,
                    0
                  )}
                </Text>
              </View>

              {boxLevelItem.box_details.map((boxLevelItem, boxLevelIndex) => (
                <View key={boxLevelIndex} style={styles.packingListRowItems}>
                  <View style={styles.packingListRowItemCompanyCode}>
                    <Image
                      style={styles.barCode}
                      src={generateBarcode(boxLevelItem.company_code_primary)}
                    />
                    <Text style={styles.smallText}>
                      {boxLevelItem.company_code_primary}
                    </Text>
                  </View>
                  <View style={styles.packingListRowItemSecondaryCode}>
                    <Text style={styles.smallText}>
                      {boxLevelItem.po_secondary_sku}
                    </Text>
                  </View>
                  <View style={styles.packingListRowItemQuantity}>
                    <Text style={styles.smallText}>
                      {boxLevelItem.box_quantity}
                    </Text>
                  </View>
                </View>
              ))}

              <View style={styles.horizontalLineHeader} />
            </View>
          ))}
        </View>
        {/* Footer */}
        <View style={styles.footer} fixed>
          <Text
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );
};

export default CompanyPurchaseOrderBoxPackingListPDF;
