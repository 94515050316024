import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Form,
  Modal,
  Offcanvas,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import "./CSS/CompanyPurchaseOrderConsignmentDetails.css";

import {
  Catalogue,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  Consignment,
  ConsignmentAnalyticsObject,
  ConsignmentItems,
  ConsignmentItemsBoxView,
  ConsignmentItemsPOView,
  ConsignmentItemsSKUView,
  PoDocument,
  PurchaseOrder,
  PurchaseOrderDemandObject,
  SearchParams,
  Transporter,
  ShipmentData,
  ConsignmentInvoiceItem,
  InvoiceDataItem,
  BoxLevelItem,
  SKUPackingListItem,
  BoxInvoiceItem,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrdersAllPurchaseOrdersSearchBar from "./CompanyPurchaseOrdersAllPurchaseOrdersSearchBar";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import {
  ChevronDown,
  ChevronUp,
  Download,
  XCircleFill,
} from "react-bootstrap-icons";
import jsonexport from "jsonexport";
import Papa from "papaparse";
import _ from "lodash";
import { ElevatorSharp } from "@mui/icons-material";
import Header from "../Header";
import CompanyPurchaseOrderLabelUtils from "./LabelUtils/CompanyPurchaseOrderLabelUtils";
import CompanyPurchaseOrderBoxPackingListPDF from "./CompanyPurchaseOrderBoxPackingListPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CompanyPurchaseOrderSKUPackingListPDF from "./CompanyPurchaseOrderSKUPackingListPDF";

interface RouteParams {
  po_id: string;
  consignment_id: string;
}

function CompanyPurchaseOrdersConsignmentDocuments() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const { po_id, consignment_id } = useParams<RouteParams>();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [selectedConsignment, setSelectedConsignment] = useState<Consignment>();
  const [consignmentInvoiceItems, setConsignmentInvoiceItems] = useState<
    ConsignmentInvoiceItem[]
  >([]);
  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);
  const [showUploadInvoiceCanvas, setShowUploadInvoiceCanvas] =
    useState<boolean>(false);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [isExpandedDetailsLoading, setIsExpandedDetailsLoading] =
    useState<boolean>(false);
  const [showAssignInvoiceNumberCanvas, setShowInvoiceNumberCanvas] =
    useState<boolean>(false);
  const [fyInvoiceNumber, setFyInvoiceNumber] = useState<string>("");
  const [otherFyInvoiceNumber, setOtherFyInvoiceNumber] = useState<string>("");
  const [generatePackingListPDF, setGeneratePackingListPDF] =
    useState<boolean>(false);
  const [generateSKUPackingListPDF, setGenerateSKUPackingListPDF] =
    useState<boolean>(false);

  const [files, setFiles] = useState<File[]>([]);
  const [boxLevelData, setBoxLevelData] = useState<BoxLevelItem[]>([]);
  const [skuLevelData, setSkuLevelData] = useState<SKUPackingListItem[]>([]);
  const [useOtherInvoiceNumber, setUseOtherInvoiceNumber] =
    useState<boolean>(false);

  const [currentPurchaseOrder, setCurrentPurchaseOrder] =
    useState<IncomingPurchaseOrder>();

  const handleCheckboxChange = () => {
    setUseOtherInvoiceNumber(!useOtherInvoiceNumber);
  };

  const fetchConsignmentByConsignmentId = async (consignment_id: string) => {
    setShowLoadingPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.fetchConsignmentByConsignmentId(
          consignment_id
        );

      setSelectedConsignment(response.data);
      setShowLoadingPage(false);
    } catch (error) {
      console.error("Error in fetching Consignment by consignment id: ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const submitInvoiceNumber = () => {
    let finalInvoiceNumber = "";
    if (useOtherInvoiceNumber) {
      if (
        otherFyInvoiceNumber.length < 10 ||
        otherFyInvoiceNumber.length > 16
      ) {
        toast.error(
          "Invalid Invoice Number. Invoice number should have less than 16 and more than 10 characters.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );

        return;
      }

      finalInvoiceNumber = otherFyInvoiceNumber;
    } else {
      let invoiceNumber = "";
      if (/^\d{0,4}$/.test(fyInvoiceNumber)) {
        // Allow only up to 4 digits
        const numericValue = parseInt(fyInvoiceNumber, 10);

        if (numericValue >= 1 && numericValue <= 9999) {
          invoiceNumber = fyInvoiceNumber.padStart(2, "0");
        } else {
          toast.error("Invoice Number must be between 01 and 9999.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
          return;
        }
      } else {
        toast.error("Only numeric values up to 4 digits are allowed.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });

        return;
      }

      const created_invoice_number = getFinancialYear() + invoiceNumber;

      if (
        created_invoice_number.length < 10 ||
        created_invoice_number.length > 16
      ) {
        toast.error(
          "Invalid Invoice Number. Invoice number should have less than 16 and more than 10 characters.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );

        return;
      }

      finalInvoiceNumber = created_invoice_number;
    }

    submitInvoiceNumberToWeb(finalInvoiceNumber);
  };

  const submitInvoiceNumberToWeb = async (finalInvoiceNumber: string) => {
    setShowLoadingPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.submitInvoiceNumberToWeb(
          finalInvoiceNumber,
          po_id,
          consignment_id,
          userDetailsFromStore.user_details.userId,
          consignmentInvoiceItems[
            expandedRow ? expandedRow : 0
          ].invoice_id.toString(),
          currentPurchaseOrder ? currentPurchaseOrder.sold_via : ""
        );
      setConsignmentInvoiceItems(response.data);
      setShowLoadingPage(false);
      setShowInvoiceNumberCanvas(false);
    } catch (error) {
      console.error("Error in submitting invoice number : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const downloadFileFromWeb = async (file_id: any, saved_file_name: string) => {
    setShowDownloadPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.downloadPoFileFromWeb(file_id);

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = saved_file_name; // Use the original file name for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setShowDownloadPage(false);
    } catch (error) {
      console.error("Error in downloading file : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowDownloadPage(false);
    }
  };

  const getInvoiceData = async (
    consignment_id: string
  ): Promise<InvoiceDataItem[]> => {
    setShowDownloadPage(true);
    try {
      const response = await companyPurchaseOrderNetworkUtil.getDataForInvoice(
        consignment_id
      );
      // setConsignmentInvoiceItems(response.data);
      setShowDownloadPage(false);
      return response.data;
    } catch (error) {
      console.error("Error in fetching invoice data : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowDownloadPage(false);
      return [];
    }
  };

  const getBoxInvoiceData = async (
    consignment_id: string
  ): Promise<BoxInvoiceItem[]> => {
    setShowDownloadPage(true);
    try {
      const response = await companyPurchaseOrderNetworkUtil.getBoxInvoiceData(
        consignment_id
      );
      // setConsignmentInvoiceItems(response.data);
      setShowDownloadPage(false);
      return response.data;
    } catch (error) {
      console.error("Error in fetching box invoice data : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowDownloadPage(false);
      return [];
    }
  };

  const sanitize = (invoice_number: string) => {
    const match = invoice_number.match(/[\/\\](\d+)$/); // Match '/' or '\' followed by digits
    return match ? match[1] : null;
  };

  const downloadPackingListData = async (
    consignmentInvoiceItem: ConsignmentInvoiceItem
  ) => {
    const boxInvoicePackingListData: BoxInvoiceItem[] = await getBoxInvoiceData(
      consignmentInvoiceItem.consignment_id.toString()
    );

    try {
      // itemsToExport.push()
      const transformedItems = boxInvoicePackingListData.map((singleItem) => {
        return {
          po_number: singleItem.po_number,
          company_code_primary: singleItem.company_code_primary,
          po_secondary_sku: singleItem.po_secondary_sku,
          hsn: singleItem.hsn_code,
          invoice_number: sanitize(consignmentInvoiceItem.invoice_number),
          invoice_date: DateTimeUtils.getCurrentDateOnly(),
          quantity: singleItem.box_quantity,
          item_price_excl_gst: singleItem.rate_without_tax,
          total_cost_excluding_gst:
            Math.round(
              singleItem.rate_without_tax * singleItem.box_quantity * 100
            ) / 100,
          tax_rate: singleItem.tax_rate,
          total_cost_including_gst:
            Math.round(
              ((singleItem.box_quantity *
                singleItem.rate_without_tax *
                (100 + singleItem.tax_rate)) /
                100) *
                100
            ) / 100,
          box_number: singleItem.box_number,
        };
      });
      jsonexport(transformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = consignmentInvoiceItem.consignment_id + "_packing_list";

        // Simulate a click to trigger the download
        link.click();
      });
    } catch (error) {
      console.log("Printing error from downloadInvoiceData ");
      console.error("Error:", error);
    }
  };

  const downloadInvoiceData = async (
    consignmentInvoiceItem: ConsignmentInvoiceItem
  ) => {
    const invoiceData: InvoiceDataItem[] = await getInvoiceData(
      consignmentInvoiceItem.consignment_id.toString()
    );

    console.log(invoiceData);

    try {
      // itemsToExport.push()
      const transformedItems = invoiceData.map((singleItem) => {
        return {
          invoice_date: DateTimeUtils.getCurrentDateOnly(),
          invoice_number: consignmentInvoiceItem.invoice_number,
          po_number: singleItem.po_number,
          company_code_primary: singleItem.company_code_primary,
          po_secondary_sku: singleItem.po_secondary_sku,
          title: singleItem.title,
          quantity: singleItem.consignment_qty,
          item_price_excl_gst: singleItem.rate_without_tax,
          state: "",
          tax_rate: singleItem.tax_rate,
          igst:
            Math.round(
              ((singleItem.consignment_qty *
                singleItem.rate_without_tax *
                singleItem.tax_rate) /
                100) *
                100
            ) / 100,
          sgst_cgst:
            Math.round(
              ((singleItem.consignment_qty *
                singleItem.rate_without_tax *
                singleItem.tax_rate) /
                200) *
                100
            ) / 100,
          taxable_amount:
            Math.round(
              singleItem.consignment_qty * singleItem.rate_without_tax * 100
            ) / 100,
          net_sale:
            Math.round(
              ((singleItem.consignment_qty *
                singleItem.rate_without_tax *
                (100 + singleItem.tax_rate)) /
                100) *
                100
            ) / 100,
          gstin: singleItem.buyer_gstin,
          company_name: singleItem.company_name,
          shipping_address: singleItem.delivery_address,
          pin_code: "",
          iat: "",
          total_box: "Total Box - " + singleItem.box_count,
          transporter_name: consignmentInvoiceItem.transporter.transporter_name,
          export_sales: "",
          shipment_mode: consignmentInvoiceItem.shipment_mode,
          transporter_legal_id:
            consignmentInvoiceItem.transporter.legal_transporter_id,
          vehicle_docket_number:
            consignmentInvoiceItem.shipment_mode == "Roadways"
              ? consignmentInvoiceItem.vehicle_number
              : consignmentInvoiceItem.docket_number,
          vehicle_type: "",
          sold_via: currentPurchaseOrder ? currentPurchaseOrder.sold_via : "",
          invoice_id: consignmentInvoiceItem.invoice_id,
          hsn: singleItem.hsn_code,
        };
      });
      jsonexport(transformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          consignmentInvoiceItem.consignment_id + "_invoice_raw_data";

        // Simulate a click to trigger the download
        link.click();
      });
    } catch (error) {
      console.log("Printing error from downloadInvoiceData ");
      console.error("Error:", error);
    }
  };

  const getBoxLevelData = async (
    consignment_id: string
  ): Promise<BoxLevelItem[]> => {
    setShowDownloadPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.getDataForBoxLabels(
          consignment_id
        );
      // setConsignmentInvoiceItems(response.data);
      setShowDownloadPage(false);
      return response.data;
    } catch (error) {
      console.error("Error in fetching invoice data : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowDownloadPage(false);
      return [];
    }
  };

  const fetchPurchaseOrderDetails = async (purchase_order_id: any) => {
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.geturchaseOrderByPOid(
          purchase_order_id.toString()
        );
      // console.log(response.data);
      setCurrentPurchaseOrder(response.data);
      return response.data;
    } catch (error) {
      toast.error("Error! Could not find purchase order details.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const donwloadBoxLevelItems = async (
    consignmentInvoiceItem: ConsignmentInvoiceItem
  ) => {
    setShowDownloadPage(true);

    if (boxLevelData.length == 0) {
      const localBoxLevelData: BoxLevelItem[] = await getBoxLevelData(
        consignmentInvoiceItem.consignment_id.toString()
      );
      setBoxLevelData(localBoxLevelData);
    }

    if (!currentPurchaseOrder) {
      const localPurchaseOrder: IncomingPurchaseOrder =
        await fetchPurchaseOrderDetails(po_id);
      // setCurrentPurchaseOrder(localPurchaseOrder);
    }

    setShowDownloadPage(false);
    setGeneratePackingListPDF(true);
  };

  const downloadBoxLabels = async (
    consignmentInvoiceItem: ConsignmentInvoiceItem
  ) => {
    let localBoxLevelData: BoxLevelItem[] = boxLevelData;
    if (boxLevelData.length == 0) {
      localBoxLevelData = await getBoxLevelData(
        consignmentInvoiceItem.consignment_id.toString()
      );
      setBoxLevelData(localBoxLevelData);
    }

    const match = consignmentInvoiceItem.invoice_number.match(/[\/\\](\d+)$/); // Match '/' or '\' followed by digits
    const sanitized_invoice_number = match ? match[1] : "";

    CompanyPurchaseOrderLabelUtils.generateBoxLabels4by6_v2(
      localBoxLevelData,
      sanitized_invoice_number,
      consignmentInvoiceItem.consignment_id.toString()
    );
  };

  const donwloadBoxLevelItemsWithSKUpackingListPrep = async (
    consignmentInvoiceItem: ConsignmentInvoiceItem
  ) => {
    setShowDownloadPage(true);

    let localBoxLevelData: BoxLevelItem[] = boxLevelData;
    if (boxLevelData.length == 0) {
      localBoxLevelData = await getBoxLevelData(
        consignmentInvoiceItem.consignment_id.toString()
      );
      setBoxLevelData(localBoxLevelData);
    }

    if (!currentPurchaseOrder) {
      const localPurchaseOrder: IncomingPurchaseOrder =
        await fetchPurchaseOrderDetails(po_id);
      // setCurrentPurchaseOrder(localPurchaseOrder);
    }

    let localSkuLevelData: SKUPackingListItem[] = skuLevelData;
    if (skuLevelData.length == 0) {
      localSkuLevelData = await prepareSKUWiseDataForPackingList(
        localBoxLevelData
      );
      setSkuLevelData(localSkuLevelData);
    }

    setShowDownloadPage(false);
    setGenerateSKUPackingListPDF(true);
  };

  const prepareSKUWiseDataForPackingList = (
    boxLevelData: BoxLevelItem[]
  ): SKUPackingListItem[] => {
    const skuPackingMap = new Map<string, SKUPackingListItem>();
    boxLevelData.forEach((boxLevelItem) => {
      boxLevelItem.box_details.forEach((detail) => {
        const {
          company_code_primary,
          po_secondary_sku,
          box_number,
          box_quantity,
        } = detail;

        // Determine if the box contains multiple SKUs
        const is_multi = boxLevelItem.box_details.length > 1;
        const key = `${company_code_primary}-${po_secondary_sku}`;

        if (!skuPackingMap.has(key)) {
          // Add a new SKUPackingListItem
          skuPackingMap.set(key, {
            company_code_primary,
            po_secondary_sku,
            boxes: [],
          });
        }

        // Add the box to the existing SKUPackingListItem
        skuPackingMap.get(key)?.boxes.push({
          box_number,
          quantity: box_quantity,
          is_multi,
        });
      });
    });

    return Array.from(skuPackingMap.values());
  };

  const getRelatedInvoiceEntries = async (consignment_id: string) => {
    setShowLoadingPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.fetchConsignmentInvoiceEntries(
          po_id,
          consignment_id
        );

      setConsignmentInvoiceItems(response.data);
      setShowLoadingPage(false);
    } catch (error) {
      console.error(
        "Error in fetching Invoice entries by consignment id: ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const toggleRow = (index: number) => {
    if (expandedRow === index) {
      setExpandedRow(null); // collapse if already expanded
    } else {
      setExpandedRow(index); // expand selected row
      // setIsPackComboListingDetailsLoading(true);
      // getPackComboListingDetails(
      //   currentPackComboListings[index].pack_combo_sku_id
      // );
    }
  };

  const getClassNameForInvoiceNumberStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "ASSIGNED") return "color-green";
    else if (invoice_number_status == "NOT-ASSIGNED") return "color-red";
    return "";
  };

  const getClassNameForInvoiceStatus = (invoice_status: string) => {
    if (invoice_status == "ACTIVE") return "color-green";
    else if (invoice_status == "INACTIVE") return "color-red";
    return "";
  };

  const getClassNameForInvoiceUploadStatus = (invoice_status: string) => {
    if (invoice_status == "UPLOADED") return "color-green";
    else if (invoice_status == "NOT-UPLOADED") return "color-red";
    return "";
  };

  const handleFYinvoiceNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFyInvoiceNumber(e.target.value.trim());
  };

  const handleOtherFYinvoiceNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOtherFyInvoiceNumber(e.target.value.trim());
  };

  const getCalculatedPoStatusColor = (calculatedStatus: string) => {
    if (calculatedStatus === "ACKNOWLEDGEMENT PENDING")
      return "incoming-po-text-orange";
    else if (calculatedStatus === "OPEN") return "incoming-po-text-green";
    else if (calculatedStatus === "MARKED CANCELLED")
      return "incoming-po-text-purple";
    else if (calculatedStatus === "EXPIRED") return "incoming-po-text-red";
    return "";
  };

  const getWIPcolor = (wip_status: string) => {
    if (wip_status == "YES") return "marked-green";
    else return "marked-red";
  };

  function getFinancialYear(): string {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-indexed

    // Financial year starts in April
    const startYear = currentMonth >= 4 ? currentYear : currentYear - 1;
    const endYear = (startYear + 1).toString().slice(-2);

    return `${startYear}-${endYear}/`;
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event.target.files;
    if (uploadedFiles) {
      const fileArray = Array.from(uploadedFiles);
      if (fileArray.length + files.length > 2) {
        alert("You can upload a maximum of 2 files.");
      } else {
        setFiles([...files, ...fileArray]);
      }
    }
  };

  const handleFileRemove = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const uploadInvoice = async () => {
    if (consignmentInvoiceItems.length > 0) {
      try {
        const response = await companyPurchaseOrderNetworkUtil.uploadInvoice(
          files,
          userDetailsFromStore.user_details.userId,
          po_id,
          consignmentInvoiceItems[
            expandedRow ? expandedRow : 0
          ].consignment_id.toString(),
          consignmentInvoiceItems[
            expandedRow ? expandedRow : 0
          ].invoice_id.toString(),
          consignmentInvoiceItems[
            expandedRow ? expandedRow : 0
          ].invoice_number.toString()
        );
        console.log(response);
        toast.success("Files successfully uploaded!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        // updateTransferListing(response.data["paths"], transferListing);
        setShowUploadInvoiceCanvas(false);
        window.location.reload();
      } catch (error) {
        console.log(error);
        toast.error(
          "Unknown error while trying to upload files! Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );
        return;
      }
    }
  };

  useEffect(() => {
    fetchPurchaseOrderDetails(po_id);
    fetchConsignmentByConsignmentId(consignment_id);
    getRelatedInvoiceEntries(consignment_id);
  }, []);

  useEffect(() => {
    if (files.length == 0) {
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset the file input
      }
    }
  }, [files]);

  return (
    <div className="consignment-details-container catalogues-container">
      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          {/* Loading, please wait... */}
        </div>
      )}

      {showDownloadPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
          Please wait, While your file is being downloaded...
        </div>
      )}

      {selectedConsignment && (
        <div className="vendor-action-buttons-container incoming-purchase-order-pack-task-details-table-container">
          <div className="po-document-section-heading">
            <Table bordered hover size="sm" className="table-auto-width mt-2">
              <tbody>
                <tr className="consignment-heading">
                  <td className="nowrap">
                    Consignment - {selectedConsignment.consignment_id}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      )}

      {currentPurchaseOrder && (
        <div className="incoming-purchase-order-pack-task-details-table-container">
          <Table bordered hover size="sm" className="table-auto-width">
            <tbody>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Status</td>
                <td
                  className={getCalculatedPoStatusColor(
                    currentPurchaseOrder.calculated_po_status
                  )}
                >
                  {currentPurchaseOrder.calculated_po_status}
                </td>
              </tr>

              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Marked WIP ?</td>
                <td className={getWIPcolor(currentPurchaseOrder.is_wip)}>
                  {currentPurchaseOrder.is_wip}
                </td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Buyer Company Name</td>
                <td>{currentPurchaseOrder.company.company_name}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Number</td>
                <td>{currentPurchaseOrder.po_number}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Type</td>
                <td>{currentPurchaseOrder.po_type}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Sold Via</td>
                <td>
                  <b>{currentPurchaseOrder.sold_via}</b>
                </td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Reference Location</td>
                <td>{currentPurchaseOrder.delivery_city}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Delivery Address</td>
                <td>{currentPurchaseOrder.delivery_address}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Billing Address</td>
                <td>{currentPurchaseOrder.billing_address}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Buyer GSTIN</td>
                <td>{currentPurchaseOrder.buyer_gstin}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Issue Date</td>
                <td>
                  {DateTimeUtils.formatDateOnly(
                    currentPurchaseOrder.po_issue_date
                  )}
                </td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Expiry Date</td>
                <td>
                  {DateTimeUtils.formatDateOnly(
                    currentPurchaseOrder.expiry_date
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}

      <div className="vendor-action-buttons-container incoming-purchase-order-pack-task-details-table-container pt-4">
        <div className="po-document-section-heading">Invoices</div>
        {consignmentInvoiceItems.length === 0 ? (
          <div className="po-document-section-no-document mt-3">
            No invoice item was found
          </div>
        ) : (
          <div className="incoming-purchase-order-file-details-table-container pt-4 invoice-details-table">
            <Table bordered hover size="sm" className="table-auto-width">
              <thead className="sticky-header">
                <tr>
                  <th>Invoice ID</th>
                  <th>Sold Via</th>
                  <th>Invoice Number Status</th>
                  <th>Invoice Number</th>
                  <th>Invoice Upload Status</th>
                  <th>Invoice Status</th>
                  <th>Transporter Name</th>
                  <th>Shipment Mode</th>
                  <th>Vehicle Number</th>
                  <th>Docket Number</th>
                  <th>Marked RTD By</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {consignmentInvoiceItems.map(
                  (consignmentInvoiceItem, index) => {
                    const isExpanded = expandedRow === index;
                    return (
                      <React.Fragment key={index}>
                        <tr className="catalogues-search-bar-row">
                          <td className="nowrap">
                            {consignmentInvoiceItem.invoice_id}
                          </td>

                          <td className="nowrap">
                            {consignmentInvoiceItem.sold_via}
                          </td>

                          <td
                            className={getClassNameForInvoiceNumberStatus(
                              consignmentInvoiceItem.invoice_number_status
                            )}
                          >
                            {consignmentInvoiceItem.invoice_number_status}
                          </td>

                          <td>{consignmentInvoiceItem.invoice_number}</td>

                          <td
                            className={getClassNameForInvoiceUploadStatus(
                              consignmentInvoiceItem.invoice_number_status ==
                                "NOT-ASSIGNED"
                                ? ""
                                : consignmentInvoiceItem.invoice_upload_status
                            )}
                          >
                            {consignmentInvoiceItem.invoice_number_status ==
                            "NOT-ASSIGNED"
                              ? ""
                              : consignmentInvoiceItem.invoice_upload_status}
                          </td>
                          <td
                            className={getClassNameForInvoiceStatus(
                              consignmentInvoiceItem.invoice_status
                            )}
                          >
                            {consignmentInvoiceItem.invoice_status}
                          </td>
                          <td>
                            {
                              consignmentInvoiceItem.transporter
                                .transporter_name
                            }
                          </td>
                          <td>{consignmentInvoiceItem.shipment_mode}</td>
                          <td>{consignmentInvoiceItem.vehicle_number}</td>
                          <td>{consignmentInvoiceItem.docket_number}</td>

                          <td>{consignmentInvoiceItem.created_by}</td>
                          <td>
                            {DateTimeUtils.formatDateTime(
                              consignmentInvoiceItem.created_at
                            )}
                          </td>
                          <td>
                            {DateTimeUtils.formatDateTime(
                              consignmentInvoiceItem.updated_at
                            )}
                          </td>
                          <td>
                            {isExpanded ? (
                              <ChevronUp
                                size={15}
                                color="#007bff"
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleRow(index)}
                              />
                            ) : (
                              <ChevronDown
                                size={15}
                                color="#007bff"
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleRow(index)}
                              />
                            )}
                          </td>

                          {/* <td>
                        <Download
                          size={15}
                          color="#007bff"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            downloadFileFromWeb(doc.id, doc.saved_file_name)
                          }
                        />
                      </td> */}
                        </tr>

                        {isExpanded && (
                          <tr
                            className={`incoming-po-search-bar-row expandable-content-invoice 
                            
                            ${isExpanded ? "expanded" : "collapsed"}`}
                          >
                            <td
                              colSpan={13}
                              className="invoice-section-expanded"
                            >
                              {isExpandedDetailsLoading ? (
                                <div className="loading-item">
                                  <Spinner animation="grow" />
                                  Loading, please wait...
                                </div>
                              ) : (
                                <div className="expanded-content">
                                  <div className="expanded-container-sub">
                                    <div className="actions-container">
                                      {userDetailsFromStore.authorities.includes(
                                        "AUTH_ACCOUNTS"
                                      ) && (
                                        <div className="purchase-order-action-buttons-container">
                                          {consignmentInvoiceItem.invoice_number_status ==
                                            "NOT-ASSIGNED" && (
                                            <Button
                                              variant="outline-primary"
                                              className="vendor-action-button"
                                              onClick={() =>
                                                setShowInvoiceNumberCanvas(true)
                                              }
                                            >
                                              Assign Invoice Number
                                            </Button>
                                          )}

                                          {consignmentInvoiceItem.invoice_upload_status ==
                                            "NOT-UPLOADED" && (
                                            <Button
                                              variant="outline-primary"
                                              className="vendor-action-button"
                                              onClick={() =>
                                                setShowUploadInvoiceCanvas(true)
                                              }
                                            >
                                              Upload Invoice
                                            </Button>
                                          )}

                                          <Button
                                            variant="outline-primary"
                                            className="vendor-action-button"
                                          >
                                            Delete this Invoice Request
                                          </Button>

                                          <Button
                                            variant="outline-primary"
                                            className="vendor-action-button"
                                          >
                                            Cancel Invoice
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                    <div className="downloadables-container">
                                      {consignmentInvoiceItem.invoice_number_status ==
                                        "ASSIGNED" && (
                                        <div className="vendor-action-buttons-container incoming-purchase-order-invoice-details-table-container">
                                          <Table
                                            bordered
                                            hover
                                            size="sm"
                                            className="table-auto-width"
                                          >
                                            <tbody>
                                              <tr className="catalogues-search-bar-row">
                                                <td
                                                  colSpan={2}
                                                  className="centered-heading"
                                                >
                                                  Prepared Downloadables
                                                </td>
                                              </tr>
                                              <tr className="catalogues-search-bar-row">
                                                <td className="nowrap">
                                                  Invoice Data (.csv)
                                                </td>
                                                <td className="centered">
                                                  <Download
                                                    size={15}
                                                    color="#007bff"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      downloadInvoiceData(
                                                        consignmentInvoiceItem
                                                      )
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                              <tr className="catalogues-search-bar-row">
                                                <td className="nowrap">
                                                  Box Labels (.pdf)
                                                </td>
                                                <td className="centered">
                                                  <Download
                                                    size={15}
                                                    color="#007bff"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      downloadBoxLabels(
                                                        consignmentInvoiceItem
                                                      )
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                              <tr className="catalogues-search-bar-row">
                                                <td className="nowrap">
                                                  Packing List (.csv)
                                                </td>
                                                <td className="centered">
                                                  <Download
                                                    size={15}
                                                    color="#007bff"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      downloadPackingListData(
                                                        consignmentInvoiceItem
                                                      )
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                              <tr className="catalogues-search-bar-row">
                                                <td className="nowrap">
                                                  Packing List Box-Wise (.pdf)
                                                </td>
                                                <td className="centered">
                                                  {generatePackingListPDF &&
                                                  currentPurchaseOrder &&
                                                  selectedConsignment &&
                                                  boxLevelData.length > 0 ? (
                                                    <PDFDownloadLink
                                                      document={
                                                        <CompanyPurchaseOrderBoxPackingListPDF
                                                          boxLevelItems={
                                                            boxLevelData
                                                          }
                                                          incomingPurchaseOrder={
                                                            currentPurchaseOrder
                                                          }
                                                          selectedConsignmentInvoiceItem={
                                                            consignmentInvoiceItem
                                                          }
                                                        />
                                                      }
                                                      fileName={
                                                        selectedConsignment
                                                          ? selectedConsignment.consignment_id.toString()
                                                          : "packing-list"
                                                      }
                                                    >
                                                      {({
                                                        blob,
                                                        url,
                                                        loading,
                                                        error,
                                                      }) => (
                                                        <Button
                                                          className="download-packing-list-button-main"
                                                          disabled={loading}
                                                        >
                                                          {loading
                                                            ? "Loading document..."
                                                            : "Download Packing List (BOX Wise)"}
                                                        </Button>
                                                      )}
                                                    </PDFDownloadLink>
                                                  ) : (
                                                    <Download
                                                      size={15}
                                                      color="#007bff"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        donwloadBoxLevelItems(
                                                          consignmentInvoiceItem
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </td>
                                              </tr>
                                              <tr className="catalogues-search-bar-row">
                                                <td className="nowrap">
                                                  Packing List SKU-Wise (.pdf)
                                                </td>
                                                <td className="centered">
                                                  {generateSKUPackingListPDF &&
                                                  currentPurchaseOrder &&
                                                  selectedConsignment &&
                                                  skuLevelData.length > 0 ? (
                                                    <PDFDownloadLink
                                                      document={
                                                        <CompanyPurchaseOrderSKUPackingListPDF
                                                          boxLevelItems={
                                                            boxLevelData
                                                          }
                                                          skuLevelItems={
                                                            skuLevelData
                                                          }
                                                          incomingPurchaseOrder={
                                                            currentPurchaseOrder
                                                          }
                                                          selectedConsignmentInvoiceItem={
                                                            consignmentInvoiceItem
                                                          }
                                                        />
                                                      }
                                                      fileName={
                                                        selectedConsignment
                                                          ? selectedConsignment.consignment_id.toString()
                                                          : "packing-list"
                                                      }
                                                    >
                                                      {({
                                                        blob,
                                                        url,
                                                        loading,
                                                        error,
                                                      }) => (
                                                        <Button
                                                          className="download-packing-list-button-main"
                                                          disabled={loading}
                                                        >
                                                          {loading
                                                            ? "Loading document..."
                                                            : "Download Packing List (SKU Wise)"}
                                                        </Button>
                                                      )}
                                                    </PDFDownloadLink>
                                                  ) : (
                                                    <Download
                                                      size={15}
                                                      color="#007bff"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        donwloadBoxLevelItemsWithSKUpackingListPrep(
                                                          consignmentInvoiceItem
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </div>
                                      )}

                                      {consignmentInvoiceItem.invoice_upload_status ==
                                        "UPLOADED" && (
                                        <div className="vendor-action-buttons-container incoming-purchase-order-invoice-details-table-container">
                                          <div className="po-document-section-heading">
                                            Invoice Files
                                          </div>

                                          <div className="incoming-purchase-order-file-details-table-container mt-3">
                                            <Table
                                              bordered
                                              hover
                                              size="sm"
                                              className="table-auto-width"
                                            >
                                              <thead>
                                                <tr>
                                                  <th>File ID</th>
                                                  <th>Uploaded At</th>
                                                  <th>Uploaded By</th>
                                                  <th>File Name</th>
                                                  <th>Download</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {consignmentInvoiceItem.invoice_files.map(
                                                  (invoice_file, index) => (
                                                    <tr
                                                      key={index}
                                                      className="catalogues-search-bar-row"
                                                    >
                                                      <td className="nowrap">
                                                        {invoice_file.id}
                                                      </td>
                                                      <td>
                                                        {DateTimeUtils.formatDateTime(
                                                          invoice_file.updated_at
                                                        )}
                                                      </td>
                                                      <td>
                                                        {
                                                          invoice_file.file_uploaded_by
                                                        }
                                                      </td>
                                                      <td>
                                                        {invoice_file.file_name}
                                                      </td>
                                                      <td>
                                                        <Download
                                                          size={15}
                                                          color="#007bff"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            downloadFileFromWeb(
                                                              invoice_file.id,
                                                              invoice_file.saved_file_name
                                                            )
                                                          } // Add your download handler here
                                                        />
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </Table>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  }
                )}
              </tbody>
            </Table>
          </div>
        )}
      </div>

      <Offcanvas
        show={showAssignInvoiceNumberCanvas}
        onHide={() => {
          setUseOtherInvoiceNumber(false);
          setShowInvoiceNumberCanvas(false);
        }}
        placement="end"
        className="dispatch-offcanvas"
        backdrop="true"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="dispatch-offcanvas-title">
              Assign Invoice Number
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="invoice-details-container">
            {currentPurchaseOrder && (
              <div className="mb-4">
                <div className="mt-1">
                  Sold Via - {currentPurchaseOrder.sold_via}
                </div>
                <label>
                  Ensure correct invoice number is assigned as per the (sold
                  via) company name.
                </label>
              </div>
            )}

            <label>Add Invoice Number</label>
            <div className="invoice-number mt-2">
              <div className="financial-year-text">{getFinancialYear()}</div>
              <input
                className="invoice-input"
                name="assigned_invoice_number"
                value={fyInvoiceNumber}
                onChange={handleFYinvoiceNumberChange}
                disabled={useOtherInvoiceNumber}
                autoComplete="off"
              />
            </div>

            <div className="checkbox-container-invoice mt-2">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={useOtherInvoiceNumber}
                  onChange={handleCheckboxChange}
                />
                Use Other Invoice Number
              </label>
            </div>

            {useOtherInvoiceNumber && (
              <label className="mt-4">Add Other Invoice Number</label>
            )}
            {useOtherInvoiceNumber && (
              <div className="invoice-number mt-2">
                <input
                  className="invoice-input"
                  name="assigned_other_invoice_number"
                  value={otherFyInvoiceNumber}
                  onChange={handleOtherFYinvoiceNumberChange}
                  autoComplete="off"
                />
              </div>
            )}

            <div className="offcanvas-button-container">
              <button type="button" onClick={submitInvoiceNumber}>
                Submit
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showUploadInvoiceCanvas}
        onHide={() => {
          setUseOtherInvoiceNumber(false);
          setShowUploadInvoiceCanvas(false);
        }}
        placement="end"
        className="dispatch-offcanvas"
        backdrop="true"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="dispatch-offcanvas-title">Upload Invoice</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="invoice-details-container">
            {currentPurchaseOrder && consignmentInvoiceItems.length > 0 && (
              <div className="mb-4">
                <div className="container-invoice-upload-section">
                  <label>Sold Via - {currentPurchaseOrder.sold_via}</label>
                  <label>
                    Sold To - {currentPurchaseOrder.company.company_name}
                  </label>
                  <label>
                    <b>
                      Invoice Number -{" "}
                      {
                        consignmentInvoiceItems[expandedRow ? expandedRow : 0]
                          .invoice_number
                      }
                    </b>
                  </label>
                  <label>
                    Buyer GSTIN - {currentPurchaseOrder.buyer_gstin}
                  </label>
                </div>
              </div>
            )}

            <div className="upload-invoice-container">
              <span>
                Maximum 1 file is allowed. File size should not exceed 2MB.
                Please ensure, both e-invoice and e-way bill is present in the
                pdf
              </span>{" "}
              <input
                ref={fileInputRef}
                className="mt-4"
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                disabled={files.length == 1}
                // multiple
              />
            </div>

            {files.length > 0 && (
              <div className="upload-invoice-container">
                <div>Selected Files:</div>
                <ul>
                  {files.map((file, index) => (
                    <li key={index} style={{ padding: "4px" }}>
                      {file.name}
                      <XCircleFill
                        size={15}
                        color="#007bff"
                        style={{ cursor: "pointer", marginLeft: "8px" }}
                        onClick={() => handleFileRemove(index)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="offcanvas-button-container">
              <button type="button" onClick={uploadInvoice}>
                Submit
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default CompanyPurchaseOrdersConsignmentDocuments;
