import React, { useEffect, useState, ChangeEvent, KeyboardEvent } from "react";
import {
  Button,
  Modal,
  Offcanvas,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import "./CSS/CompanyPurchaseOrderConsignmentDetails.css";

import {
  Catalogue,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  Consignment,
  ConsignmentAnalyticsObject,
  ConsignmentItems,
  ConsignmentItemsBoxView,
  ConsignmentItemsPOView,
  ConsignmentItemsSKUView,
  PoDocument,
  PurchaseOrder,
  PurchaseOrderDemandObject,
  SearchParams,
  ConsignmentDetailed,
  FilterItem,
  Company,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrdersAllPurchaseOrdersSearchBar from "./CompanyPurchaseOrdersAllPurchaseOrdersSearchBar";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Download, Filter, FilterSquareFill } from "react-bootstrap-icons";
import jsonexport from "jsonexport";
import Papa from "papaparse";
import _ from "lodash";
import { ElevatorSharp } from "@mui/icons-material";
import EscapeKeyListener from "../EscapeKeyListener";

interface RouteParams {
  po_id: string;
}

function CompanyPurchaseOrderConsignmentsAll() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const { po_id } = useParams<RouteParams>();

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [showBottomLoader, setShowBottomLoader] = useState<boolean>(false);

  const [deliveryLocations, setDeliveryLocations] = useState<[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);

  const [createNewConsignment, setCreateNewConsignment] = useState(false);
  const [currentConsignments, setCurrentConsignments] = useState<
    ConsignmentDetailed[]
  >([]);

  const [deliveryLocationFilterList, setDeliveryLocationFilterList] = useState<
    FilterItem[]
  >([]);
  const [companyFilterList, setCompanyFilterList] = useState<FilterItem[]>([]);

  const [poNumberSearchQueryLocal, setPoNumberSearchQueryLocal] =
    useState<string>("");
  const [poNumberSearchQueryGlobal, setPoNumberSearchQueryGlobal] =
    useState<string>("");

  const [invoiceNumberSearchQueryLocal, setInvoiceNumberSearchQueryLocal] =
    useState<string>("");
  const [invoiceNumberSearchQueryGlobal, setInvoiceNumberSearchQueryGlobal] =
    useState<string>("");

  const [
    deliveryLocationCountCheckedCountLocal,
    setDeliveryLocationCheckedCountLocal,
  ] = useState<number>(0);
  const [
    deliveryLocationCheckedCountGlobal,
    setDeliveryLocationCheckedCountGlobal,
  ] = useState<number>(0);

  const [companyFilterCheckedCountLocal, setCompanyFilterCheckedCountLocal] =
    useState<number>(0);
  const [companyFilterCheckedCountGlobal, setCompanyFilterCheckedCountGlobal] =
    useState<number>(0);

  const [isPoNumberFilterVisible, setIsPoNumberFilterVisible] = useState(false); // State for filter visibility
  const [isInvoiceNumberFilterVisible, setIsInvoiceNumberFilterVisible] =
    useState(false); // State for filter visibility

  const [isDeliveryLocationFilterVisible, setIsDeliveryLocationFilterVisible] =
    useState(false);
  const [isCompaniesFilterVisible, setIsCompaniesFilterVisible] =
    useState(false); // State for filter visibility

  const [finalDeliveryLocationsList, setFinalDeliveryLocationsList] = useState<
    string[]
  >([]);
  const [finalCompanyIdsList, setFinalCompanyIdsList] = useState<number[]>([]);

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);

  const fetchAllConsignmentsPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean,
    requestBody: Record<string, any>
  ) => {
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.fetchAllConsignmentsPaginated(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          100,
          requestBody
        );

      // setCurrentConsignments(response.data);
      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setCurrentConsignments((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);

      setShowLoadingPage(false);
      setShowBottomLoader(false);
    } catch (error) {
      console.error("Error in creating new Consignment : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
      setShowBottomLoader(false);
    }
  };

  const handleInvoiceNumberKeyPress = (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      setInvoiceNumberSearchQueryGlobal(invoiceNumberSearchQueryLocal);
      handleSearchWithInvoiceNumber(invoiceNumberSearchQueryLocal);
      setIsInvoiceNumberFilterVisible(false);
    }
  };

  const handlePoNumberKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setPoNumberSearchQueryGlobal(poNumberSearchQueryLocal);
      handleSearchWithPoNumber(poNumberSearchQueryLocal);
      setIsPoNumberFilterVisible(false);
    }
  };

  const handleCheckBoxChangeAtDeliveryLocationFilterIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked)
      setDeliveryLocationCheckedCountLocal(
        deliveryLocationCountCheckedCountLocal + 1
      );
    else {
      setDeliveryLocationCheckedCountLocal(
        deliveryLocationCountCheckedCountLocal - 1
      );
      // if (checkedCount - 1 == 0 && isCheckedSelectAll) {
      //   setIsCheckedSelectAll(false);
      // }
    }

    setDeliveryLocationFilterList((prevItems) =>
      prevItems.map((item, i) =>
        i == index
          ? { ...item, ["is_selected_local"]: !item.is_selected_local }
          : item
      )
    );
  };

  const handleCheckBoxChangeAtCompanyFilterIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked)
      setCompanyFilterCheckedCountLocal(companyFilterCheckedCountLocal + 1);
    else {
      setCompanyFilterCheckedCountLocal(companyFilterCheckedCountLocal - 1);
      // if (checkedCount - 1 == 0 && isCheckedSelectAll) {
      //   setIsCheckedSelectAll(false);
      // }
    }

    setCompanyFilterList((prevItems) =>
      prevItems.map((item, i) =>
        i == index
          ? { ...item, ["is_selected_local"]: !item.is_selected_local }
          : item
      )
    );
  };

  const handleSearchWithInvoiceNumber = (query: string) => {
    const requestBody: Record<string, any> = {};
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["invoiceNumber"] = query;
    requestBody["companyIds"] = finalCompanyIdsList;
    requestBody["deliveryLocations"] = finalDeliveryLocationsList;

    setCurrentConsignments([]);
    setShowBottomLoader(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    fetchAllConsignmentsPaginated(query, 1, true, requestBody);
  };

  const handleInvoiceNumberInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInvoiceNumberSearchQueryLocal(e.target.value);
  };

  const handlePoNumberInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPoNumberSearchQueryLocal(e.target.value);
  };

  const handleSearchWithPoNumber = (query: string) => {
    const requestBody: Record<string, any> = {};
    requestBody["poNumber"] = query;
    requestBody["invoiceNumber"] = invoiceNumberSearchQueryGlobal;
    requestBody["companyIds"] = finalCompanyIdsList;
    requestBody["deliveryLocations"] = finalDeliveryLocationsList;

    setCurrentConsignments([]);
    setShowBottomLoader(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    fetchAllConsignmentsPaginated(query, 1, true, requestBody);
  };

  const handleSearchWithCompanyFilters = (
    query: string,
    companyIds: number[]
  ) => {
    const requestBody: Record<string, any> = {};
    // requestBody["expiryDates"] = finalExpiryDatesList;
    // requestBody["poStatuses"] = finalPoStatusList;
    // requestBody["poTypes"] = finalPOTypesList;
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["invoiceNumber"] = invoiceNumberSearchQueryGlobal;
    requestBody["companyIds"] = companyIds;
    requestBody["deliveryLocations"] = finalDeliveryLocationsList;

    setCurrentConsignments([]);
    setShowBottomLoader(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    fetchAllConsignmentsPaginated(query, 1, true, requestBody);
  };

  const handleSearchWithDeliveryLocationFilters = (
    query: string,
    localDeliveryLocations: string[]
  ) => {
    const requestBody: Record<string, any> = {};
    // requestBody["expiryDates"] = finalExpiryDatesList;
    // requestBody["poStatuses"] = finalPoStatusList;
    // requestBody["poTypes"] = finalPOTypesList;
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["invoiceNumber"] = invoiceNumberSearchQueryGlobal;
    requestBody["companyIds"] = finalCompanyIdsList;
    requestBody["deliveryLocations"] = localDeliveryLocations;

    setCurrentConsignments([]);
    setShowBottomLoader(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    fetchAllConsignmentsPaginated(query, 1, true, requestBody);
  };

  const convertCompaniesToCompanyTypeFilterList = (
    localCompanies: Company[]
  ) => {
    return localCompanies.map((item) => ({
      filter_id: item.company_id,
      filter_string: item.company_name,
      is_selected_local: false,
      is_selected_global: false,
    }));
  };

  const convertDeliveryLocationsToDeliveryLocationFilterList = (
    locations: []
  ) => {
    return locations.map((item) => ({
      filter_id: 0,
      filter_string: item,
      is_selected_local: false,
      is_selected_global: false,
    }));
  };

  const clearInvoiceNumberFilter = () => {
    // setDeliveryLocationFilterList((prevItems) =>
    //   prevItems.map((item) => ({
    //     ...item, // Spread the existing properties of the object
    //     is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
    //   }))
    // );

    setIsInvoiceNumberFilterVisible(false);
    setInvoiceNumberSearchQueryLocal(invoiceNumberSearchQueryGlobal);
  };

  const clearPoNumberFilter = () => {
    // setDeliveryLocationFilterList((prevItems) =>
    //   prevItems.map((item) => ({
    //     ...item, // Spread the existing properties of the object
    //     is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
    //   }))
    // );

    setIsPoNumberFilterVisible(false);
    setPoNumberSearchQueryLocal(poNumberSearchQueryGlobal);
  };

  const clearCompaniesFilter = () => {
    setCompanyFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setIsCompaniesFilterVisible(false); // Close the dropdown
    setCompanyFilterCheckedCountLocal(companyFilterCheckedCountGlobal);
  };

  const clearDeliveryLocationFilter = () => {
    setDeliveryLocationFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setIsDeliveryLocationFilterVisible(false); // Close the dropdown
    setDeliveryLocationCheckedCountLocal(deliveryLocationCheckedCountGlobal);
  };

  const resetInvoiceNumberFilter = () => {
    setIsInvoiceNumberFilterVisible(false);
    setInvoiceNumberSearchQueryLocal("");
    setInvoiceNumberSearchQueryGlobal("");
    handleSearchWithInvoiceNumber("");
  };

  const resetPoNumberFilter = () => {
    setIsPoNumberFilterVisible(false);
    setPoNumberSearchQueryLocal("");
    setPoNumberSearchQueryGlobal("");
    handleSearchWithPoNumber("");
  };

  const resetCompanyNameFilter = () => {
    setIsCompaniesFilterVisible(false);
    setCompanyFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: false, // Update `is_selected_local` with the value of `is_selected_global`
        is_selected_global: false, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setCompanyFilterCheckedCountLocal(0);
    setCompanyFilterCheckedCountGlobal(0);
    setFinalCompanyIdsList([]);
    handleSearchWithCompanyFilters(searchParams.search_string, []);
  };

  const resetDeliveryLocationFilter = () => {
    setIsDeliveryLocationFilterVisible(false);
    setDeliveryLocationFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: false, // Update `is_selected_local` with the value of `is_selected_global`
        is_selected_global: false, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setDeliveryLocationCheckedCountGlobal(0);
    setDeliveryLocationCheckedCountLocal(0);
    setFinalDeliveryLocationsList([]);
    handleSearchWithDeliveryLocationFilters(searchParams.search_string, []);
  };

  const applyInvoiceNumberFilter = () => {
    setInvoiceNumberSearchQueryGlobal(invoiceNumberSearchQueryLocal);
    handleSearchWithInvoiceNumber(invoiceNumberSearchQueryLocal);
    setIsInvoiceNumberFilterVisible(false);
  };

  const applyPONumberFilter = () => {
    setPoNumberSearchQueryGlobal(poNumberSearchQueryLocal);
    handleSearchWithPoNumber(poNumberSearchQueryLocal);
    setIsPoNumberFilterVisible(false);
  };

  const applyCompanyTypeFilter = () => {
    const companyIds = companyFilterList
      .filter((item) => item.is_selected_local)
      .map((item) => item.filter_id);
    setFinalCompanyIdsList(companyIds);

    setCompanyFilterCheckedCountGlobal(companyFilterCheckedCountLocal);
    setCompanyFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_global: item.is_selected_local, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    handleSearchWithCompanyFilters(searchParams.search_string, companyIds);
    setIsCompaniesFilterVisible(false); // Close the dropdown
  };

  const applyDeliveryLocationFilter = () => {
    const deliveryLocations = deliveryLocationFilterList
      .filter((item) => item.is_selected_local)
      .map((item) => item.filter_string);

    setFinalDeliveryLocationsList(deliveryLocations);
    setDeliveryLocationCheckedCountGlobal(
      deliveryLocationCountCheckedCountLocal
    );
    setDeliveryLocationFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_global: item.is_selected_local, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    handleSearchWithDeliveryLocationFilters(
      searchParams.search_string,
      deliveryLocations
    );
    setIsDeliveryLocationFilterVisible(false); // Close the dropdown
  };

  const getClassNameForConsignment = (consignment_status: string) => {
    if (consignment_status == "OPEN") return "color-green";
    else if (consignment_status == "CLOSED") return "color-red";
    else if (consignment_status == "MARKED_RTD") return "color-pink";
    return "";
  };

  const getClassNameForInvoiceNumberStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "NOT-ASSIGNED") return "color-red";
    else if (invoice_number_status == "ASSIGNED") return "color-green";
    return "";
  };
  const getClassNameForInvoiceUploadStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "NOT-UPLOADED") return "color-red";
    else if (invoice_number_status == "UPLOADED") return "color-green";
    return "";
  };

  const loadMore = () => {
    setShowBottomLoader(true);
    const requestBody: Record<string, any> = {};
    // requestBody["expiryDates"] = finalExpiryDatesList;
    // requestBody["poStatuses"] = finalPoStatusList;
    // requestBody["poTypes"] = finalPOTypesList;
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["invoiceNumber"] = invoiceNumberSearchQueryGlobal;
    requestBody["companyIds"] = finalCompanyIdsList;
    requestBody["deliveryLocations"] = finalDeliveryLocationsList;
    fetchAllConsignmentsPaginated(
      "",
      searchParams.current_page_num + 1,
      false,
      requestBody
    );
    // setSearchParams((prev) => {
    //   return {
    //     ...prev,
    //     current_page_num: prev.current_page_num + 1,
    //   };
    // });
  };

  useEffect(() => {
    const fetchAllActiveCompanies = async () => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.getAllActiveCompanies();
        setCompanies(response.data);
        setCompanyFilterList(
          convertCompaniesToCompanyTypeFilterList(response.data)
        );
      } catch (error) {
        console.error("Error in fetching all active companies", error);
      }
    };
    const fetchAllUniqueDeliveryLocations = async () => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.getAllUniqueDeliveryLocations();
        setDeliveryLocations(response.data);
        setDeliveryLocationFilterList(
          convertDeliveryLocationsToDeliveryLocationFilterList(response.data)
        );
      } catch (error) {
        console.error("Error in fetching all active companies", error);
      }
    };

    setShowLoadingPage(true);
    fetchAllActiveCompanies();
    fetchAllUniqueDeliveryLocations();
    const requestBody: Record<string, any> = {};
    // requestBody["expiryDates"] = finalExpiryDatesList;
    // requestBody["poStatuses"] = finalPoStatusList;
    // requestBody["poTypes"] = finalPOTypesList;
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["invoiceNumber"] = invoiceNumberSearchQueryGlobal;
    requestBody["companyIds"] = finalCompanyIdsList;
    requestBody["deliveryLocations"] = finalDeliveryLocationsList;
    fetchAllConsignmentsPaginated("", 1, false, requestBody);
  }, []);

  return (
    <div className="consignments-data-container">
      {currentConsignments && (
        <div className="vendor-action-buttons-container">
          <div className="consignments-count-container">
            Showing {searchParams.item_count} of{" "}
            {searchParams.expected_item_count} consignment(s).
          </div>

          <div className="labels-master-data-table-container">
            <div className="all-labels-master-data-table">
              <Table bordered hover size="sm" className="table-auto-width">
                <thead>
                  <tr>
                    <th>Consignment ID</th>
                    <th>
                      Company Name
                      {companyFilterCheckedCountGlobal == 0 ? (
                        <Filter
                          className="filter-option ml-1"
                          onClick={() => setIsCompaniesFilterVisible(true)}
                        ></Filter>
                      ) : (
                        <FilterSquareFill
                          className="filter-option ml-1"
                          style={{ color: "green" }}
                          onClick={() => setIsCompaniesFilterVisible(true)}
                        ></FilterSquareFill>
                      )}
                      {isCompaniesFilterVisible && (
                        <EscapeKeyListener onEscape={clearCompaniesFilter}>
                          <div className="filter-dropdown">
                            <div className="empty-space"></div>
                            <div className="filter-list-main">
                              {companyFilterList.map(
                                (filterListItem, index) => (
                                  <div key={index} className="filter-item">
                                    <input
                                      className="filter-check-box mr-1"
                                      type="checkbox"
                                      checked={filterListItem.is_selected_local}
                                      onChange={(e) =>
                                        handleCheckBoxChangeAtCompanyFilterIndex(
                                          e,
                                          index
                                        )
                                      }
                                    />
                                    {filterListItem.filter_string}
                                  </div>
                                )
                              )}
                            </div>
                            <div className="filter-buttons-section">
                              {/* {poTypeFilterCheckedCount > 0} */}
                              {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={resetCompanyNameFilter}
                              >
                                Reset
                              </Button>
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={clearCompaniesFilter}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                className="filters-button"
                                disabled={companyFilterCheckedCountLocal == 0}
                                onClick={applyCompanyTypeFilter}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        </EscapeKeyListener>
                      )}
                    </th>
                    <th>
                      Location
                      {deliveryLocationCheckedCountGlobal == 0 ? (
                        <Filter
                          className="filter-option ml-1"
                          onClick={() =>
                            setIsDeliveryLocationFilterVisible(true)
                          }
                        ></Filter>
                      ) : (
                        <FilterSquareFill
                          className="filter-option ml-1"
                          style={{ color: "green" }}
                          onClick={() =>
                            setIsDeliveryLocationFilterVisible(true)
                          }
                        ></FilterSquareFill>
                      )}
                      {isDeliveryLocationFilterVisible && (
                        <EscapeKeyListener
                          onEscape={clearDeliveryLocationFilter}
                        >
                          <div className="filter-dropdown">
                            <div className="empty-space"></div>
                            <div className="filter-list-main">
                              {deliveryLocationFilterList.map(
                                (filterListItem, index) => (
                                  <div key={index} className="filter-item">
                                    <input
                                      className="filter-check-box mr-1"
                                      type="checkbox"
                                      checked={filterListItem.is_selected_local}
                                      onChange={(e) =>
                                        handleCheckBoxChangeAtDeliveryLocationFilterIndex(
                                          e,
                                          index
                                        )
                                      }
                                    />
                                    {filterListItem.filter_string}
                                  </div>
                                )
                              )}
                            </div>
                            <div className="filter-buttons-section">
                              {/* {poTypeFilterCheckedCount > 0} */}
                              {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={resetDeliveryLocationFilter}
                              >
                                Reset
                              </Button>
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={clearDeliveryLocationFilter}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                className="filters-button"
                                disabled={
                                  deliveryLocationCountCheckedCountLocal == 0
                                }
                                onClick={applyDeliveryLocationFilter}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        </EscapeKeyListener>
                      )}
                    </th>
                    <th>Sold Via</th>
                    <th>
                      PO Number
                      {poNumberSearchQueryGlobal.length == 0 ? (
                        <Filter
                          className="filter-option ml-1"
                          onClick={() => setIsPoNumberFilterVisible(true)}
                        ></Filter>
                      ) : (
                        <FilterSquareFill
                          className="filter-option ml-1"
                          style={{ color: "green" }}
                          onClick={() => setIsPoNumberFilterVisible(true)}
                        ></FilterSquareFill>
                      )}
                      {isPoNumberFilterVisible && (
                        <EscapeKeyListener onEscape={clearPoNumberFilter}>
                          <div className="filter-dropdown">
                            <div className="empty-space"></div>
                            <div className="filter-list-main ml-2 mr-2">
                              <input
                                type="text"
                                placeholder="Search PO Number..."
                                value={poNumberSearchQueryLocal}
                                onChange={handlePoNumberInputChange}
                                onKeyPress={handlePoNumberKeyPress}
                              />
                            </div>
                            <div className="filter-buttons-section mt-2 ml-2 mr-2">
                              {/* {poTypeFilterCheckedCount > 0} */}
                              {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}

                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={resetPoNumberFilter}
                              >
                                Reset
                              </Button>
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={clearPoNumberFilter}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                className="filters-button"
                                disabled={poNumberSearchQueryLocal.length == 0}
                                onClick={applyPONumberFilter}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        </EscapeKeyListener>
                      )}
                    </th>
                    <th>PO Type</th>
                    <th>Consignment Status</th>
                    <th>Invoice Number Status</th>
                    <th>
                      Invoice Number
                      {invoiceNumberSearchQueryGlobal.length == 0 ? (
                        <Filter
                          className="filter-option ml-1"
                          onClick={() => setIsInvoiceNumberFilterVisible(true)}
                        ></Filter>
                      ) : (
                        <FilterSquareFill
                          className="filter-option ml-1"
                          style={{ color: "green" }}
                          onClick={() => setIsInvoiceNumberFilterVisible(true)}
                        ></FilterSquareFill>
                      )}
                      {isInvoiceNumberFilterVisible && (
                        <EscapeKeyListener onEscape={clearInvoiceNumberFilter}>
                          <div className="filter-dropdown">
                            <div className="empty-space"></div>
                            <div className="filter-list-main ml-2 mr-2">
                              <input
                                type="text"
                                placeholder="Search Invoice Number..."
                                value={invoiceNumberSearchQueryLocal}
                                onChange={handleInvoiceNumberInputChange}
                                onKeyPress={handleInvoiceNumberKeyPress}
                              />
                            </div>
                            <div className="filter-buttons-section mt-2 ml-2 mr-2">
                              {/* {poTypeFilterCheckedCount > 0} */}
                              {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}

                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={resetInvoiceNumberFilter}
                              >
                                Reset
                              </Button>
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={clearInvoiceNumberFilter}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                className="filters-button"
                                disabled={
                                  invoiceNumberSearchQueryLocal.length == 0
                                }
                                onClick={applyInvoiceNumberFilter}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        </EscapeKeyListener>
                      )}
                    </th>
                    <th>Invoice Upload Status</th>
                    <th>Boxes Count</th>
                    <th>Sku Count</th>
                    <th>Total Quantity</th>
                    <th>Transporter Name</th>
                    <th>Vehicle Number/Docket Number</th>
                    <th>Consignment Created At</th>
                    <th>Consignment Marked RTD At</th>
                    <th>Marked RTD By</th>
                  </tr>
                </thead>
                <tbody>
                  {currentConsignments.map((Consignment, index) => {
                    return (
                      <tr className="catalogues-search-bar-row">
                        <td className="po-id-link">
                          <a
                            href={`/incomingPos/info/consignmentDetails/${Consignment.po_number}/${Consignment.consignment_id}/documents`}
                          >
                            {Consignment.consignment_id}
                          </a>
                        </td>
                        <td>{Consignment.company_name}</td>
                        <td>{Consignment.delivery_city}</td>
                        <td>{Consignment.sold_via}</td>
                        <td>{Consignment.po_number}</td>
                        <td>{Consignment.po_type}</td>
                        <td
                          className={getClassNameForConsignment(
                            Consignment.consignment_status
                          )}
                        >
                          {Consignment.consignment_status}
                        </td>
                        <td
                          className={getClassNameForInvoiceNumberStatus(
                            Consignment.invoice_number_status
                          )}
                        >
                          {Consignment.invoice_number_status}
                        </td>
                        <td>{Consignment.invoice_number}</td>

                        <td
                          className={getClassNameForInvoiceUploadStatus(
                            Consignment.invoice_upload_status
                          )}
                        >
                          {Consignment.invoice_number_status == "NOT-ASSIGNED"
                            ? ""
                            : Consignment.invoice_upload_status}
                        </td>

                        <td>{Consignment.total_boxes}</td>
                        <td>{Consignment.total_skus}</td>
                        <td>{Consignment.total_quantity}</td>
                        <td>{Consignment.transporter_name}</td>
                        <td>{Consignment.vehicle_number}</td>

                        <td>
                          {DateTimeUtils.formatDateTime(Consignment.created_at)}
                        </td>

                        <td>
                          {DateTimeUtils.formatDateTime(
                            Consignment.marked_rtd_at
                          )}
                        </td>

                        <td>{Consignment.marked_rtd_by}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {!showBottomLoader && currentConsignments.length == 0 && (
                <div className="nothing-found-container">
                  No consignments were found.
                </div>
              )}
              {searchParams.item_count > 0 &&
                searchParams.item_count < searchParams.expected_item_count &&
                !showBottomLoader && (
                  <div className="load-more-catalogues-button-container">
                    <Button
                      variant="outline-primary"
                      className="load-more-consignments-button"
                      onClick={() => loadMore()}
                    >
                      Load More..
                    </Button>
                  </div>
                )}
              {showBottomLoader && (
                <div className="bottom-progress-container">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}

      {showDownloadPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
          Please wait, While your file is being downloaded...
        </div>
      )}
    </div>
  );
}

export default CompanyPurchaseOrderConsignmentsAll;
