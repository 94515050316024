import React, { useEffect, useState } from "react";

import "./CSS/VendorsHome.css";

import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";
import VendorsAll from "./VendorsAll";
import VendorsAdd from "./VendorsAdd";
import PurchaseOrdersOpen from "./PurchaseOrdersOpen";
import PurchaseOrdersListingView from "./PurchaseOrdersListingView";
import PurchaseOrdersAdd from "./PurchaseOrdersAdd";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
}

function VendorHome() {
  const dispatch: AppDispatch = useDispatch();
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  const history = useHistory();
  useEffect(() => {
    // if (!userDetailsFromStore.authorities.includes("AUTH_ADMIN")) {
    //   history.push(`/listings/all`);
    // }
    dispatch(ActiveTab__Action("vendor-hub"));
  }, []);

  const { category } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(category);
  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    history.push(`/vendor-hub/${item}`);
  };

  useEffect(() => {
    setSelectedItem(category);
  }, [category]);

  return (
    <div>
      <div className="nav-container">
        <nav className="navbar-vendors">
          <ul className="navbar-menu">
            <li
              className={`navbar-item ${
                selectedItem === "all-vendors" ? "active" : ""
              }`}
              onClick={() => handleItemClick("all-vendors")}
            >
              All Vendors
            </li>
            <li
              className={`navbar-item ${
                selectedItem === "all-purchase-orders" ? "active" : ""
              }`}
              onClick={() => handleItemClick("all-purchase-orders")}
            >
              All purchase orders
            </li>
            <li
              className={`navbar-item ${
                selectedItem === "po-listing-view" ? "active" : ""
              }`}
              onClick={() => handleItemClick("po-listing-view")}
            >
              SKU Wise View
            </li>
            <li
              className={`navbar-item ${
                selectedItem === "add-purchase-order" ? "active" : ""
              }`}
              onClick={() => handleItemClick("add-purchase-order")}
            >
              Create New Purchase order
            </li>
          </ul>
        </nav>
      </div>

      {selectedItem == "all-vendors" && <VendorsAll />}
      {selectedItem == "all-purchase-orders" && <PurchaseOrdersOpen />}
      {selectedItem == "po-listing-view" && <PurchaseOrdersListingView />}
      {selectedItem == "add-purchase-order" && <PurchaseOrdersAdd />}
    </div>
  );
}

export default VendorHome;
