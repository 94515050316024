import React, { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import {
  Button,
  Modal,
  Offcanvas,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import "./CSS/LabelsMasterDataPage.css";

import {
  Catalogue,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  Consignment,
  ConsignmentAnalyticsObject,
  ConsignmentItems,
  ConsignmentItemsBoxView,
  ConsignmentItemsPOView,
  ConsignmentItemsSKUView,
  PoDocument,
  PurchaseOrder,
  PurchaseOrderDemandObject,
  SearchParams,
  ConsignmentDetailed,
  SecondarySkuCompanySkuRelationItem,
  LabelsMasterDataItem,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/LabelsNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Download, Filter, FilterSquareFill } from "react-bootstrap-icons";
import jsonexport from "jsonexport";
import Papa from "papaparse";
import _ from "lodash";
import { ElevatorSharp } from "@mui/icons-material";
import LabelsNetworkUtils from "./NetworkUtils/LabelsNetworkUtils";
import EscapeKeyListener from "../EscapeKeyListener";

interface RouteParams {
  po_id: string;
}

function LabelsMasterDataPage() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const labelsNetworkUtil = new LabelsNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [showBottomLoader, setShowBottomLoader] = useState<boolean>(false);
  const [labelsMasterDataItemList, setLabelsMasterDataItemList] = useState<
    LabelsMasterDataItem[]
  >([]);

  const [searchQuerySecondarySkuLocal, setSearchQuerySecondarySkuLocal] =
    useState<string>("");
  const [searchQuerySecondarySkuGlobal, setSearchQuerySecondarySkuGlobal] =
    useState<string>("");
  const [isSecondarySkuFilterVisible, setIsSecondarySkuFilterVisible] =
    useState(false); // State for filter visibility

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);

  const fetchLabelsMasterDataPaginated = async (
    pageNumber: number,
    requestBody: Record<string, any>
  ) => {
    try {
      const response = await labelsNetworkUtil.fetchLabelsMasterDataPaginated(
        pageNumber,
        100,
        requestBody
      );

      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setLabelsMasterDataItemList((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);

      setShowLoadingPage(false);
      setShowBottomLoader(false);
    } catch (error) {
      console.error("Error in creating new Consignment : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowBottomLoader(false);
      setShowLoadingPage(false);
    }
  };

  const clearSecondarySkuFilter = () => {
    // setDeliveryLocationFilterList((prevItems) =>
    //   prevItems.map((item) => ({
    //     ...item, // Spread the existing properties of the object
    //     is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
    //   }))
    // );

    setIsSecondarySkuFilterVisible(false);
    setSearchQuerySecondarySkuLocal(searchQuerySecondarySkuGlobal);
  };

  const applySecondarySkuFilter = () => {
    setSearchQuerySecondarySkuGlobal(searchQuerySecondarySkuLocal);
    handleSearchWithSecondarySkuFilter(searchQuerySecondarySkuLocal);
    setIsSecondarySkuFilterVisible(false);
  };

  const handleSearchWithSecondarySkuFilter = (
    secondarySkuSearchQuery: string
  ) => {
    const requestBody: Record<string, any> = {};
    requestBody["secondarySku"] = secondarySkuSearchQuery;

    setLabelsMasterDataItemList([]);
    setShowBottomLoader(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
      };
    });

    fetchLabelsMasterDataPaginated(1, requestBody);
  };

  const handleSecondarySkuInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuerySecondarySkuLocal(e.target.value);
  };

  const handleSecondarySkuKeyPress = (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      setSearchQuerySecondarySkuGlobal(searchQuerySecondarySkuLocal);
      handleSearchWithSecondarySkuFilter(searchQuerySecondarySkuLocal);
      setIsSecondarySkuFilterVisible(false);
    }
  };

  const resetSecondarySkuFilter = () => {
    setIsSecondarySkuFilterVisible(false);
    setSearchQuerySecondarySkuLocal("");
    setSearchQuerySecondarySkuGlobal("");
    handleSearchWithSecondarySkuFilter("");
  };

  const getClassNameForConsignment = (consignment_status: string) => {
    if (consignment_status == "OPEN") return "color-green";
    else if (consignment_status == "CLOSED") return "color-red";
    else if (consignment_status == "MARKED_RTD") return "color-pink";
    return "";
  };

  const getClassNameForInvoiceNumberStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "NOT-ASSIGNED") return "color-red";
    else if (invoice_number_status == "ASSIGNED") return "color-green";
    return "";
  };
  const getClassNameForInvoiceUploadStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "NOT-UPLOADED") return "color-red";
    else if (invoice_number_status == "UPLOADED") return "color-green";
    return "";
  };

  const loadMore = () => {
    setShowBottomLoader(true);
    const requestBody: Record<string, any> = {};
    requestBody["secondarySku"] = searchQuerySecondarySkuGlobal;
    fetchLabelsMasterDataPaginated(
      searchParams.current_page_num + 1,
      requestBody
    );
    // setSearchParams((prev) => {
    //   return {
    //     ...prev,
    //     current_page_num: prev.current_page_num + 1,
    //   };
    // });
  };

  useEffect(() => {
    setShowLoadingPage(true);
    const requestBody: Record<string, any> = {};
    fetchLabelsMasterDataPaginated(1, requestBody);
  }, []);

  return (
    // <div className="all-consignments-container">
    <div className="labels-master-data-container">
      {labelsMasterDataItemList && (
        <div className="vendor-action-buttons-container">
          {labelsMasterDataItemList.length > 0 && (
            <div className="labels-master-data-count-container">
              Showing {searchParams.item_count} of{" "}
              {searchParams.expected_item_count} relation(s).
            </div>
          )}

          <div className="labels-master-data-table-container">
            <div className="all-labels-master-data-table">
              <Table
                bordered
                hover
                size="sm"
                className="table-auto-width data-table"
              >
                <thead>
                  <tr>
                    <th>
                      Secondary SKU
                      {searchQuerySecondarySkuGlobal.length == 0 ? (
                        <Filter
                          className="filter-option ml-1"
                          onClick={() => setIsSecondarySkuFilterVisible(true)}
                        ></Filter>
                      ) : (
                        <FilterSquareFill
                          className="filter-option ml-1"
                          style={{ color: "green" }}
                          onClick={() => setIsSecondarySkuFilterVisible(true)}
                        ></FilterSquareFill>
                      )}
                      {isSecondarySkuFilterVisible && (
                        <EscapeKeyListener onEscape={clearSecondarySkuFilter}>
                          <div className="filter-dropdown">
                            <div className="empty-space"></div>
                            <div className="filter-list-main ml-2 mr-2">
                              <input
                                type="text"
                                placeholder="Search Secondary SKU..."
                                value={searchQuerySecondarySkuLocal}
                                onChange={handleSecondarySkuInputChange}
                                onKeyPress={handleSecondarySkuKeyPress}
                              />
                            </div>
                            <div className="filter-buttons-section mt-2 ml-2 mr-2">
                              {/* {poTypeFilterCheckedCount > 0} */}
                              {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}

                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={resetSecondarySkuFilter}
                              >
                                Reset
                              </Button>
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={clearSecondarySkuFilter}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                className="filters-button"
                                disabled={
                                  searchQuerySecondarySkuLocal.length == 0
                                }
                                onClick={applySecondarySkuFilter}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        </EscapeKeyListener>
                      )}
                    </th>
                    <th>EAN Code</th>
                    <th>Size</th>
                    <th>Color</th>
                    <th>One Set Contains</th>
                    <th>Material</th>
                    <th>MRP</th>
                  </tr>
                </thead>
                <tbody>
                  {labelsMasterDataItemList.map((singleItem, index) => {
                    return (
                      <tr className="catalogues-search-bar-row">
                        <td>{singleItem.secondary_sku}</td>
                        <td>{singleItem.ean_code}</td>
                        <td>{singleItem.size}</td>
                        <td>{singleItem.color}</td>
                        <td>{singleItem.one_set_contains}</td>
                        <td>{singleItem.material}</td>
                        <td>{singleItem.mrp}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {!showBottomLoader && labelsMasterDataItemList.length == 0 && (
                <div className="nothing-found-container">
                  No items were found.
                </div>
              )}

              {searchParams.item_count > 0 &&
                searchParams.item_count < searchParams.expected_item_count &&
                !showBottomLoader && (
                  <div className="load-more-catalogues-button-container">
                    <Button
                      variant="outline-primary"
                      className="load-more-consignments-button"
                      onClick={() => loadMore()}
                    >
                      Load More..
                    </Button>
                  </div>
                )}
              {showBottomLoader && (
                <div className="bottom-progress-container">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}
    </div>
  );
}

export default LabelsMasterDataPage;
