import { Bin, ImageHashMapState, Listing } from "../../atomic_components/types";
import { ImageHashMapActionTypes, VendorPurchaseOrderImageHashMapActionTypes } from "../action-types";
import {  ImageHashMapVendorPurchaseOrderAction } from "../actions-interface";



const initialState: ImageHashMapState = {
    imageHashmap: {}
};

const purchaseOrderImageHashMapReducer = (
    state: ImageHashMapState = initialState,
    action: ImageHashMapVendorPurchaseOrderAction
) => {
  switch (action.type) {
    case VendorPurchaseOrderImageHashMapActionTypes.ADD_TO_MAP:
        console.log(state);
        return {
            ...state,
            imageHashmap: {
              ...state.imageHashmap,
              [action.payload.key]: action.payload.value
            }
          };
    case VendorPurchaseOrderImageHashMapActionTypes.RESET_MAP:
        return {
            ...state,
            imageHashmap: {}
          };
    case VendorPurchaseOrderImageHashMapActionTypes.INIT_MAP:
      const newState : ImageHashMapState = {
        imageHashmap: {}
      };
      action.payload.purchaseOrderItemWithListings.forEach((purchaseOrderListingItem) => {
        if(purchaseOrderListingItem.listing.img_wdim && purchaseOrderListingItem.listing.img_wdim.trim() !== "") newState.imageHashmap[purchaseOrderListingItem.listing.sku_id] = 0;
        else if(purchaseOrderListingItem.listing.img_hd && purchaseOrderListingItem.listing.img_hd.trim() !== "") newState.imageHashmap[purchaseOrderListingItem.listing.sku_id] = 1;
        else if(purchaseOrderListingItem.listing.img_white && purchaseOrderListingItem.listing.img_white.trim() !== "") newState.imageHashmap[purchaseOrderListingItem.listing.sku_id] = 2;
        else if(purchaseOrderListingItem.listing.img_link1 && purchaseOrderListingItem.listing.img_link1.trim() !== "") newState.imageHashmap[purchaseOrderListingItem.listing.sku_id] = 3;
        else if(purchaseOrderListingItem.listing.img_link2 && purchaseOrderListingItem.listing.img_link2.trim() !== "") newState.imageHashmap[purchaseOrderListingItem.listing.sku_id] = 4;
        else newState.imageHashmap[purchaseOrderListingItem.listing.sku_id] = 5;
      })
      console.log("Printing new state : ", newState);
      return {
          ...state,
          imageHashmap: newState.imageHashmap
        };
    default:
      return state;
  }
};
export default purchaseOrderImageHashMapReducer;
