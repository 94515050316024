import React, { useEffect, useState } from "react";
import VendorNamesDropdown from "./VendorNamesDropdown";
import VendorsNetworkUtil from "./NetworkUtils/VendorsNetworkUtil";
import { useHistory, useLocation } from "react-router-dom";
import { PurchaseOrder, Vendor } from "../../atomic_components/types";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import Datetime from "react-datetime";
import DatePicker from "react-modern-calendar-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./CSS/PurchaseOrdersAdd.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import PurchaseOrdersPDF from "./PurchaseOrderPDF";
import { set } from "lodash";
import CustomDateTimePicker from "./CustomDateTimePicker";
import PurchaseOrderNetworkUtils from "./NetworkUtils/PurchaseOrderNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorPurchaseOrderDatePicker from "./VendorPurchaseOrderDatePicker";

function PurchaseOrdersAdd() {
  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const purchaseOrdersNetworkUtil = new PurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  useEffect(() => {
    fetchAllVendors();
  }, []);

  const [vendorsList, setVendorsList] = useState<Vendor[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const fetchAllVendors = async () => {
    try {
      const response = await vendorsNetworkUtil.getAllVendors();
      setVendorsList(response.data);
    } catch (error) {
      console.error("Error in fetching vendor listings by vendor id :", error);
    }
  };

  const handleVendorFromDropdownSelected = (value: Vendor) => {
    console.log("Selected Value:", value);
    setSelectedVendor(value);
  };

  const [poRemarks, setPoRemarks] = useState<string>("");

  // Event handler to update the state when the input value changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPoRemarks(e.target.value);
    console.log(e.target.value);
  };

  const [providedSelectedValue, setProvidedSelectedValue] =
    useState<string>("");

  useEffect(() => {
    const urlVendorId = new URLSearchParams(location.search).get("vendorId");
    console.log("Logging vendor Id", urlVendorId);
    if (urlVendorId) {
      vendorsList.forEach((vendor: Vendor) => {
        if (vendor.id == parseInt(urlVendorId)) {
          console.log("inside setting selected Vendor");
          setSelectedVendor(vendor);
          console.log(vendor.vendor_name);
          setProvidedSelectedValue(vendor.vendor_name);
        }
      });
    }
  }, [vendorsList]);

  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null);

  const handleDateTimeChange = (newDateTime: Date | null) => {
    setSelectedDateTime(newDateTime);
  };

  const createPOAndProceed = async () => {
    // history.push(`/purchase-orders/add/addItems`);
    if (selectedVendor && selectedDateTime) {
      // const durationInMilliseconds = (5 * 60 + 30) * 60 * 1000;
      // const newTimestamp = new Date().getTime() - durationInMilliseconds;
      // const currentDateUTC = new Date(newTimestamp);
      const currentDate = new Date();

      if (selectedDateTime <= currentDate) {
        toast.error("Invalid expiry date, Past dates are not supported.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        return;
      }

      const body = {
        po_remarks: poRemarks,
        vendor_id: selectedVendor ? selectedVendor.id : "",
        status: "PENDING",
        created_by: userDetailsFromStore.user_details.userId,
        expected_date: DateTimeUtils.formatDateTimeForDb(selectedDateTime),
      };
      try {
        const response = await purchaseOrdersNetworkUtil.createNewPurchaseOrder(
          body
        );
        const createdPurchaseOrder: PurchaseOrder = response.data;
        history.push(
          `/vendor-hub/all-purchase-orders/add-items/${createdPurchaseOrder.po_id}`
        );
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
      }

      // const url = `/purchase-orders/add/addItems/1`;
      // const win = window.open(url, "_blank");
      // if (win) {
      //   win.focus(); // Focus on the new tab if it opened successfully
      // } else {
      //   console.error("Popup blocked. Please allow popups for this website.");
      // }
    } else {
      toast.error("Vendor or Expiry date not selected", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  return (
    <div className="container mt-4">
      <div className="form-container">
        <div className="dropdown-container mb-2">
          <VendorNamesDropdown
            options={vendorsList}
            onItemSelected={handleVendorFromDropdownSelected}
            providedSelectedValue={providedSelectedValue}
          />
        </div>
        <div className="mt-3">
          <VendorPurchaseOrderDatePicker
            onDateTimeChange={handleDateTimeChange}
          />
        </div>

        <FloatingLabel
          controlId="floatingInput"
          label="PO Remarks (Optional)"
          className="mb-3 mt-3"
        >
          <Form.Control
            type="text"
            value={poRemarks}
            onChange={handleInputChange}
          />
        </FloatingLabel>
        <div className="purchase-order-create-button-container mt-4">
          <Button
            className="purchase-order-create-button"
            variant="outline-primary"
            onClick={createPOAndProceed}
          >
            Submit
          </Button>
        </div>
        {/* <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        placeholderText="Select a date"
      /> */}
      </div>
    </div>
  );
}

export default PurchaseOrdersAdd;
