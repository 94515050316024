import React, { useEffect, useState, KeyboardEvent } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/SecondaryListingsBulkOperations.css";
import { Button, ListGroup, Modal, Spinner, Table } from "react-bootstrap";

import {
  AisListing,
  AisListingAfterUploadResultItem,
  AisListingUploadItem,
  IncomingPurchaseOrder,
  PacksCombosListingAfterUploadResultItem,
  PacksCombosUploadItem,
  SearchParams,
  SecondaryListingAfterUploadResultItem,
  SecondaryListingDetailsObject,
  SecondaryListingUploadItem,
  SecondaryListings,
} from "../../atomic_components/types";
import ListingsView from "./ListingsView";

import _, { update } from "lodash";
import { toast } from "react-toastify";

import { useAppSelector } from "../../state/hooks";
import InventoryNetworkUtils from "./NetworkUtils/InventoryNetworkUtils";
import SecondaryListingsAisSearchbar from "./SecondaryListingsAisSearchbar";
import jsonexport from "jsonexport";
import Papa from "papaparse";

function SecondaryListingsBulkOperations() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [showProgressPage, setShowProgressPage] = useState<boolean>(false);
  const [downloadButtonClicked, setDownloadButtonClicked] = useState(false);
  const [showAISListingUploadModal, setShowAISListingUploadModal] =
    useState(false);
  const [
    showPacksAndCombosListingUploadModal,
    setShowPacksAndCombosUploadModal,
  ] = useState(false);
  const [showSecondaryListingUploadModal, setShowSecondaryListingUploadModal] =
    useState(false);
  const [uploadButtonActive, setUploadButtonActive] = useState(false);
  const [uploadAISlistingsToSecondStep, setUploadAISlistingsToSecondStep] =
    useState(false);
  const [
    uploadSecondarylistingsToSecondStep,
    setUploadSecondarylistingsToSecondStep,
  ] = useState(false);
  const [
    uploadPacksComboslistingsToSecondStep,
    setUploadPacksComboslistingsToSecondStep,
  ] = useState(false);

  const [finalAISlistingsToUpload, setFinalAISlistingsToUpload] = useState<
    AisListingUploadItem[]
  >([]);
  const [finalSecondaryListingsToUpload, setFinalSecondaryListingsToUpload] =
    useState<SecondaryListingUploadItem[]>([]);
  const [
    finalPacksCombosListingsToUpload,
    setFinalPacksCombosListingsToUpload,
  ] = useState<PacksCombosUploadItem[]>([]);
  const [showAISListingAfterUploadResult, setShowAISListingAfterUploadResult] =
    useState<boolean>(false);
  const [
    showSecondaryListingAfterUploadResult,
    setShowSecondaryListingAfterUploadResult,
  ] = useState<boolean>(false);
  const [
    showPacksCombosListingAfterUploadResult,
    setShowPacksCombosListingAfterUploadResult,
  ] = useState<boolean>(false);
  const [aisListingsAfterUploadResult, setAisLisitngsAfterUploadResult] =
    useState<AisListingAfterUploadResultItem[]>([]);
  const [
    secondaryListingsAfterUploadResult,
    setSecondaryLisitngsAfterUploadResult,
  ] = useState<SecondaryListingAfterUploadResultItem[]>([]);

  const [
    packsAndComboListingsAfterUploadResult,
    setPacksAndCombosLisitngsAfterUploadResult,
  ] = useState<PacksCombosListingAfterUploadResultItem[]>([]);

  const inventoryNetworkUtils = new InventoryNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const handleSecondaryListingsFileDownload = async () => {
    try {
      setDownloadButtonClicked(true);
      let total = 0,
        count = -1,
        pageNumber = 1;
      let allListings: SecondaryListings[] = [];

      while (count < total) {
        const response =
          await inventoryNetworkUtils.getSecondaryListingsBySearchKeywordPaginated(
            "",
            pageNumber,
            5000
          );
        if (count == -1) {
          count = response.data.curr_page_count;
          total = response.data.total;
        } else {
          count = count + response.data.curr_page_count;
        }
        pageNumber = pageNumber + 1;
        console.log(count, total, response.data.content);
        allListings.push(...response.data.content);
      }
      console.log(allListings);
      setDownloadButtonClicked(false);
      jsonexport(allListings, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        const blob = new Blob([csv], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `secondary_listings_${Date.now()}.csv`;
        link.click();
      });
    } catch (error) {
      console.log("Printing error from download secondary listings");
      console.error("Error:", error);
      toast.error("Unknown error while downloading secondary listings.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const handlePacksAndCombosListingsFileDownload = async () => {
    try {
      setDownloadButtonClicked(true);
      let total = 0,
        count = -1,
        pageNumber = 1;
      let allListings: SecondaryListings[] = [];

      while (count < total) {
        const response =
          await inventoryNetworkUtils.getRawDownloadablePacksAndCombosPaginated(
            "",
            pageNumber,
            5000
          );
        if (count == -1) {
          count = response.data.curr_page_count;
          total = response.data.total;
        } else {
          count = count + response.data.curr_page_count;
        }
        pageNumber = pageNumber + 1;
        console.log(count, total, response.data.content);
        allListings.push(...response.data.content);
      }
      console.log(allListings);
      setDownloadButtonClicked(false);
      jsonexport(allListings, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        const blob = new Blob([csv], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `packs_combos_listings_${Date.now()}.csv`;
        link.click();
      });
    } catch (error) {
      console.log("Printing error from download packs and combos listings");
      console.error("Error:", error);
      toast.error(
        "Unknown error while downloading packs and combos listings.",
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        }
      );
    }
  };

  const handleAISListingDownload = async () => {
    try {
      setDownloadButtonClicked(true);
      let total = 0,
        count = -1,
        pageNumber = 1;
      let allListings: AisListing[] = [];

      while (count < total) {
        const response =
          await inventoryNetworkUtils.getAisListingsBySearchKeywordPaginated(
            "",
            pageNumber,
            1000
          );
        if (count == -1) {
          count = response.data.curr_page_count;
          total = response.data.total;
        } else {
          count = count + response.data.curr_page_count;
        }
        pageNumber = pageNumber + 1;
        console.log(count, total, response.data.content);
        allListings.push(...response.data.content);
      }
      console.log(allListings);
      setDownloadButtonClicked(false);
      jsonexport(allListings, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        const blob = new Blob([csv], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `ais_listings_${Date.now()}.csv`;
        link.click();
      });
    } catch (error) {
      console.log("Printing error from download ais listings");
      console.error("Error:", error);
      toast.error("Unknown error while downloading ais listings.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const handleSamplAISfileDownload = async () => {
    try {
      jsonexport(
        [
          {
            id: "",
            inventory_sku_id: "",
            ais_quantity: "put updated/new ais quantity here",
            update_add:
              "put update/add in this column, update operation will update the ais_quantity, add operation will add new sku to the list",
          },
        ],
        (err: Error | null, csv: string) => {
          if (err) {
            console.error(err);
            return;
          }
          // Create a Blob object with the CSV data
          const blob = new Blob([csv], { type: "text/csv" });
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `ais-sample-upload-file.csv`;
          link.click();
        }
      );
    } catch (error) {
      console.log("Printing error from handleSampleDownload");
      console.error("Error:", error);
    }
  };

  const handleSamplePacksAndCombosFileDownload = async () => {
    try {
      jsonexport(
        [
          {
            pack_combo_sku_id:
              "put a valid pack sku, that should be part of secondary listings",
            inventory_sku_id:
              "put a valid inventory_sku_id that should be part of app listings",
            sku_count:
              "put valid sku count that tells how many units of this inventory sku contributes to the pack/combo",
            update_add: "put update/add in this column",
          },
        ],
        (err: Error | null, csv: string) => {
          if (err) {
            console.error(err);
            return;
          }
          // Create a Blob object with the CSV data
          const blob = new Blob([csv], { type: "text/csv" });
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `packs-combos-sample-upload-file.csv`;
          link.click();
        }
      );
    } catch (error) {
      console.log("Printing error from handleSampleDownload");
      console.error("Error:", error);
    }
  };

  const handleSampleSecondaryListingsFileDownload = async () => {
    try {
      jsonexport(
        [
          {
            id: "",
            secondary_sku: "",
            master_sku: "",
            inventory_sku_id: "",
            pack_combo_sku_id: "",
            sku_type: "SINGLE/MULTI",
            update_add: "put update/add in this column",
          },
        ],
        (err: Error | null, csv: string) => {
          if (err) {
            console.error(err);
            return;
          }
          // Create a Blob object with the CSV data
          const blob = new Blob([csv], { type: "text/csv" });
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `secondary-listings-sample-upload-file.csv`;
          link.click();
        }
      );
    } catch (error) {
      console.log("Printing error from handleSampleDownload");
      console.error("Error:", error);
    }
  };

  const handleAISFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const expectedHeaders = [
      "id",
      "inventory_sku_id",
      "ais_quantity",
      "update_add",
    ];
    setUploadButtonActive(false);

    if (event.target.files) {
      const selectedFile = event.target.files[0];
      // let jsonPayload: any[] | null = null;
      if (selectedFile) {
        Papa.parse(selectedFile, {
          complete: (result) => {
            const fileHeaders = result.meta.fields;
            if (fileHeaders) {
              const headersMatch =
                expectedHeaders.every((header) =>
                  fileHeaders.includes(header)
                ) &&
                fileHeaders.every((header) => expectedHeaders.includes(header));

              if (!headersMatch) {
                toast.error(
                  "Error: The CSV file headers are incorrect. Please upload a valid file.",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                  }
                );
                return; // Stop further processing
              }

              const validRows = result.data.filter((row: any) => {
                // Check if the row is not empty
                return Object.values(row).some(
                  (value) => value !== null && value !== ""
                );
              });

              if (validRows.length === 0) {
                toast.error(
                  "Error: The CSV file contains no valid rows. Please upload a valid file.",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                  }
                );
                return;
              }

              // If headers match, process the data
              setUploadButtonActive(true);
              mapToUploadAISfileFormat(validRows);
            } else {
              toast.error(
                "Error: The CSV file headers are missing. Please upload a valid file.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  hideProgressBar: true,
                }
              );
            }
          },
          header: true, // Treat the first row as header
          skipEmptyLines: true,
        });
      }
    }
  };

  const handleSecondaryListingFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const expectedHeaders = [
      "id",
      "secondary_sku",
      "master_sku",
      "inventory_sku_id",
      "pack_combo_sku_id",
      "sku_type",
      "update_add",
    ];
    setUploadButtonActive(false);

    if (event.target.files) {
      const selectedFile = event.target.files[0];
      // let jsonPayload: any[] | null = null;
      if (selectedFile) {
        Papa.parse(selectedFile, {
          complete: (result) => {
            const fileHeaders = result.meta.fields;
            if (fileHeaders) {
              const headersMatch =
                expectedHeaders.every((header) =>
                  fileHeaders.includes(header)
                ) &&
                fileHeaders.every((header) => expectedHeaders.includes(header));

              if (!headersMatch) {
                toast.error(
                  "Error: The CSV file headers are incorrect. Please upload a valid file.",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                  }
                );
                return; // Stop further processing
              }

              const validRows = result.data.filter((row: any) => {
                // Check if the row is not empty
                return Object.values(row).some(
                  (value) => value !== null && value !== ""
                );
              });

              if (validRows.length === 0) {
                toast.error(
                  "Error: The CSV file contains no valid rows. Please upload a valid file.",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                  }
                );
                return;
              }

              // If headers match, process the data
              setUploadButtonActive(true);
              mapToUploadSecondaryListingfileFormat(validRows);
            } else {
              toast.error(
                "Error: The CSV file headers are missing. Please upload a valid file.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  hideProgressBar: true,
                }
              );
            }
          },
          header: true, // Treat the first row as header
          skipEmptyLines: true,
        });
      }
    }
  };

  const handlePacksAndCombosFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const expectedHeaders = [
      "pack_combo_sku_id",
      "inventory_sku_id",
      "sku_count",
      "update_add",
    ];
    setUploadButtonActive(false);

    if (event.target.files) {
      const selectedFile = event.target.files[0];
      // let jsonPayload: any[] | null = null;
      if (selectedFile) {
        Papa.parse(selectedFile, {
          complete: (result) => {
            const fileHeaders = result.meta.fields;
            if (fileHeaders) {
              const headersMatch =
                expectedHeaders.every((header) =>
                  fileHeaders.includes(header)
                ) &&
                fileHeaders.every((header) => expectedHeaders.includes(header));

              if (!headersMatch) {
                toast.error(
                  "Error: The CSV file headers are incorrect. Please upload a valid file.",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                  }
                );
                return; // Stop further processing
              }

              const validRows = result.data.filter((row: any) => {
                // Check if the row is not empty
                return Object.values(row).some(
                  (value) => value !== null && value !== ""
                );
              });

              if (validRows.length === 0) {
                toast.error(
                  "Error: The CSV file contains no valid rows. Please upload a valid file.",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                  }
                );
                return;
              }

              // If headers match, process the data
              setUploadButtonActive(true);
              mapToUploadPacksAndCombosFileFormat(validRows);
            } else {
              toast.error(
                "Error: The CSV file headers are missing. Please upload a valid file.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  hideProgressBar: true,
                }
              );
            }
          },
          header: true, // Treat the first row as header
          skipEmptyLines: true,
        });
      }
    }
  };

  const mapToUploadAISfileFormat = async (data: any[]) => {
    const aisListingUploadItems: AisListingUploadItem[] = [];
    data.forEach((item) => {
      if (item.update_add == "add" || item.update_add == "update") {
        aisListingUploadItems.push({
          inventory_sku_id: item.inventory_sku_id,
          ais_quantity: item.ais_quantity,
          update_add: item.update_add,
        });
      }
    });

    console.log(aisListingUploadItems);
    setFinalAISlistingsToUpload(aisListingUploadItems);
  };

  const mapToUploadPacksAndCombosFileFormat = async (data: any[]) => {
    const packsAndCombosUploadItems: PacksCombosUploadItem[] = [];
    data.forEach((item) => {
      if (item.update_add == "add" || item.update_add == "update") {
        packsAndCombosUploadItems.push({
          pack_combo_sku_id: item.pack_combo_sku_id,
          inventory_sku_id: item.inventory_sku_id,
          sku_count: item.sku_count,
          update_add: item.update_add,
        });
      }
    });

    setFinalPacksCombosListingsToUpload(packsAndCombosUploadItems);
  };

  const mapToUploadSecondaryListingfileFormat = async (data: any[]) => {
    const secondaryListingUploadItems: SecondaryListingUploadItem[] = [];
    data.forEach((item) => {
      if (item.update_add == "add" || item.update_add == "update") {
        secondaryListingUploadItems.push({
          secondary_sku: item.secondary_sku,
          master_sku: item.master_sku,
          inventory_sku_id: item.inventory_sku_id,
          pack_combo_sku_id: item.pack_combo_sku_id,
          sku_type: item.sku_type,
          update_add: item.update_add,
        });
      }
    });

    console.log(secondaryListingUploadItems);
    setFinalSecondaryListingsToUpload(secondaryListingUploadItems);
  };

  const submitSecondaryListingToWeb = async () => {
    if (finalSecondaryListingsToUpload.length > 1000) {
      toast.error(
        "Error: Unable to update/add more than 1000 listings at once.",
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        }
      );
    }

    setShowProgressPage(true);

    try {
      const response =
        await inventoryNetworkUtils.addOrUpdateSecondaryListingsInBulk(
          finalSecondaryListingsToUpload,
          userDetailsFromStore.user_details.userId
        );
      //   setCurrentPurchaseOrder(response.data);
      setSecondaryLisitngsAfterUploadResult(response.data);
      setShowSecondaryListingAfterUploadResult(true);
      setShowProgressPage(false);
    } catch (error) {
      toast.error("Error! Could not post Secondary listings to web.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setShowProgressPage(false);
    }
  };

  const submitPacksAndCombosListingToWeb = async () => {
    if (finalPacksCombosListingsToUpload.length > 1000) {
      toast.error("Error: Unable to submit more than 1000 listings at once.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }

    setShowProgressPage(true);

    try {
      const response =
        await inventoryNetworkUtils.addOrUpdatePacksAndCombosListingsInBulk(
          finalPacksCombosListingsToUpload,
          userDetailsFromStore.user_details.userId
        );
      //   setCurrentPurchaseOrder(response.data);
      setPacksAndCombosLisitngsAfterUploadResult(response.data);
      setShowPacksCombosListingAfterUploadResult(true);
      setShowProgressPage(false);
    } catch (error) {
      toast.error("Error! Could not post Packs And Combos listings to web.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setShowProgressPage(false);
    }
  };

  const submitAISlistingToWeb = async () => {
    if (finalAISlistingsToUpload.length > 1000) {
      toast.error(
        "Error: Unable to update/add more than 1000 listings at once.",
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        }
      );
    }

    setShowProgressPage(true);

    try {
      const response = await inventoryNetworkUtils.addOrUpdateAISlistingsInBulk(
        finalAISlistingsToUpload,
        userDetailsFromStore.user_details.userId
      );

      //   setCurrentPurchaseOrder(response.data);
      setAisLisitngsAfterUploadResult(response.data);
      setShowAISListingAfterUploadResult(true);
      setShowProgressPage(false);
    } catch (error) {
      toast.error("Error! Could not post AIS listings to web.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });

      setShowProgressPage(false);
    }
  };

  const downloadCurrentAISAfterSubmissionResultView = async () => {
    try {
      // itemsToExport.push()
      jsonexport(
        aisListingsAfterUploadResult,
        (err: Error | null, csv: string) => {
          if (err) {
            console.error(err);
            return;
          }
          // Create a Blob object with the CSV data
          const blob = new Blob([csv], { type: "text/csv" });
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "ais_listing_after_upload_result.csv";
          // Simulate a click to trigger the download
          link.click();
        }
      );
    } catch (error) {
      console.log(
        "Printing error from downloadCurrentAISAfterSubmissionResultView "
      );
      console.error("Error:", error);
    }
  };

  const downloadCurrentSecondaryListingAfterSubmissionResultView = async () => {
    try {
      // itemsToExport.push()
      jsonexport(
        secondaryListingsAfterUploadResult,
        (err: Error | null, csv: string) => {
          if (err) {
            console.error(err);
            return;
          }
          // Create a Blob object with the CSV data
          const blob = new Blob([csv], { type: "text/csv" });
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "secondary_listing_after_upload_result.csv";
          // Simulate a click to trigger the download
          link.click();
        }
      );
    } catch (error) {
      console.log(
        "Printing error from downloadCurrentSecondaryListingAfterSubmissionResultView "
      );
      console.error("Error:", error);
    }
  };

  const downloadCurrentPacksAndCombosListingAfterSubmissionResultView =
    async () => {
      try {
        // itemsToExport.push()
        jsonexport(
          packsAndComboListingsAfterUploadResult,
          (err: Error | null, csv: string) => {
            if (err) {
              console.error(err);
              return;
            }
            // Create a Blob object with the CSV data
            const blob = new Blob([csv], { type: "text/csv" });
            // Create a link element to trigger the download
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "packs_and_combos_listing_after_upload_result.csv";
            // Simulate a click to trigger the download
            link.click();
          }
        );
      } catch (error) {
        console.log(
          "Printing error from downloadCurrentPacksAndCombosListingAfterSubmissionResultView "
        );
        console.error("Error:", error);
      }
    };

  return (
    <div className="inventory-bulk-operations-main-container">
      {showLoadingPage ? (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      ) : (
        <div className="secondary-listings-bulk-operations-container">
          {uploadAISlistingsToSecondStep ||
          uploadSecondarylistingsToSecondStep ||
          uploadPacksComboslistingsToSecondStep ? (
            <div>
              {uploadAISlistingsToSecondStep && (
                <div>
                  {!showAISListingAfterUploadResult ? (
                    <div>
                      <div className="second-step-bulk-operations-title">
                        Upload AIS Listings File
                      </div>
                      <div className="final-submit-button-container-bulk-ops">
                        <div>
                          out of x listings {finalAISlistingsToUpload.length}{" "}
                          are qualified to be submitted to web.
                        </div>
                        <button
                          className="mt-3"
                          onClick={submitAISlistingToWeb}
                          disabled={!uploadButtonActive}
                        >
                          Submit Qualified Listings
                        </button>
                      </div>
                      <div className="secondary-listing-items-container mt-4">
                        <Table bordered hover size="sm">
                          <thead className="sticky-header">
                            <tr>
                              <th>sr_no.</th>
                              <th>inventory_sku</th>
                              <th>ais_quantity</th>
                              <th>update_add</th>
                            </tr>
                          </thead>
                          <tbody>
                            {finalAISlistingsToUpload.map(
                              (aisListingItem, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr
                                      className={"incoming-po-search-bar-row"}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <td>{index + 1}</td>
                                      <td>{aisListingItem.inventory_sku_id}</td>
                                      <td>{aisListingItem.ais_quantity}</td>
                                      <td>{aisListingItem.update_add}</td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>

                      {showProgressPage && (
                        <div className="loading-page-horizontal">
                          <Spinner animation="grow" className="mr-2" />
                          <Spinner animation="grow" className="mr-2" />
                          <Spinner animation="grow" className="mr-2" />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="after-submission-result-action-container">
                        <button
                          className="mt-3"
                          onClick={downloadCurrentAISAfterSubmissionResultView}
                        >
                          Download Current View as Excel Sheet
                        </button>
                      </div>
                      <div className="secondary-listing-items-container mt-4">
                        <Table bordered hover size="sm">
                          <thead className="sticky-header">
                            <tr>
                              <th>sr_no.</th>
                              <th>inventory_sku</th>
                              <th>ais_quantity</th>
                              <th>update_add</th>
                              <th>result</th>
                              <th>remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {aisListingsAfterUploadResult.map(
                              (aisListingResultItem, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr
                                      className={
                                        aisListingResultItem.result == "success"
                                          ? "green-row"
                                          : "red-row"
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <td>{index + 1}</td>
                                      <td>
                                        {aisListingResultItem.inventory_sku_id}
                                      </td>
                                      <td>
                                        {aisListingResultItem.ais_quantity}
                                      </td>
                                      <td>{aisListingResultItem.update_add}</td>
                                      <td>{aisListingResultItem.result}</td>
                                      <td>{aisListingResultItem.remarks}</td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>

                      {showProgressPage && (
                        <div className="loading-page-horizontal">
                          <Spinner animation="grow" className="mr-2" />
                          <Spinner animation="grow" className="mr-2" />
                          <Spinner animation="grow" className="mr-2" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              {uploadSecondarylistingsToSecondStep && (
                <div>
                  {!showSecondaryListingAfterUploadResult ? (
                    <div>
                      <div className="second-step-bulk-operations-title">
                        Upload Secondary Listings File
                      </div>

                      <div className="final-submit-button-container-bulk-ops">
                        <div>
                          out of x listings{" "}
                          {finalSecondaryListingsToUpload.length} are qualified
                          to be submitted to web.
                        </div>
                        <button
                          className="mt-3"
                          onClick={submitSecondaryListingToWeb}
                          disabled={!uploadButtonActive}
                        >
                          Submit Qualified Listings
                        </button>
                      </div>
                      <div className="secondary-listing-items-container mt-4">
                        <Table bordered hover size="sm">
                          <thead className="sticky-header">
                            <tr>
                              <th>sr_no.</th>
                              <th>secondary_sku</th>
                              <th>master_sku</th>
                              <th>inventory_sku</th>
                              <th>pack_combo_sku</th>
                              <th>sku_type</th>
                              <th>update_add</th>
                            </tr>
                          </thead>
                          <tbody>
                            {finalSecondaryListingsToUpload.map(
                              (secondaryListingItem, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr
                                      className={"incoming-po-search-bar-row"}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <td>{index + 1}</td>
                                      <td>
                                        {secondaryListingItem.secondary_sku}
                                      </td>
                                      <td>{secondaryListingItem.master_sku}</td>
                                      <td>
                                        {secondaryListingItem.inventory_sku_id}
                                      </td>
                                      <td>
                                        {secondaryListingItem.pack_combo_sku_id}
                                      </td>
                                      <td>{secondaryListingItem.sku_type}</td>
                                      <td>{secondaryListingItem.update_add}</td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>

                      {showProgressPage && (
                        <div className="loading-page-horizontal">
                          <Spinner animation="grow" className="mr-2" />
                          <Spinner animation="grow" className="mr-2" />
                          <Spinner animation="grow" className="mr-2" />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="after-submission-result-action-container">
                        <button
                          className="mt-3"
                          onClick={
                            downloadCurrentSecondaryListingAfterSubmissionResultView
                          }
                        >
                          Download Current View as Excel Sheet
                        </button>
                      </div>
                      <div className="secondary-listing-items-container mt-4">
                        <Table bordered hover size="sm">
                          <thead className="sticky-header">
                            <tr>
                              <th>sr_no.</th>
                              <th>secondary_sku</th>
                              <th>master_sku</th>
                              <th>inventory_sku</th>
                              <th>pack_combo_sku</th>
                              <th>sku_type</th>
                              <th>update_add</th>
                              <th>result</th>
                              <th>remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {secondaryListingsAfterUploadResult.map(
                              (
                                secondaryListingAfterUploadResultItem,
                                index
                              ) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr
                                      className={
                                        secondaryListingAfterUploadResultItem.result ==
                                        "success"
                                          ? "green-row"
                                          : "red-row"
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <td>{index + 1}</td>
                                      <td>
                                        {
                                          secondaryListingAfterUploadResultItem.secondary_sku
                                        }
                                      </td>
                                      <td>
                                        {
                                          secondaryListingAfterUploadResultItem.master_sku
                                        }
                                      </td>
                                      <td>
                                        {
                                          secondaryListingAfterUploadResultItem.inventory_sku_id
                                        }
                                      </td>
                                      <td>
                                        {
                                          secondaryListingAfterUploadResultItem.pack_combo_sku_id
                                        }
                                      </td>
                                      <td>
                                        {
                                          secondaryListingAfterUploadResultItem.sku_type
                                        }
                                      </td>
                                      <td>
                                        {
                                          secondaryListingAfterUploadResultItem.update_add
                                        }
                                      </td>
                                      <td>
                                        {
                                          secondaryListingAfterUploadResultItem.result
                                        }
                                      </td>
                                      <td>
                                        {
                                          secondaryListingAfterUploadResultItem.remarks
                                        }
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>

                      {showProgressPage && (
                        <div className="loading-page-horizontal">
                          <Spinner animation="grow" className="mr-2" />
                          <Spinner animation="grow" className="mr-2" />
                          <Spinner animation="grow" className="mr-2" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              {uploadPacksComboslistingsToSecondStep && (
                <div>
                  {!showPacksCombosListingAfterUploadResult ? (
                    <div>
                      <div className="second-step-bulk-operations-title">
                        Upload Packs & Combos Listings File
                      </div>

                      <div className="final-submit-button-container-bulk-ops">
                        <div>
                          out of x listings{" "}
                          {finalPacksCombosListingsToUpload.length} are
                          qualified to be submitted to web.
                        </div>
                        <button
                          className="mt-3"
                          onClick={submitPacksAndCombosListingToWeb}
                          disabled={!uploadButtonActive}
                        >
                          Submit Qualified Listings
                        </button>
                      </div>
                      <div className="secondary-listing-items-container mt-4">
                        <Table bordered hover size="sm">
                          <thead className="sticky-header">
                            <tr>
                              <th>sr_no.</th>
                              <th>pack_combo_sku_id</th>
                              <th>inventory_sku_id</th>
                              <th>sku_count</th>
                              <th>update_add</th>
                            </tr>
                          </thead>
                          <tbody>
                            {finalPacksCombosListingsToUpload.map(
                              (packComboItem, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr
                                      className={"incoming-po-search-bar-row"}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <td>{index + 1}</td>
                                      <td>{packComboItem.pack_combo_sku_id}</td>
                                      <td>{packComboItem.inventory_sku_id}</td>
                                      <td>{packComboItem.sku_count}</td>
                                      <td>{packComboItem.update_add}</td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>

                      {showProgressPage && (
                        <div className="loading-page-horizontal">
                          <Spinner animation="grow" className="mr-2" />
                          <Spinner animation="grow" className="mr-2" />
                          <Spinner animation="grow" className="mr-2" />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="after-submission-result-action-container">
                        <button
                          className="mt-3"
                          onClick={
                            downloadCurrentPacksAndCombosListingAfterSubmissionResultView
                          }
                        >
                          Download Current View as Excel Sheet
                        </button>
                      </div>
                      <div className="secondary-listing-items-container mt-4">
                        <Table bordered hover size="sm">
                          <thead className="sticky-header">
                            <tr>
                              <th>sr_no.</th>
                              <th>pack_combo_sku_id</th>
                              <th>inventory_sku_id</th>
                              <th>sku_count</th>
                              <th>update_add</th>
                              <th>result</th>
                              <th>remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {packsAndComboListingsAfterUploadResult.map(
                              (
                                packsAndComboListingsAfterUploadResultItem,
                                index
                              ) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr
                                      className={
                                        packsAndComboListingsAfterUploadResultItem.result ==
                                        "success"
                                          ? "green-row"
                                          : "red-row"
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <td>{index + 1}</td>
                                      <td>
                                        {
                                          packsAndComboListingsAfterUploadResultItem.pack_combo_sku_id
                                        }
                                      </td>
                                      <td>
                                        {
                                          packsAndComboListingsAfterUploadResultItem.inventory_sku_id
                                        }
                                      </td>
                                      <td>
                                        {
                                          packsAndComboListingsAfterUploadResultItem.sku_count
                                        }
                                      </td>
                                      <td>
                                        {
                                          packsAndComboListingsAfterUploadResultItem.update_add
                                        }
                                      </td>
                                      <td>
                                        {
                                          packsAndComboListingsAfterUploadResultItem.result
                                        }
                                      </td>
                                      <td>
                                        {
                                          packsAndComboListingsAfterUploadResultItem.remarks
                                        }
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>

                      {showProgressPage && (
                        <div className="loading-page-horizontal">
                          <Spinner animation="grow" className="mr-2" />
                          <Spinner animation="grow" className="mr-2" />
                          <Spinner animation="grow" className="mr-2" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="operations-container">
              <div className="operation-section">
                <div className="section-title">Export Operations</div>
                <div className="operation-content mt-4">
                  <div className="possible-operations-list">
                    <div
                      className="operations-list-item"
                      onClick={handleSecondaryListingsFileDownload}
                    >
                      Download Secondary Listings File
                    </div>
                    <div
                      className="operations-list-item"
                      onClick={handlePacksAndCombosListingsFileDownload}
                    >
                      Download Packs & Combos File
                    </div>
                    <div
                      className="operations-list-item"
                      onClick={handleAISListingDownload}
                    >
                      Download AIS Listings File
                    </div>
                  </div>
                </div>
              </div>
              <div className="operation-section">
                <div className="section-title">Import Operations</div>
                <div className="operation-content mt-4">
                  <div
                    className="operations-list-item"
                    onClick={() => setShowSecondaryListingUploadModal(true)}
                  >
                    Add/Update Secondary Listings
                  </div>
                  <div
                    className="operations-list-item"
                    onClick={() => setShowPacksAndCombosUploadModal(true)}
                  >
                    Add/Update Packs & Combos
                  </div>
                  <div
                    className="operations-list-item"
                    onClick={() => setShowAISListingUploadModal(true)}
                  >
                    Add/Update AIS Listings
                  </div>
                </div>
              </div>

              {downloadButtonClicked && (
                <div className="loading-page-horizontal">
                  <Spinner animation="grow" className="mr-2" />
                  <Spinner animation="grow" className="mr-2" />
                  <Spinner animation="grow" className="mr-2" />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <Modal
        show={showAISListingUploadModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowAISListingUploadModal(false)}
      >
        <div>
          <Modal.Body>
            <div className="upload-incoming-po-container">
              <div className="upload-incoming-po-container-insider mb-4 pt-4 pb-4">
                <div className="upload-snapshot-container">
                  <div className="upload-snapshot-heading-text">
                    Upload updated AIS listings file.
                  </div>
                  <div>
                    <p
                      className="download-sample-link"
                      onClick={handleSamplAISfileDownload}
                    >
                      [Download Sample File]
                    </p>
                  </div>
                  <div className="upload-controls">
                    <input
                      className="upload-snapshot-button"
                      type="file"
                      accept=".csv"
                      id="fileInput"
                      onChange={handleAISFileChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="add-item-quantity-card-button-container">
              <Button
                className="mr-2"
                onClick={() => setShowAISListingUploadModal(false)}
              >
                Cancel
              </Button>

              <Button
                className="ml-2"
                onClick={() => {
                  setUploadAISlistingsToSecondStep(true);
                  setShowAISListingUploadModal(false);
                }}
                disabled={!uploadButtonActive}
              >
                Upload
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>

      <Modal
        show={showPacksAndCombosListingUploadModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowPacksAndCombosUploadModal(false)}
      >
        <div>
          <Modal.Body>
            <div className="upload-incoming-po-container">
              <div className="upload-incoming-po-container-insider mb-4 pt-4 pb-4">
                <div className="upload-snapshot-container">
                  <div className="upload-snapshot-heading-text">
                    Upload updated packs & combos file
                  </div>
                  <div>
                    <p
                      className="download-sample-link"
                      onClick={handleSamplePacksAndCombosFileDownload}
                    >
                      [Download Sample File]
                    </p>
                  </div>
                  <div className="upload-controls">
                    <input
                      className="upload-snapshot-button"
                      type="file"
                      accept=".csv"
                      id="fileInput"
                      onChange={handlePacksAndCombosFileChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="add-item-quantity-card-button-container">
              <Button
                className="mr-2"
                onClick={() => setShowPacksAndCombosUploadModal(false)}
              >
                Cancel
              </Button>

              <Button
                className="ml-2"
                onClick={() => {
                  setUploadPacksComboslistingsToSecondStep(true);
                  setShowPacksAndCombosUploadModal(false);
                }}
                disabled={!uploadButtonActive}
              >
                Upload
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>

      <Modal
        show={showSecondaryListingUploadModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowSecondaryListingUploadModal(false)}
      >
        <div>
          <Modal.Body>
            <div className="upload-incoming-po-container">
              <div className="upload-incoming-po-container-insider mb-4 pt-4 pb-4">
                <div className="upload-snapshot-container">
                  <div className="upload-snapshot-heading-text">
                    Upload updated secondary listings file.
                  </div>
                  <div>
                    <p
                      className="download-sample-link"
                      onClick={handleSampleSecondaryListingsFileDownload}
                    >
                      [Download Sample File]
                    </p>
                  </div>
                  <div className="upload-controls">
                    <input
                      className="upload-snapshot-button"
                      type="file"
                      accept=".csv"
                      id="fileInput"
                      onChange={handleSecondaryListingFileChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="add-item-quantity-card-button-container">
              <Button
                className="mr-2"
                onClick={() => setShowSecondaryListingUploadModal(false)}
              >
                Cancel
              </Button>

              <Button
                className="ml-2"
                onClick={() => {
                  setUploadSecondarylistingsToSecondStep(true);
                  setShowSecondaryListingUploadModal(false);
                }}
                disabled={!uploadButtonActive}
              >
                Upload
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
}

export default SecondaryListingsBulkOperations;
