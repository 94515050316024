import React, { useState, useEffect, useCallback } from "react";
import "./CSS/PurchaseOrdersVendorListingsView.css";
import {
  CostPriceTimelineItem,
  ImageHashMapState,
  Listing,
  ListingPage,
  PurchaseOrderItemWithLisiting,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";

import { toast } from "react-toastify";
import _ from "lodash";
import { useAppSelector } from "../../state/hooks";
import { AxiosError } from "axios";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "./NetworkUtils/VendorsNetworkUtil";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state";
import {
  ImageHashMapVendorPurchaseOrder__Action,
  ImageHashMap__Action,
} from "../../state/action-creators";
import {
  ImageHashMapActionTypes,
  VendorPurchaseOrderImageHashMapActionTypes,
} from "../../state/action-types";

interface ListingImageProps {
  images: string[]; // Define the type of the images prop as an array of strings
  purchaseOrderItemWithListing: PurchaseOrderItemWithLisiting;
}

const ListingImage: React.FC<ListingImageProps> = ({
  images,
  purchaseOrderItemWithListing,
}) => {
  // const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const hashMap: ImageHashMapState = useAppSelector(
    (state) => state.purchase_order_image_hashmap_store
  );
  const dispatch: AppDispatch = useDispatch();

  const handleImageError = () => {
    let nextIndex =
      hashMap.imageHashmap[purchaseOrderItemWithListing.listing.sku_id];
    if (nextIndex) nextIndex = nextIndex + 1;
    else nextIndex = 1;

    while (
      nextIndex < images.length &&
      (images[nextIndex] == null || images[nextIndex].trim() === "")
    ) {
      nextIndex++;
    }

    dispatch(
      ImageHashMapVendorPurchaseOrder__Action(
        VendorPurchaseOrderImageHashMapActionTypes.ADD_TO_MAP,
        {
          key: purchaseOrderItemWithListing.listing.sku_id,
          value: nextIndex,
          purchaseOrderItemWithListings: [],
        }
      )
    );
  };

  return (
    <div className="listings-img-container">
      {hashMap.imageHashmap[purchaseOrderItemWithListing.listing.sku_id] > 4 ? (
        <img
          src="https://tech.intellozene.com/Media/na.png"
          alt="Product Image"
          onError={handleImageError}
        />
      ) : (
        <img
          src={
            images[
              hashMap.imageHashmap[purchaseOrderItemWithListing.listing.sku_id]
            ]
          }
          alt="Product Image"
          onError={handleImageError}
        />
      )}
    </div>
  );
};

const ListingCard: React.FC<{
  purchaseOrderListing: PurchaseOrderItemWithLisiting;
  handleCardClick: (listing: PurchaseOrderItemWithLisiting) => void;
}> = ({ purchaseOrderListing, handleCardClick }) => {
  return (
    <div
      className="listings-view-card vendor-listing-card"
      onClick={() => handleCardClick(purchaseOrderListing)}
    >
      <div className="listings-img-container">
        <ListingImage
          images={[
            purchaseOrderListing.listing.img_wdim,
            purchaseOrderListing.listing.img_hd,
            purchaseOrderListing.listing.img_white,
            purchaseOrderListing.listing.img_link1,
            purchaseOrderListing.listing.img_link2,
          ]}
          purchaseOrderItemWithListing={purchaseOrderListing}
        />
      </div>

      <div className="listings-view-card-title">
        <h3
          className={`sku-id-title ${
            purchaseOrderListing.listing.sku_type == "MULTI"
              ? ""
              : purchaseOrderListing.listing.available_quantity === 0
              ? "not-available"
              : "available"
          }`}
        >
          {purchaseOrderListing.listing.sku_id}
        </h3>
      </div>
      <p>OPS Tag: {purchaseOrderListing.listing.ops_tag}</p>
      {purchaseOrderListing.listing.sku_type == "MULTI" ? (
        <p>MULTI</p>
      ) : (
        <p>
          Available Quantity: {purchaseOrderListing.listing.available_quantity}
        </p>
      )}
      {/* <p>Available Quantity: {purchaseOrderListing.listing.available_quantity}</p> */}

      <div className="required-quantity-container">
        <p>Required Quantity : </p>
        <input
          type="text"
          name="vendor_cost_price"
          value={purchaseOrderListing.required_quantity}
          //   onChange={handleChange}
          //   disabled={!editMode}
        />
      </div>
      <p className="last-modified">
        Last Modified:{" "}
        {DateTimeUtils.formatDateTime(purchaseOrderListing.updated_at)}
      </p>
    </div>
  );
};

type ListingsViewProps = {
  publishedListings: PurchaseOrderItemWithLisiting[];
  onCardClick: (selectedListing: PurchaseOrderItemWithLisiting) => void;
};

export default function PurchaseOrdersPublishedItemsView({
  publishedListings,
  onCardClick,
}: ListingsViewProps) {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();

  const handleCardClick = async (listing: PurchaseOrderItemWithLisiting) => {
    onCardClick(listing);
  };

  return (
    <div>
      {publishedListings && publishedListings.length > 0 && (
        <div className="vendor-listings-card-container">
          {publishedListings.map((publishedListing, index) => (
            <ListingCard
              key={index}
              purchaseOrderListing={publishedListing}
              handleCardClick={handleCardClick}
            />
          ))}
        </div>
      )}

      {publishedListings && publishedListings.length == 0 && (
        <div className="no-items-view">Nothing to Display</div>
      )}
    </div>
  );
}
