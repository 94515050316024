import React, { useEffect, useState } from "react";

import "./CSS/LabelsHome.css";

import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";
import Header from "../Header";
import LabelsCompany from "./LabelsCompany";
import LabelsSecondaryAndCompanySkuRelation from "./LabelsSecondaryAndCompanySkuRelation";
import LabelsMasterDataPage from "./LabelsMasterDataPage";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
}

function ListingsHome() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(ActiveTab__Action("inventory"));
  }, []);

  const { category } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(category);
  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    history.push(`/labels/${item}`);
  };

  useEffect(() => {
    setSelectedItem(category);
  }, [category]);

  return (
    <div className="overlay-inventory-home">
      <Header />

      <div className="nav-container">
        <nav className="navbar-vendors">
          <ul className="navbar-menu">
            <li
              className={`navbar-item ${
                selectedItem === "generateLabels" ? "active" : ""
              }`}
              onClick={() => handleItemClick("generateLabels")}
            >
              Generate Labels
            </li>
            <li
              className={`navbar-item ${
                selectedItem === "secondaryCompanySkuRelation" ? "active" : ""
              }`}
              onClick={() => handleItemClick("secondaryCompanySkuRelation")}
            >
              eCraft's Secondary SKU & Company's SKU Relation
            </li>

            <li
              className={`navbar-item ${
                selectedItem === "labelsMasterData" ? "active" : ""
              }`}
              onClick={() => handleItemClick("labelsMasterData")}
            >
              Labels Master Data
            </li>
          </ul>
        </nav>
      </div>

      {selectedItem == "generateLabels" && <LabelsCompany />}
      {selectedItem == "secondaryCompanySkuRelation" && (
        <LabelsSecondaryAndCompanySkuRelation />
      )}
      {selectedItem == "labelsMasterData" && <LabelsMasterDataPage />}
    </div>
  );
}

export default ListingsHome;
