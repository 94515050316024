import { ActionType, ActiveTabActionTypes, ImageHashMapActionTypes, ListingActionTypes, SearchTextActionTypes, TopListingActionTypes, UserDetailsActionTypes, VendorPurchaseOrderImageHashMapActionTypes } from "../action-types"
import { Dispatch } from "redux"
import {ActionInterface, ActiveTabAction, ImageHashMapAction, ImageHashMapVendorPurchaseOrderAction, ListingAction, SearchParamsAction, SearchTextAction, TopListingAction, UserDetailsAction} from "../actions-interface/index"
import {Bin, ImageHashMapItem, ImageHashMapVendorPurchaseOrderItem, Listing, LoginDetails, POType, SearchParams} from "../../atomic_components/types"

// All the actions to send data to reducer;

export const UserId__Action = (id : number) =>{

    return (dispatch : Dispatch<ActionInterface>) =>{
        dispatch({
            type : ActionType.USER_ID            ,
            payload : id
        })
    }
}

export const ActiveTab__Action = ( payload : string) =>{

    return (dispatch : Dispatch<ActiveTabAction>) =>{
        dispatch({
            type : ActiveTabActionTypes.SET_ACTIVE_TAB,
            payload : payload
        })
    }
}

export const SearchText__Action = ( payload : string) =>{
    return (dispatch : Dispatch<SearchTextAction>) =>{
        dispatch({
            type : SearchTextActionTypes.SET_SEARCH_TEXT,
            payload : payload
        })
    }
}

export const SetSearchParams__Action = (actionType : SearchTextActionTypes, payload : SearchParams) => {
    return (dispatch : Dispatch<SearchParamsAction>) =>{
        dispatch({
            type : actionType,
            payload : payload
        })
    }
}

export const Listings__Action = ( actionType : ListingActionTypes,  payload : Listing[], actionOnSku : string, binsPayload : Bin[], listingPayload? : Listing ) =>{

    return (dispatch : Dispatch<ListingAction>) =>{
        dispatch({
            type : actionType,
            payload : payload,
            actionOnSku : actionOnSku,
            binsPayload : binsPayload,
            listingPayload : listingPayload
        })
    }
}


export const TopListings__Action = ( actionType : TopListingActionTypes,  payload : Listing[], actionOnSku : string, binsPayload : Bin[], listingPayload? : Listing ) =>{

    return (dispatch : Dispatch<TopListingAction>) =>{
        dispatch({
            type : actionType,
            payload : payload,
            actionOnSku : actionOnSku,
            binsPayload : binsPayload,
            listingPayload : listingPayload
        })
    }
}

export const ImageHashMap__Action = (actionType : ImageHashMapActionTypes, payload : ImageHashMapItem) =>{
    return (dispatch : Dispatch<ImageHashMapAction>) =>{
        dispatch({
            type : actionType,
            payload : payload
        })
    }
}

export const ImageHashMapVendorPurchaseOrder__Action = (actionType : VendorPurchaseOrderImageHashMapActionTypes, payload : ImageHashMapVendorPurchaseOrderItem) =>{
    return (dispatch : Dispatch<ImageHashMapVendorPurchaseOrderAction>) =>{
        dispatch({
            type : actionType,
            payload : payload
        })
    }
}


export const WindowSmall__Action = ( payload : boolean) =>{

    return (dispatch : Dispatch<ActionInterface>) =>{
        dispatch({
            type : ActionType.WINDOW_SMALL,
            payload : payload
        })
    }
}
export const SideBarOption__Action = ( payload : boolean) =>{

    return (dispatch : Dispatch<ActionInterface>) =>{
        dispatch({
            type : ActionType.SIDE_BAR_OPTION,
            payload : payload
        })
    }
}
export const PO_ID__Action = ( payload : number) =>{

    return (dispatch : Dispatch<ActionInterface>) =>{
        dispatch({
            type : ActionType.PO_ID,
            payload : payload
        })
    }
}

export const PO__Action = ( payload : POType) =>{

    return (dispatch : Dispatch<ActionInterface>) =>{
        dispatch({
            type : ActionType.PO,
            payload : payload
        })
    }
}

export const User_details_Action = (payload : LoginDetails) => {
    return (dispatch : Dispatch<UserDetailsAction>) => {
        dispatch({
            type : UserDetailsActionTypes.ADD_USER_DETAILS,
            payload : payload
        })
    }
}

