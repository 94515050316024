import React, { useEffect, useState } from "react";

import "./CSS/VendorsHome.css";

import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";
import VendorsAll from "./VendorsAll";
import VendorsAdd from "./VendorsAdd";
import PurchaseOrdersOpen from "./PurchaseOrdersOpen";
import PurchaseOrdersListingView from "./PurchaseOrdersListingView";
import PurchaseOrdersAdd from "./PurchaseOrdersAdd";
import VendorDetails from "./VendorDetails";
import Header from "../Header";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
  vendor_id: string;
  vendor_option: string;
}

function VendorDetailsHome() {
  const dispatch: AppDispatch = useDispatch();
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  const history = useHistory();
  useEffect(() => {
    // if (!userDetailsFromStore.authorities.includes("AUTH_ADMIN")) {
    //   history.push(`/listings/all`);
    // }
  }, []);

  const { category, vendor_id, vendor_option } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(vendor_option);
  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    history.push(`/vendor-hub/all-vendors/${vendor_id}/${item}`);
  };

  useEffect(() => {
    setSelectedItem(vendor_option);
  }, [vendor_option]);

  return (
    <div className="overlay-purchase-order-details">
      <Header />
      <div>
        <div className="nav-container">
          <nav className="navbar-vendors">
            <ul className="navbar-menu">
              <li
                className={`navbar-item ${
                  selectedItem === "details" ? "active" : ""
                }`}
                onClick={() => handleItemClick("details")}
              >
                Vendor Details
              </li>
              <li
                className={`navbar-item ${
                  selectedItem === "vendor-purchase-orders" ? "active" : ""
                }`}
                onClick={() => handleItemClick("vendor-purchase-orders")}
              >
                Vendor Purchase Orders
              </li>
            </ul>
          </nav>
        </div>

        {selectedItem == "details" && <VendorDetails />}
        {/* {selectedItem == "vendor-purchase-orders" && <VendorDetailsHome />} */}
      </div>
    </div>
  );
}

export default VendorDetailsHome;
