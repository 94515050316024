import React, { useEffect, useState } from "react";

import "./CSS/ListingsHome.css";

import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";
import ListingsAll from "./ListingsAll";
import ListingsTop from "./ListingsTop";
import ListingsBulkOperations from "./ListingsBulkOperations";
import SecondaryListingsAll from "./SecondayListingsAll";
import SecondaryListingsPackAndCombos from "./SecondaryListingsPackAndCombos";
import SecondaryListingsAIS from "./SecondaryListingsAIS";
import SecondaryListingsBulkOperations from "./SecondaryListingsBulkOperations";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
}

function ListingsHome() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(ActiveTab__Action("listings"));
  }, []);

  const { category } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(category);
  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    history.push(`/listings/${item}`);
  };

  useEffect(() => {
    setSelectedItem(category);
  }, [category]);

  return (
    <div>
      <div className="nav-container">
        <nav className="navbar-listings">
          <ul className="navbar-menu">
            <li
              className={`navbar-item ${
                selectedItem === "all" ? "active" : ""
              }`}
              onClick={() => handleItemClick("all")}
            >
              All App Listings
            </li>
            <li
              className={`navbar-item ${
                selectedItem === "all-secondary-listings" ? "active" : ""
              }`}
              onClick={() => handleItemClick("all-secondary-listings")}
            >
              All Secondary Listings
            </li>
            <li
              className={`navbar-item ${
                selectedItem === "packs-and-combos" ? "active" : ""
              }`}
              onClick={() => handleItemClick("packs-and-combos")}
            >
              Packs And Combos
            </li>

            <li
              className={`navbar-item ${
                selectedItem === "ais-listings" ? "active" : ""
              }`}
              onClick={() => handleItemClick("ais-listings")}
            >
              AIS (Always In Stock) Listings
            </li>
            <li
              className={`navbar-item ${
                selectedItem === "bulk-operations" ? "active" : ""
              }`}
              onClick={() => handleItemClick("bulk-operations")}
            >
              Bulk Operations
            </li>
          </ul>
        </nav>
      </div>

      {selectedItem == "all" && <ListingsAll />}
      {selectedItem == "all-secondary-listings" && <SecondaryListingsAll />}
      {selectedItem == "packs-and-combos" && <SecondaryListingsPackAndCombos />}
      {selectedItem == "ais-listings" && <SecondaryListingsAIS />}
      {selectedItem == "bulk-operations" && <SecondaryListingsBulkOperations />}
      {/* {selectedItem == "bulk" && <ListingsBulkOperations />} */}
    </div>
  );
}

export default ListingsHome;
