import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Offcanvas,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import "./CSS/LabelsSecondaryAndCompanySkuRelation.css";

import {
  Catalogue,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  Consignment,
  ConsignmentAnalyticsObject,
  ConsignmentItems,
  ConsignmentItemsBoxView,
  ConsignmentItemsPOView,
  ConsignmentItemsSKUView,
  PoDocument,
  PurchaseOrder,
  PurchaseOrderDemandObject,
  SearchParams,
  ConsignmentDetailed,
  SecondarySkuCompanySkuRelationItem,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/LabelsNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Download } from "react-bootstrap-icons";
import jsonexport from "jsonexport";
import Papa from "papaparse";
import _ from "lodash";
import { ElevatorSharp } from "@mui/icons-material";
import LabelsNetworkUtils from "./NetworkUtils/LabelsNetworkUtils";

interface RouteParams {
  po_id: string;
}

function LabelsSecondaryAndCompanySkuRelation() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const labelsNetworkUtil = new LabelsNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [showBottomLoader, setShowBottomLoader] = useState<boolean>(false);
  const [
    secondarySkuAndCompanySkuRelationItems,
    setSecondarySkuAndCompanySkuRelationItems,
  ] = useState<SecondarySkuCompanySkuRelationItem[]>([]);

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);

  const fetchSecondarySkuAndCompanySkusPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response =
        await labelsNetworkUtil.fetchSecondarySkuAndCompanySkusPaginated(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          100
        );

      // setCurrentConsignments(response.data);
      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setSecondarySkuAndCompanySkuRelationItems((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);

      setShowLoadingPage(false);
      setShowBottomLoader(false);
    } catch (error) {
      console.error("Error in creating new Consignment : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const getClassNameForConsignment = (consignment_status: string) => {
    if (consignment_status == "OPEN") return "color-green";
    else if (consignment_status == "CLOSED") return "color-red";
    else if (consignment_status == "MARKED_RTD") return "color-pink";
    return "";
  };

  const getClassNameForInvoiceNumberStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "NOT-ASSIGNED") return "color-red";
    else if (invoice_number_status == "ASSIGNED") return "color-green";
    return "";
  };
  const getClassNameForInvoiceUploadStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "NOT-UPLOADED") return "color-red";
    else if (invoice_number_status == "UPLOADED") return "color-green";
    return "";
  };

  const loadMore = () => {
    setShowBottomLoader(true);
    fetchSecondarySkuAndCompanySkusPaginated(
      "",
      searchParams.current_page_num + 1,
      false
    );
    // setSearchParams((prev) => {
    //   return {
    //     ...prev,
    //     current_page_num: prev.current_page_num + 1,
    //   };
    // });
  };

  useEffect(() => {
    setShowLoadingPage(true);
    fetchSecondarySkuAndCompanySkusPaginated("", 1, false);
  }, []);

  return (
    <div className="all-consignments-container">
      {secondarySkuAndCompanySkuRelationItems && (
        <div className="vendor-action-buttons-container incoming-purchase-order-pack-task-details-container">
          {secondarySkuAndCompanySkuRelationItems.length === 0 ? (
            <div className="po-document-section-no-document mt-3">
              No Company Code Primary v/s Secondary SKU relation was found.
            </div>
          ) : (
            <div className="all-consignments-table mt-3">
              <div className="consignments-count-container">
                Showing {searchParams.item_count} of{" "}
                {searchParams.expected_item_count} relation(s).
              </div>
              <Table bordered hover size="sm" className="table-auto-width">
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Company Code Primary</th>
                    <th>Secondary SKU</th>
                  </tr>
                </thead>
                <tbody>
                  {secondarySkuAndCompanySkuRelationItems.map(
                    (singleItem, index) => {
                      return (
                        <tr className="catalogues-search-bar-row">
                          <td>{singleItem.company_name}</td>
                          <td>{singleItem.company_code_primary}</td>
                          <td>{singleItem.secondary_sku}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
              {searchParams.item_count > 0 &&
                searchParams.item_count < searchParams.expected_item_count &&
                !showBottomLoader && (
                  <div className="load-more-catalogues-button-container">
                    <Button
                      variant="outline-primary"
                      className="load-more-consignments-button"
                      onClick={() => loadMore()}
                    >
                      Load More..
                    </Button>
                  </div>
                )}
              {showBottomLoader && (
                <div className="bottom-progress-container">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}
    </div>
  );
}

export default LabelsSecondaryAndCompanySkuRelation;
